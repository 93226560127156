/* eslint-disable no-case-declarations */
import { INITIALISE_AUDIO_STATE, RuleAudioActions, SET_AUTO_PLAY, SET_CURRENT_INDEX, SET_IS_DOWNLOADING, START_AUDIO, STOP_AUDIO, GET_AUDIO_FILE } from '../../types/actions/RuleAudio.actions';
import { RuleAudioState } from "../../types/models/RuleAudioState";

const initialSettings: RuleAudioState = {
  audioMap: new Map<number, string | undefined>(),
  autoPlay: false,
  isPlaying: false,
  currentAudioIndex: 0,
  currentAudioSource: undefined,
  isDownloading: false,
  ruleInstanceId: ""
};

const RuleAudioReducer = (state = initialSettings, action: RuleAudioActions) => {

  switch (action.type) {
    case GET_AUDIO_FILE:
      const newMap = state.audioMap ? new Map(state.audioMap) : new Map<number, string | undefined>();
      newMap.set(action.payload.index, action.payload.source);
      return {
        ...state,
        audioMap: newMap,
      };
    case START_AUDIO:
      const audioSource = state.audioMap?.get(action.payload);
      if (audioSource) {
        return {
          ...state,
          currentAudioIndex: action.payload,
          currentAudioSource: audioSource,
          isPlaying: true
        };
      }
      else {
        return {
          ...state,
        };
      }
    case STOP_AUDIO:
      return {
        ...state,
        isPlaying: false
      };
    case SET_AUTO_PLAY:
      return {
        ...state,
        autoPlay: action.payload
      };
    case SET_IS_DOWNLOADING:
      return {
        ...state,
        isDownloading: action.payload
      };
    case SET_CURRENT_INDEX:
      return {
        ...state,
        currentAudioIndex: action.payload,
        currentAudioSource: state.audioMap?.get(action.payload),
      };
    case INITIALISE_AUDIO_STATE:
      const initialState = JSON.parse(JSON.stringify(initialSettings));
      initialState.ruleInstanceId = action.payload;
      return {
        initialState
      };
    default:
      return state;
  }
};

export default RuleAudioReducer;
