import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Divider, FormControl, List, ListItem, ListItemText, ListSubheader, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import { setSelectedUserLocations } from "../../../redux/actions/RuleUnit";
import { fontColor } from "../../../constants/ColorSets";
import { PostUpdateLocationSubscriptionRequest } from "../../../types/models/PostUpdateSubscriptionsRequest";
import { getMyRoles, postUpdateLocationSubscription } from "../../../redux/actions/Role";
import { AppState } from "../../../redux/store";
import { RoleState } from "../../../types/models/RoleState";
import EmptyResult from "../EmptyResult";
import { MyLocation } from "../../../types/models/MyRole";

const LocationFilter = () => {

    const dispatch = useDispatch();

    const [activeLocationIds, setActiveLocationIds] = useState<string[] | undefined>([]);
    const [availableLocationIds, setAvailableLocationIds] = useState<string[]>([]);
    const [isOpenRole, setIsOpenRole] = useState<boolean>(false);
    const [isRoleEmpty, setIsRoleEmpty] = useState<boolean>(false);

    const roleState = useSelector<AppState, RoleState>((state) => state.role);

    useEffect(() => {
        dispatch(getMyRoles((values) => {
            if (values) {
                initialiseLocations(values.locations);
            }
            else {
                setIsRoleEmpty(true);
            }
        }));
    }, []);

    const getLocationById = (locationId: string) => {
        const knownLocation = roleState.knownRoles?.locations.find((knownLocation) => knownLocation.LNIId === locationId);
        return knownLocation;
    }

    const getSelectedLocationElements = (selectedLocationIds: string[]) => {
        const elements: ReactNode[] = selectedLocationIds.map((location, index) =>
            <ListItem 
                key={index + "-" + location} 
                sx={{ borderBottom: (index != selectedLocationIds.length - 1) ? ("1px solid " + fontColor.grayBackground) : "none" }}
                >
                {location} - {getLocationById(location)?.Name}
            </ListItem>
        );

        return (
            <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                    <Typography sx={{ color: fontColor.titleFontColor }}>{"Active Locations (" + selectedLocationIds.length + ")"}</Typography>
                    <Button sx={{ color: fontColor.orangeTitle }} onClick={() => setIsOpenRole(true)}>Edit</Button>
                </Box>
                <Box sx={{ border: "1px solid " + fontColor.grayBackground, borderRadius: 2 }}>
                    {selectedLocationIds?.length > 0 ? (
                        <List>
                            {elements}
                        </List>
                    ) : (
                        <Box sx={{
                            display: "grid",
                            background: fontColor.ruleSearchEmptyResultBackground,
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            width: "100%",
                            height: "100%"
                        }}>
                            <Box sx={{ margin: 1, color: "grey" }}>No locations selected</Box>
                        </Box>
                    )}
                </Box>
            </Box >);
    }

    const updateLocationSelection = (selectedLocationIds: string[]) => {
        const otherLocations: string[] = [];
        roleState.knownRoles?.locations.forEach(location => {
            if (!selectedLocationIds.some(selectedLocationId => selectedLocationId == location.LNIId))
                otherLocations.push(location.LNIId);
        });

        if(!activeLocationIds) {
            setActiveLocationIds(selectedLocationIds);
            setAvailableLocationIds(otherLocations);
            dispatch(setSelectedUserLocations(selectedLocationIds));
        }
        else {
            const requests: PostUpdateLocationSubscriptionRequest[] = [];
            const addedLocationIds = selectedLocationIds.filter((id) => !activeLocationIds.includes(id));
            addedLocationIds.forEach((addedId) => {
                requests.push({
                    LNIId: addedId,
                    Action: "add",
                });
            });

            const removedLocationIds = activeLocationIds.filter(id => !selectedLocationIds.includes(id));
            removedLocationIds.forEach(removedId => {
                requests.push({
                    LNIId: removedId,
                    Action: "remove"
                });
            });

            Promise
            .allSettled(requests.map(request => dispatch(postUpdateLocationSubscription(request))))
            .then(() => {
                setActiveLocationIds(selectedLocationIds);
                setAvailableLocationIds(otherLocations);
                dispatch(setSelectedUserLocations(selectedLocationIds));
            });
        }
    }

    const initialiseLocations = (myLocations: MyLocation[]) => {
        const knownLocations = roleState.knownRoles?.locations ?? [];
        const initialSelectedLocationIds: string[] = [];

        myLocations.forEach((myLocation) => {
            const location = knownLocations.find((location) => location.LNIId === myLocation.LNIId);
            if(location && !initialSelectedLocationIds.some((initialLocationId) => initialLocationId === location.LNIId)) {
                initialSelectedLocationIds.push(location.LNIId);
            }
        });     
        updateLocationSelection(initialSelectedLocationIds);
        
    }

    const getMenus = (locationIds: string[]) => {
        const elements: ReactNode[] = [];
        locationIds.forEach(locationId => {
            const location = getLocationById(locationId);
            if(!location) return;


            elements.push(
                <MenuItem sx={{ margin: 1 }} key={location.LNIId} value={location.LNIId}>
                    <ListItemText primary={`${location.LNIId} - ${location.Name}`} />
                </MenuItem>
            )
        });
        return elements;
    }

    return (
        <Box>
            {isRoleEmpty ?
                <EmptyResult>No roles available.</EmptyResult>
                : (
                    <FormControl sx={{ m: 0.5, width: "100%" }} size="medium" >
                        <Select
                            displayEmpty
                            open={isOpenRole}
                            onClose={() => setIsOpenRole(false)}
                            multiple={true}
                            variant="standard"
                            value={activeLocationIds ?? []}
                            defaultValue={activeLocationIds ?? []}
                            MenuProps={{ disablePortal: true }}
                            id="grouped-select"
                            label="Roles"
                            sx={{
                                boxShadow: "none",
                                borderRadius: 2,
                                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                    border: 0,
                                },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                    border: 0,
                                },
                                '.MuiSelect-icon': {
                                    display: "none"
                                },
                            }}
                            onChange={selectedOption => {
                                let selectedLocationIds = typeof selectedOption.target.value === 'string' ? selectedOption.target.value.split(',') : selectedOption.target.value;

                                selectedLocationIds = selectedLocationIds.sort();
                                updateLocationSelection(selectedLocationIds);
                            }}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                                getSelectedLocationElements(selected ?? [])
                            )}
                        >
                            <ListSubheader>Active</ListSubheader>
                            {getMenus(activeLocationIds ?? [])}
                            <Divider />
                            <ListSubheader>Available</ListSubheader>
                            {getMenus(availableLocationIds)}
                        </Select>
                    </FormControl>
                )}
        </Box>
    )
}

export default LocationFilter;