import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Collapse, Fab, Typography, alpha } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MessageIcon from '@mui/icons-material/Message';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import ReactAudioPlayer from "react-audio-player";
import { Fonts } from "../../../constants/AppEnums";
import { RuleInstance } from "../../../types/models/RuleInstance";
import { ContentRevision } from "../../../types/models/ContentRevision";
import { PostAcknowledgedRequest } from "../../../types/models/PostAcknowledgedRequest";
import { postAcknowledgeStatus } from "../../../redux/actions";
import { fontColor } from "../../../constants/ColorSets";
import AppDialog from "../../../components/AppBase/AppDialog";
import Chat from "../../../components/Chat";
import { AppState } from "../../../redux/store";
import { RuleAudioState } from "../../../types/models/RuleAudioState";
import { getAudioFile, setAutoPlay, startAudio, stopAudio } from "../../../redux/actions/RuleAudio";

interface RuleDetailFooterProps {
    contentRevision: ContentRevision | undefined,
    ruleInstance: RuleInstance | undefined,
    sendingAcknowledge: (state: boolean) => void,
    handleSentAcknowledgeStatus: () => void,
    displayFeedbackButtons: boolean,
    disableButtons: boolean,
}

const RuleDetailFooter: React.FC<RuleDetailFooterProps> = (props) => {
    const dispatch = useDispatch();

    const audioState = useSelector<AppState, RuleAudioState>((state) => state.audio);
    const [audioPlayer, setAudioPlayer] = useState<ReactAudioPlayer | null>(null);

    const [isSendingAcknowledge, setIsSendingAcknowledge] = useState<boolean>(false);
    const [sentAcknowledgeStatus, setSentAcknowledgedStatus] = useState<boolean>(false);
    const [isOpenFeedBack, setIsOpenFeedBack] = useState<boolean>(false);
    const [isAcknowledgeButtonHover, setIsAcknowledgeButtonHover] = useState<boolean>(false);
    const [hideAudioPlayer, setHideAudioPlayer] = useState<boolean>(false);

    const readyToPlayAudio = (audioState.audioMap?.size ?? 0) > 0 && !hideAudioPlayer;

    const handleSendingAcknowledgement = () => {

        props.sendingAcknowledge(true);
        setIsSendingAcknowledge(true);

        const request: PostAcknowledgedRequest = {
            RuleInstanceId: props.ruleInstance?.RuleInstanceId ?? "",
            AcknowledgementType: "acknowledged",
            DeviceId: null,
            UsersTime: new Date(),
        };

        // Send post request to acknowledge the rule
        dispatch(postAcknowledgeStatus(request, (result) => {
            props.sendingAcknowledge(false);
            setIsSendingAcknowledge(false);
            if (result) {
                props.handleSentAcknowledgeStatus();
                setSentAcknowledgedStatus(true);
            }
        }));
    }

    useEffect(() => {
        try {
            if (audioState.isPlaying) {
                setHideAudioPlayer(false);
                audioPlayer?.audioEl.current?.play();
            } else {
                audioPlayer?.audioEl.current?.pause();
            }
        } catch (error) {
            console.error(error);
        }

    }, [audioState.isPlaying, audioState.currentAudioIndex]);

    const handleOnStartDownloading = () => {
        dispatch(setAutoPlay(true));
        playAudio(0);
    }

    const playAudio = (index: number) => {
        if (!props.ruleInstance || !props.contentRevision) return;
        if (audioState.audioMap?.has(index)) {
            dispatch(startAudio(index));
        } else {
            dispatch(getAudioFile(props.ruleInstance, props.contentRevision.ContentRevisionId, index, (result) => {
                result ? dispatch(startAudio(index)) : dispatch(stopAudio());
            }));
        }
    }

    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
        }}>
            {props.displayFeedbackButtons && (<>
                <Fab
                    size="medium"
                    variant={isAcknowledgeButtonHover ? "extended" : "circular"}
                    onClick={() => handleSendingAcknowledgement()}
                    onMouseOut={() => setIsAcknowledgeButtonHover(false)}
                    onMouseOver={() => setIsAcknowledgeButtonHover(true)}
                    sx={{
                        backgroundColor: fontColor.orangeTitle,
                        color: "white",
                        "&:hover": {
                            backgroundColor: alpha(fontColor.orangeTitle, 0.8),
                        }
                    }}
                    disabled={isSendingAcknowledge || props.disableButtons || sentAcknowledgeStatus}
                >
                    <CheckIcon />
                    <Collapse
                        orientation="horizontal"
                        in={isAcknowledgeButtonHover}
                        timeout={{ appear: 500, enter: 500, exit: 0 }}
                    >
                        <Typography sx={{
                            ml: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize: 15
                        }} >Mark as Understood</Typography>
                    </Collapse>
                </Fab>
                <Fab
                    size="medium"
                    onClick={() => { setIsOpenFeedBack(true) }}
                    sx={{
                        ml: 1,
                        backgroundColor: fontColor.orangeTitle,
                        color: "white",
                        "&:hover": {
                            backgroundColor: alpha(fontColor.orangeTitle, 0.8),
                        }
                    }}
                    disabled={props.disableButtons || sentAcknowledgeStatus}>
                    <MessageIcon></MessageIcon>
                </Fab>
            </>)}
            {localStorage.getItem("muteAudio") === null ? 
            <Fab
                size="medium"
                variant={readyToPlayAudio ? "extended" : "circular"}
                onClick={() => handleOnStartDownloading()}
                sx={{
                    ml: 1,
                    backgroundColor: fontColor.orangeTitle,
                    color: "white",
                    px: "4px",
                    height: readyToPlayAudio ? "32px" : "48px",
                    "&:hover": {
                        backgroundColor: alpha(fontColor.orangeTitle, 0.8),
                    }
                }}
                disabled={props.disableButtons || audioState.isDownloading}>
                {audioState.isDownloading &&
                    (
                        <Box sx={{ position: "absolute", mt: 1, zIndex: 9999 }}>
                            <CircularProgress size="1.5rem" sx={{ color: fontColor.orangeTitle }} />
                        </Box>
                    )
                }
                {!readyToPlayAudio && <VolumeUpOutlinedIcon />}
                <Collapse
                    orientation="horizontal"
                    in={readyToPlayAudio}
                    timeout={{ appear: 500, enter: 500, exit: 0 }}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <ReactAudioPlayer
                            src={audioState.currentAudioSource}
                            ref={(element) => { setAudioPlayer(element) }}
                            controls
                            style={{ height: "24px", background: "none" }}
                            title=" "
                            onPlay={() => dispatch(startAudio(audioState.currentAudioIndex ?? 0))}
                            onPause={() => dispatch(stopAudio())}
                            onEnded={() => {
                                if (audioState.autoPlay
                                    && ((audioState.currentAudioIndex ?? 0) + 1 < (props.ruleInstance?.Paragraphs?.length ?? -1))) {
                                    playAudio((audioState.currentAudioIndex ?? 0) + 1)
                                }
                            }}
                        />
                        <CloseIcon
                            sx={{ color: "white", fontSize: 16, ml: 0.5 }}
                            onClick={((e) => {
                                e.stopPropagation();
                                setHideAudioPlayer(true);
                                dispatch(stopAudio());
                            })} />
                    </Box>
                </Collapse>
            </Fab> : null}

            {
                props.ruleInstance &&
                <AppDialog
                    open={isOpenFeedBack}
                    dividers
                    onClose={() => { setIsOpenFeedBack(false) }}
                    title={
                        <Box sx={{ display: "flex" }}>
                            <MessageIcon sx={{ marginRight: 1, pl: 0 }} />
                            <Typography
                                component="h6"
                                sx={{
                                    mb: 1,
                                    fontWeight: Fonts.SEMI_BOLD,
                                }}
                            >
                                {props.ruleInstance.DisplayName}
                            </Typography>
                        </Box>}>
                    <Chat
                        ruleInstanceId={props.ruleInstance.RuleInstanceId}
                        responseMode={false}
                        enableEscalation={false}
                        currentTeam={undefined}
                    />
                </AppDialog>
            }
        </Box >
    );
}

export default RuleDetailFooter;
