import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { VirtualTeam } from "../../../types/models/Team";
import { theme } from "../../../themes/theme";
import { AppState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { TeamMembersState } from "../../../types/models/TeamMembersState";
import { RoleMap } from "../../../constants/AppEnums";
import { useTeams } from "../../../hooks/useTeams";
import { TeamMemberProgress } from "../../../types/models/TeamMember";
import EmptyResult from "../../rules/EmptyResult";
import ProgressPieChart from "../../../components/TableItems/ProgressPieChart";
import PlusMoreLabel from "../../../components/TableItems/PlusMoreLabel";
import MemberModal from "../MemberModal.tsx";
import { MyTeamId } from "..";
import { RuleUnitState } from "../../../types/models/RuleUnitState";
import "./MemberTableStyle.scss";
import { getMetaData, isOverdue } from "../../../utils/RuleUtil";
import { UnreadAlert } from "./UnreadAlert";
import { AcknowledgeAlert } from "./AcknowledgeAlert";
import { getAuthUserInfoFromToken } from "../../../components/AppBase/AppAuthProvider";
import { RoleState } from "../../../types/models/RoleState";

interface MemberTableProp {
    team?: VirtualTeam,
    allMemberProgress: boolean,
    onOpenEditDialog: (team: VirtualTeam) => void
    displayTeamProgress: TeamMemberProgress[] | undefined;
    setDisplayTeamProgress: (displayTeamProgress: TeamMemberProgress[] | undefined) => void;
    targetTeamProgress: TeamMemberProgress[] | undefined;
    setTargetTeamProgress: (targetTeamProgress: TeamMemberProgress[] | undefined) => void;
}

const MemberTable: React.FC<MemberTableProp> = (prop) => {
    const userInfo = getAuthUserInfoFromToken();
    const { team_id, user_id } = useParams();
    const teamState = useSelector<AppState, TeamMembersState>((state) => state.teamMembers);
    const ruleState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
    const roleState = useSelector<AppState, RoleState>((state) => state.role);
    const alerts = ruleState.baseAlertContentRevisionDetails?.map((baseAlert) => baseAlert.RuleInstances?.map((rule) => ({Metadata: rule.Metadata, RuleInstanceId: rule.RuleInstanceId}))).flat();

    const { calculateRulesProgress, filterAlertProgress, getProgressForTeam, isLoading } = useTeams();

    const [selectedMember, setSelectedMember] = useState<TeamMemberProgress | undefined>();
    const [openMemberDetail, setOpenMemberDetail] = useState<boolean>(false);

    const [orderDirections, setOrderDirections] = useState<Array<"asc" | "desc">>(["asc", "asc", "asc"]);
    const [sortFlags, setSortFlags] = useState<Array<boolean>>([false, false, false]);

    const handleSortRequest = (index: number) => {

        if (!prop.displayTeamProgress) return;

        // Display sort allow
        const newSortFlags: Array<boolean> = JSON.parse(JSON.stringify(sortFlags));
        for (let i = 0; i < newSortFlags.length; i++) {
            newSortFlags[i] = (i === index);
        }
        setSortFlags(newSortFlags);

        // Change allow direction
        const newOrderDirections: Array<"asc" | "desc"> = JSON.parse(JSON.stringify(orderDirections));
        newOrderDirections[index] = newOrderDirections[index] === "asc" ? "desc" : "asc";
        setOrderDirections(newOrderDirections);

        // Sort data
        const forward = (newOrderDirections[index] === "asc") ? 1 : -1;
        const behind = (newOrderDirections[index] === "asc") ? -1 : 1;
        const sortedMembers = prop.displayTeamProgress.sort((a, b) => {
            let percentA = 0;
            let percentB = 0;
            if (index === 2 && a.completion && b.completion) {
                percentA = a.completion.total == 0 ? 100 : (a.completion.completed / a.completion.total);
                percentB = b.completion.total == 0 ? 100 : (b.completion.completed / b.completion.total);
            }

            switch (index) {
                case 0:
                    return (a.name?.toLowerCase() ?? "") > (b.name?.toLowerCase() ?? "") ? forward : behind;
                case 1:
                    return (a.roles?.join().toLowerCase() ?? "") > (b.roles?.join().toLowerCase() ?? "") ? forward : behind;
                case 2:
                    return (getLocationNames(a.locations ?? []).join().toLowerCase()) > (getLocationNames(b.locations ?? []).join().toLowerCase()) ? forward : behind;
                case 3:
                    // The question mark is always last
                    if (!a.progress || a.progress.length === 0) {
                        return behind;
                    }
                    else if (!b.progress || b.progress.length === 0) {
                        return forward;
                    }
                    else {
                        return percentA > percentB ? forward : behind;
                    }
                default:
                    return 0;
            }
        });

        prop.setDisplayTeamProgress(sortedMembers);
    };

    useEffect(() => {
        // Display selected team
        if (prop.team && prop.team.TeamId !== MyTeamId) {
                // Clear progress to avoid flashing display for previous progress
                prop.setDisplayTeamProgress(undefined);
                getProgressForTeam(prop.team.TeamId, (progress) => {
                    prop.setTargetTeamProgress(progress.sort((a, b) => a.name.localeCompare(b.name)));
                    openUserModalForURLParam(progress);
                });
            clearSortingStates();
        }

    }, [prop.team]);

    const clearSortingStates = () => {
        // Initialise sorting states
        setOrderDirections(["asc", "asc", "asc"]);
        setSortFlags([false, false, false]);
    };

    useEffect(() => {
        // Display "My Team"
        if (prop.allMemberProgress && teamState.orgProgress && !prop.team) {
            prop.setTargetTeamProgress(teamState.orgProgress);
            openUserModalForURLParam(teamState.orgProgress);
            clearSortingStates();
        }
    }, [teamState.orgProgress, prop.allMemberProgress]);

    useEffect(() => {
        // Calculate pie chart values
        if (prop.targetTeamProgress && ruleState.baseAlertRules) {
            const progress = [...prop.targetTeamProgress];
            progress.forEach(member => {
                const progressCount = calculateRulesProgress(member.progress ?? [], member.roles ?? [], member.locations ?? []);
                member.completion = { completed: progressCount.completed, total: progressCount.total };
            });

            prop.setDisplayTeamProgress(progress);
        }
    }, [prop.targetTeamProgress, ruleState.baseAlertRules]);

    const openUserModalForURLParam = (progress: TeamMemberProgress[] | undefined) => {

        if (!progress) return;

        // Open member's modal if the user id is specified in URL
        if (team_id && user_id && (prop.team?.TeamId === team_id || (team_id === MyTeamId && prop.allMemberProgress))) {
            const user = progress.find(member => member.user_id === user_id);
            if (user) {
                setSelectedMember(user);
                setOpenMemberDetail(true);
            }
        }
    };

    const SkeletonRow = () => {
        return <TableRow >
            {Array.from({ length: 3 }, (_, i) => <TableCell key={i} height={"52px"}><Skeleton variant="text" height='1.5em' /></TableCell>)}
        </TableRow>
    };

    const createRolesCell = (roles: string[]) => {
        const convertedRoles = roles?.map(role => RoleMap.get(role)) ?? [];
        if (convertedRoles.length > 2) {
            const firstRole = convertedRoles[0];
            return (
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    {firstRole}
                    <PlusMoreLabel moreCount={convertedRoles.length - 1} />
                </Box>
            );
        } else {
            return convertedRoles.join(", ");
        }
    };

    const createPlusMore = (values: string[]) => {
        if(values.length > 1) {
            const firstValue = values[0];
            return (
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    {firstValue}
                    <PlusMoreLabel moreCount={values.length - 1} />
                </Box>
            );
        }
        else {
            return values.join(", ");
        }
    }

    const getLocationNames = (locationIds: string[]) => {
        const locationNames: string[] = [];
        const knownLocations = roleState.knownRoles?.locations;

        locationIds.forEach((id) => {
            const matchedId = knownLocations?.find((location) => location.LNIId === id);
            if(matchedId) {
                locationNames.push(`${matchedId.LNIId} - ${matchedId.Name}`);   
            }
        })
        return locationNames;
    }

    const createRuleProgressPieChart = (member: TeamMemberProgress) => {
        if ((member.progress?.length ?? 0) === 0) {
            // Unknown person who is in Team but not in Organisation
            return (
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <Box sx={{ display: "flex", height: "50px", width: "50px", justifyContent: "center", alignItems: "center" }}>
                        <QuestionMarkIcon sx={{ mt: 0, height: "32px", width: "46px", color: "lightgray" }} />
                    </Box>
                </Box>
            );
        }
        else {
            const notAcknowledgedTotal = (member.completion?.total ?? 0) - (member.completion?.completed ?? 0);
            return (
                <Box sx={{position: "relative"}}>
                    <ProgressPieChart total={member.completion?.total ?? 0} completed={member.completion?.completed ?? 0} />
                    {notAcknowledgedTotal > 0 && notAcknowledgedTotal < 10 ? 
                        <span className="not-acknowledged-small-number">{notAcknowledgedTotal}</span> 
                    : <span className="not-acknowledged-number">{notAcknowledgedTotal}</span> }
                </Box>
            );
        }
    };

    const getOverdueAlerts = (member: TeamMemberProgress) => {
        if(!member.progress || member.progress.length === 0) {
            return null;
        }
        else {
            let overDueCount = 0;
            const userProgress = filterAlertProgress(member.progress, member.roles ?? [], member.locations ?? []);
            const metaDataArray = userProgress.map(prog => ({
                RuleInstanceId: prog.RuleInstanceId,
                metaData: getMetaData(alerts, prog.RuleInstanceId),
                resolved: prog.Resolved,
              }));
              
              metaDataArray.forEach((mdItem) => {
                if(isOverdue(mdItem.metaData ?? "") && !mdItem.resolved) {
                    overDueCount++;
                }
              });
              if(overDueCount > 0) {
                return <AcknowledgeAlert count={overDueCount} />
              }
              return null;
        }
    }

    const getUnreadMessages = (member: TeamMemberProgress) => {
        if(!member.progress || member.progress.length === 0 || (userInfo?.Email === member.email)) {
            return null;
        }
        else {
            const userProgress = filterAlertProgress(member.progress, member.roles ?? [], member.locations ?? []);
            let isUnread = false;
            userProgress.map((progress) => {
                if(!progress.Resolved) {
                    if(progress.SenderId !== null) {
                        if(progress.SenderId === progress.UserId) {
                            isUnread = true;
                        }
                    }
                    else if(progress.SenderId === null && progress.DisplayMessageCount === 1) {
                        isUnread = true;
                    }
                }
                else {
                    return false;
                }
            });
            return (
                <>
                {isUnread ? 
                    <UnreadAlert /> : null}
                </>
            )
        }
    }

    const onClickMember = (member: TeamMemberProgress) => {
        setSelectedMember(member);
        setOpenMemberDetail(true);
    };

    return (
        <>
            <TableContainer component={Paper} sx={{ borderRadius: 3 }}>
                <Table size="small">
                    <TableHead sx={{ backgroundColor: theme.table.subHeaderBgColor }}>
                        <TableRow>
                            <TableCell onClick={() => handleSortRequest(0)}>
                                <TableSortLabel active={sortFlags[0]} direction={orderDirections[0]}>
                                    Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell onClick={() => handleSortRequest(1)}>
                                <TableSortLabel active={sortFlags[1]} direction={orderDirections[1]}>
                                    Roles
                                </TableSortLabel>
                            </TableCell>
                            {/*TODO: Update sort index when location is available */}
                             <TableCell onClick={() => handleSortRequest(2)}>
                                <TableSortLabel active={sortFlags[2]} direction={orderDirections[2]}>
                                    Locations
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center" width={"120px"} onClick={() => handleSortRequest(3)}>
                                <TableSortLabel active={sortFlags[3]} direction={orderDirections[3]}>
                                    Alerts
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            "& .MuiTableRow-root:hover": {
                                backgroundColor: theme.table.hoverBgColor
                            }
                        }}>
                        {(isLoading || !prop.displayTeamProgress) ?
                            (
                                <>
                                    <SkeletonRow />
                                    <SkeletonRow />
                                </>
                            ) : (
                                prop.displayTeamProgress?.length === 0 ?
                                    <TableRow>
                                        <TableCell sx={{ p: 0 }} colSpan={8}>
                                            <EmptyResult>No members available</EmptyResult>
                                        </TableCell>
                                    </TableRow>
                                    :
                                    prop.displayTeamProgress?.map((member, index) => (
                                        <TableRow key={member.user_id + member.email + index} hover onClick={() => onClickMember(member)}>
                                            <TableCell>
                                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                    {member.name ? member.name : member.email}
                                                    <Typography sx={{ fontSize: 13, color: theme.table.subTextColor }} >{member.email}</Typography>
                                                    <Box sx={{mt: 1, mb: 0.5 }}>{getOverdueAlerts(member)}</Box>
                                                    <Box sx={{mt: 1, mb: 0.5 }}>{getUnreadMessages(member)}</Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell>{createRolesCell(member.roles ?? []) ? createRolesCell(member.roles ?? []) : <Typography sx={{ fontSize: 13, color: theme.table.subTextColor }}>User has no roles assigned</Typography>}</TableCell>
                                            <TableCell>{createPlusMore(getLocationNames(member.locations ?? [])) ? createPlusMore(getLocationNames(member.locations ?? [])) : <Typography sx={{ fontSize: 13, color: theme.table.subTextColor }}>User has no locations assigned</Typography>}</TableCell>
                                            <TableCell>{createRuleProgressPieChart(member)}</TableCell>
                                        </TableRow>
                                    ))
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
            {selectedMember &&
                <MemberModal
                    onClose={() => setOpenMemberDetail(false)}
                    open={openMemberDetail}
                    user={selectedMember}
                    userProgress={filterAlertProgress(selectedMember.progress ?? [], selectedMember.roles ?? [], selectedMember.locations ?? [])}
                    selectedMember={selectedMember}
                    setSelectedMember={setSelectedMember}
                    currentTeam={prop.team}
                    alerts={alerts}
                />}
        </>
    );
};

export default MemberTable;
