import { Box, Container, Hidden, IconButton, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useMsal } from "@azure/msal-react";
import { theme } from "../../themes/theme";
import AppInfoView from "../../components/AppBase/AppInfoView";
import { useDispatch } from "react-redux";
import { toggleNavCollapsed } from "../../redux/actions";
import { fontColor } from "../../constants/ColorSets";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const { accounts } = useMsal();
  return (
    <>
      <Container
        sx={{
          backgroundColor: theme.page.bgColor,
          minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            my: 5,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start"
          }}>
          {(accounts && accounts[0]) && (
            <Hidden lgUp>
              <IconButton
                sx={{
                  color: "text.secondary",
                  ml: 0.2,
                  mr: 2,
                  padding: 0,
                }}
                edge="start"
                className="menu-btn"
                color="inherit"
                aria-label="open drawer"
                onClick={() => dispatch(toggleNavCollapsed())}
                size="large">
                <MenuIcon
                  sx={{
                    width: 35,
                    height: 35,
                  }}
                />
              </IconButton>
            </Hidden>
          )}
          <Box sx={{ py: 1 }}>
            <Typography
              variant="h1"
              color={fontColor.titleFontColor}>
              Shield Privacy Statement
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mb: 5 }}>
          <Typography variant="body1" sx={{ fontSize: 18 }}>
            We collect the following personal information from you, when you
            use the SHIELD Application:
            <ul>
              <li>Personal contact details.</li>
              <li>Location.</li>
              <li>
                Responses to Health and Safety notifications within the SHIELD
                Application.
              </li>
            </ul>
          </Typography>
        </Box>
        <Box sx={{ my: 5 }}>
          <Typography variant="body1" sx={{ fontSize: 18 }}>
            We collect your personal information for the purposes of:
            <ul>
              <li>Providing you with access to the SHIELD Application.</li>
              <li>
                Displaying data to assure that you are safe when working on
                the Rail Network.
              </li>
            </ul>
          </Typography>
        </Box>
        <Box sx={{ my: 5 }}>
          <Typography variant="body1" sx={{ fontSize: 18 }}>
            By using the SHIELD Application, you agree that we may disclose
            your personal information to the following parties, to the extent
            necessary to achieve the purposes listed above:
            <ul>
              <li>
                Auditing bodies such as{" "}
                <a href="https://www.worksafe.govt.nz/">Work Safe NZ</a> and{" "}
                <a href="https://www.nzta.govt.nz/">Waka Kotahi</a>, to
                provide evidence that we are assuring some of their health and
                safety regulatory requirements.
              </li>
              <li>
                Your employer so they too can assure your ability to operate
                safely on the Rail Network.
              </li>
            </ul>
          </Typography>
        </Box>
        <Box sx={{ my: 5 }}>
          <Typography variant="body1" sx={{ fontSize: 18 }}>
            You consent to the disclosure of your personal information in
            accordance with this Privacy Statement to the parties listed
            above.
          </Typography>
        </Box>
        <Box sx={{ my: 5 }}>
          <Typography variant="body1" sx={{ mb: 2, fontSize: 18 }}>
            Providing some information is optional. If you choose not to enter
            personal details, we&apos;ll be unable to provide access to the
            SHIELD Application.
          </Typography>

          <Typography variant="body1" sx={{ fontSize: 18 }}>
            <strong>Important:</strong> please ensure you use the same contact
            details as other KiwiRail Systems you already have access to
            (example: your email address).
          </Typography>
        </Box>
        <Box sx={{ my: 5 }}>
          <Typography variant="body1" sx={{ fontSize: 18 }}>
            We hold all your personal information in accordance with the
            Privacy Act 2020. You have the right to ask for a copy of any
            personal information we hold about you, and to ask for it to be
            corrected if you think it is wrong. If you&apos;d like to ask for
            a copy of your information, or to have it corrected, please
            contact us at{" "}
            <a href="mailto:privacy@kiwirail.co.nz">privacy@kiwirail.co.nz</a>
            , or <a href="tel:044983209">04 498 3209</a>, or Private Bag
            39988, Lower Hutt 5045.
          </Typography>
        </Box>
      </Container>
      <AppInfoView />
    </>
  );
};

export default PrivacyPolicy;
