import { TeamMemberProgress } from "../models/TeamMember";
import { VirtualTeam } from "../models/Team";
import { RISUser } from "../models/RISUser";
import { TeamsSearchResult } from "../models/TeamsSearchResult";
import { TeamProgressModel } from "../models/TeamProgressModel";

export const GET_TEAM_MEMBERS = "GET_TEAM_MEMBERS";
export const GET_PROGRESS_OF_TEAM = "GET_PROGRESS_OF_TEAM";
export const GET_USERS = "GET_USERS";
export const GET_ORG_TEAMS = "GET_ORG_TEAMS";
export const SEARCH_ORG_TEAMS = "SEARCH_ORG_TEAMS";
export const GET_MY_TEAMS = "GET_MY_TEAMS";

export interface GetTeamMembers {
  type: typeof GET_TEAM_MEMBERS;
  payload: TeamMemberProgress[] | undefined;
}

export interface GetUsers {
  type: typeof GET_USERS;
  payload: RISUser[] | undefined;
}

export interface GetOrgTeams {
  type: typeof GET_ORG_TEAMS;
  payload: VirtualTeam[] | undefined;
}

export interface SearchOrgTeams {
  type: typeof SEARCH_ORG_TEAMS;
  payload: TeamsSearchResult;
}

export interface GetMyTeams {
  type: typeof GET_MY_TEAMS;
  payload: VirtualTeam[] | undefined;
}

export interface GetProgressOfTeam {
  type: typeof GET_PROGRESS_OF_TEAM;
  payload: TeamProgressModel;
}

export type TeamMembersActions =
  | GetUsers
  | GetOrgTeams
  | GetTeamMembers
  | SearchOrgTeams
  | GetMyTeams
  | GetProgressOfTeam;
