import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { theme } from "../../themes/theme";
import AppInfoView from "../../components/AppBase/AppInfoView";
import UserProfileHeader from "./UserProfileHeader";
import UserTable from "./UserTable";
import UserSettingsDialog from "./UserSettingsDialog";
import AppDialog from "../../components/AppBase/AppDialog";
import RuleUnitFilter from "../rules/RuleUnitFilter.tsx";
import LaunchIcon from '@mui/icons-material/Launch';

export default function UserProfilePage() {
    const [settingsDialogOpen, setSettingsDialogOpen] = useState<boolean>(false);
    const [muteAudioChecked, setMuteAudioChecked] = useState<boolean>(false);
    const [openRoleLocationModal, setOpenRoleLocationModal] = useState<boolean>(false);

    const handleAudioSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        setMuteAudioChecked(isChecked);

        if(isChecked) {
            localStorage.setItem("muteAudio", JSON.stringify(true));
        }
        else {
            localStorage.removeItem("muteAudio");
        }
    }

    useEffect(() => {
        const isAudioMuted = localStorage.getItem("muteAudio");
        if(isAudioMuted) {
            setMuteAudioChecked(JSON.parse(isAudioMuted));
        }
    }, [])

    return (
        <>
            <Box sx={{ backgroundColor: theme.page.bgColor, minHeight: "100vh" }}>
                <UserSettingsDialog muteAudioChecked={muteAudioChecked} setSettingsDialogOpen={setSettingsDialogOpen} settingsDialogOpen={settingsDialogOpen} handleAudioSwitch={handleAudioSwitch} />
                <UserProfileHeader setSettingsDialogOpen={setSettingsDialogOpen} setOpenRoleLocationModal={setOpenRoleLocationModal}/>
                <Box sx={{ m: 2 }}>
                    <UserTable />
                </Box>
            </Box>
            <AppDialog
                open={openRoleLocationModal}
                title="Manage Roles and Locations"
                hideClose={true}
                onClose={() => { setOpenRoleLocationModal(false) }}>
                <RuleUnitFilter></RuleUnitFilter>
                <Button
                    endIcon={<LaunchIcon />}
                    variant="text"
                    onClick={() => { 
                        if (process.env.REACT_APP_RIS_PORTAL_URL) { 
                            window.open(process.env.REACT_APP_RIS_PORTAL_URL); 
                        } 
                    }}
                    sx={{ mr: 1, textTransform: "none", zIndex: 0}}>
                    <Box>Manage Account</Box>
                </Button>
            </AppDialog >
            <AppInfoView />
        </>
    );
}
