
/**
 * @name RulesHeader
 * @description Header for Rules page
 * @features 
 *  - Search
 *  - Emergency Numbers
 *  - Refresh
 */

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Box, Grid, Hidden, IconButton, InputAdornment, TextField, Tooltip, Typography, useMediaQuery } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import SyncIcon from '@mui/icons-material/Sync';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import { Fonts } from "../constants/AppEnums";
import { toggleNavCollapsed } from "../redux/actions/Setting";
import { onSearchText, checkContentUpdate } from "../redux/actions/RuleUnit";
import { fontColor } from "../constants/ColorSets";
import { AppState } from "../redux/store";
import { RuleUnitState } from "../types/models/RuleUnitState";
import { theme } from "../themes/theme";
import AppDialog from "./AppBase/AppDialog";
import RuleEmergencyList from "../pages/rules/RuleEmergencyList";

interface RulesHeaderProps {
    refreshPage: () => void;
    isRefreshing: boolean;
    title: string;
    displayFilterButton: boolean
}

const RulesHeader: React.FC<RulesHeaderProps> = (prop) => {

    const dispatch = useDispatch();
    const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
    const isScreenLessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

    const [isOpenEmergencyDialog, setIsOpenEmergencyDialog] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>(ruleUnitState.searchText ?? "");

    const myTimer = useRef<NodeJS.Timer>();

    useEffect(() => {
        function startTimer() {
            myTimer.current = setInterval(() => {
                if (!ruleUnitState.isContentUpdated) {
                    dispatch(checkContentUpdate());
                }
            }, 30000);
        }

        startTimer();
        return () => clearInterval(myTimer.current); // cleanup

    }, [ruleUnitState.isContentUpdated]);

    const handleSearchTextChange = (text: string) => {
        dispatch(onSearchText(text));
        setSearchText(text);
    }

    const startRefresh = () => {
        setSearchText("");
        prop.refreshPage();
    }

    return (
        <>
            <Box>
                <Grid sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between"
                }}>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start"
                        }}
                    >
                        <Hidden lgUp>
                            <IconButton
                                sx={{
                                    color: "text.secondary",
                                    marginLeft: 1,
                                    padding: 0,
                                }}
                                edge="start"
                                className="menu-btn"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={() => dispatch(toggleNavCollapsed())}
                                size="medium"
                            >
                                <MenuIcon
                                    sx={{
                                        width: 35,
                                        height: 35,
                                    }}
                                />
                            </IconButton>
                        </Hidden>
                        <Box>
                            <Typography
                                variant="h3"
                                component="h3"
                                color={fontColor.titleFontColor}
                                style={{ fontSize: 22, marginLeft: 12 }}
                            >
                                {prop.title}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <TextField
                            id="search"
                            type="search"
                            label="Search"
                            variant="standard"
                            value={searchText}
                            sx={{ width: isScreenLessThanSmall ? 120 : 200, marginBottom: 1 }}
                            onChange={(event) => { handleSearchTextChange(event.target.value) }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {localStorage.getItem("muteAudio") ? 
                        <Tooltip title="Audio Currently Muted">
                            <VolumeOffOutlinedIcon
                                sx={{
                                color: fontColor.footerAccountBackground,
                                marginTop: 1,
                                marginLeft: 1,
                                padding: isScreenLessThanSmall ? 0 : 1,
                                fontSize: 40
                            }} />
                        </Tooltip> : null}


                        <Tooltip title="Display Emergency Numbers">
                            <IconButton
                                onClick={() => setIsOpenEmergencyDialog(true)}
                                size="large"
                                sx={{
                                    color: fontColor.orangeTitle,
                                    marginTop: 1,
                                    marginLeft: 1,
                                    padding: isScreenLessThanSmall ? 0 : 1
                                }}>
                                <LocalPhoneOutlinedIcon></LocalPhoneOutlinedIcon>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={ruleUnitState.isContentUpdated ? "New content available. Please click the refresh button to sync" : "Sync the content"}>
                            {/* Span is used to ensure there's a child element to fire events when the button is disabled. */}
                            <span>
                                <IconButton
                                    onClick={() => startRefresh()}
                                    size="large"
                                    disabled={prop.isRefreshing}
                                    sx={{
                                        color: fontColor.orangeTitle,
                                        marginTop: 1,
                                        marginLeft: isScreenLessThanSmall ? 1 : 0,
                                        marginRight: prop.displayFilterButton ? 0 : 1,
                                        padding: isScreenLessThanSmall ? 0 : 1
                                    }}>
                                    <Badge color="warning" variant="dot" invisible={!ruleUnitState.isContentUpdated}>
                                        <SyncIcon></SyncIcon>
                                    </Badge>
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                </Grid>
                <Box sx={{ borderBottom: "1px solid lightgrey", mt: 1, mx: 1.5 }}></Box>
            </Box >
            <AppDialog
                title={<Box sx={{ display: "flex" }}>
                    <LocalPhoneOutlinedIcon sx={{ marginRight: 1 }} />
                    <Typography
                        component="h6"
                        sx={{
                            mb: 1,
                            fontWeight: Fonts.SEMI_BOLD,
                        }}
                    >
                        Emergency Numbers
                    </Typography></Box>}
                open={isOpenEmergencyDialog}
                maxWidth='lg'
                dividers={true}
                fullHeight={true}
                onClose={() => { setIsOpenEmergencyDialog(false) }} >
                <RuleEmergencyList />
            </AppDialog >
        </>
    );
}

export default RulesHeader;
