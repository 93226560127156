import { Autocomplete, Chip, TextField } from "@mui/material"
import { RISUser } from "../../../types/models/RISUser";
import CancelIcon from '@mui/icons-material/Cancel';

interface Props {
  name: string;
  emptyError: string | undefined;
  allUsers: RISUser[];
  leaderIds: string[] | undefined;
  memberIds: string[] | undefined;
  handleChange: (event: React.ChangeEvent<unknown>, values: RISUser[] | null) => void;
  getUserDisplayName: (userId: string) => string;
}

export const MemberAutocomplete = ({allUsers, emptyError, getUserDisplayName, handleChange, memberIds, leaderIds, name}: Props) => {
  return (
      <Autocomplete
        className={emptyError ? "Mui-error" : ""}
        multiple
        value={name === "leaders" ? allUsers.filter(user => leaderIds?.includes(user.UserId ?? "")) : allUsers.filter(user => memberIds?.includes(user.UserId ?? ""))}
        options={name === "leaders" ? allUsers.filter(user => user.UserId !== null && !memberIds?.includes(user.UserId ?? "")).sort((a, b) => a.Name.localeCompare(b.Name)) : allUsers.filter(user => user.UserId !== null && !leaderIds?.includes(user.UserId ?? "")).sort((a, b) => a.Name.localeCompare(b.Name))}
        getOptionLabel={(option) => `${option.Name} (${option.Email})`}
        renderInput={(params) => <TextField sx={{textTransform: "capitalize"}} {...params} label={`${name}*`} />}
        onChange={handleChange}
        renderTags={(values: RISUser[], getTagProps) => (
            values.map((value, index) => (
                <Chip
                    {...getTagProps({ index })}
                    key={value.UserId}
                    label={getUserDisplayName(value.UserId ?? "")}
                    clickable
                    deleteIcon={ 
                        <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                        />
                    }
                />
            ))
        )}
    />)
  }