import { EscalateFeedbackInVirtualTeamRequest, EscalateFeedbackRequest } from './../../types/models/EscalateFeebackRequest';
import { Dispatch } from "react";
import axios from "axios";
import jwtAxios from "../../auth/jwtAxios";
import { AppActions } from "../../types";
import { fetchStart, fetchSuccess, fetchError, showMessage } from "./Common";
import { ChatThread } from "../../types/models/ChatThread";
import { GET_RESPONSES_IN_THREAD, GET_SUBORDINATE_RESPONSES, MY_CHAT_THREADS } from "../../types/actions/Chat.actions";
import { ChatMessage } from "../../types/models/ChatMessage";
import { ChatResponse, VirtualTeamChatResponse } from "../../types/models/ChatResponse";
import { PostFeedbackRequest } from "../../types/models/PostFeedbackRequest";
import { ChatMessageResponse } from "../../types/models/ChatMessageResponse";
import { getDisplayErrorMessage } from "../../utils/StringUtils";

export const getMyChatThreads = (callBack: (threads: ChatThread[] | undefined) => void) => {

  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("api/MyChatThreads")
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          const threads: ChatThread[] = data.data.threads;
          dispatch({ type: MY_CHAT_THREADS, payload: threads });
          callBack(threads);
        } else {
          dispatch(fetchError("Something went wrong, Please try again! Status:" + data.status));
          callBack(undefined);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        callBack(undefined);
      });
  };
};

export const getResponsesInThread = (ruleInstanceId: string, callBack?: (messages: ChatMessage[] | undefined) => void) => {

  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("api/GetResponsesInThread?ruleInstanceId=" + ruleInstanceId)
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          const response: ChatMessageResponse = {
            RuleInstanceId: ruleInstanceId,
            Messages: data.data.messages
          };

          response.Messages.sort((a, b) => a.SentAt > b.SentAt ? 1 : -1);

          dispatch({ type: GET_RESPONSES_IN_THREAD, payload: response });
          if (callBack) callBack(response.Messages);
        } else {
          dispatch(fetchError("Something went wrong, Please try again! Status:" + data.status));
          if (callBack) callBack(undefined);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        if (callBack) callBack(undefined);
      });
  };
};

export const startFeedbackThread = (request: PostFeedbackRequest, callBack: (result: boolean) => void) => {

  return (dispatch: Dispatch<AppActions>) => {

    jwtAxios
      .post("api/SendFeedback", request)
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          callBack(true);

        } else {
          dispatch(fetchError("Something went wrong, Please try again! Status:" + data.status));
          callBack(false);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        callBack(false);
      });
  };
};

export const sendFeedbackResponse = (request: ChatResponse, callBack: (result: boolean) => void) => {

  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .post("api/SendFeedbackResponse", request)
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          callBack(true);

        } else {
          dispatch(fetchError("Something went wrong, Please try again! Status:" + data.status));
          callBack(false);
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        callBack(false);
      });
  };
};

export const sendTeamFeedbackResponse = (request: ChatResponse, callBack: (result: boolean) => void) => {

  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .post("api/SendTeamFeedbackResponse", request)
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          callBack(true);

        } else {
          dispatch(fetchError("Unable to send a response right now. Please try again later or contact support"));
          callBack(false);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        callBack(false);
      });
  };
};

export const sendFeedbackResponseForVirtualTeam = (request: VirtualTeamChatResponse, callback: (result: boolean) => void) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .post("api/SendTeamFeedbackResponseToVirtualTeamMember", request)
      .then((data) => {
        if(data.status === 200) {
          dispatch(fetchSuccess());
          callback(true);
        }
        else {
          dispatch(fetchError("Unable to send a response right now. Please try again later or contact support"));
          callback(false);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        callback(false);
      })
  }
}

export const getSubordinateResponses = (ruleInstanceId: string, subordinateId: string, callBack: (messages: ChatMessage[] | undefined) => void) => {

  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .get(`api/GetResponsesForSubordinateById?ruleInstanceId=${ruleInstanceId}&subordinateId=${subordinateId}`)
      .then((data) => {
        if (data.status === 200) {
          const messages: ChatMessage[] = data.data.messages;
          const sortedMessages = messages.sort((a, b) => a.SentAt > b.SentAt ? 1 : -1);
          const response: ChatMessageResponse = {
            RuleInstanceId: ruleInstanceId,
            UserId: subordinateId,
            Messages: sortedMessages
          };
          dispatch({ type: GET_SUBORDINATE_RESPONSES, payload: response });
          callBack(response.Messages);
        } else {
          callBack(undefined);
        }
      })
      .catch(() => {
        callBack(undefined);
      });
  };
};

export const getSubordinateResponsesForTeam = (ruleInstanceId: string, subordinateId: string, teamId: string, callback: (messages: ChatMessage[] | undefined) => void) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .get(`api/GetResponsesForSubordinateByIdInTeam?ruleInstanceId=${ruleInstanceId}&subordinateId=${subordinateId}&teamId=${teamId}`)
      .then((data) => {
        if(data.status === 200) {
          const messages: ChatMessage[] = data.data.messages;
          const sortedMessages = messages.sort((a, b) => a.SentAt > b.SentAt ? 1 : -1);
          const response: ChatMessageResponse = {
            RuleInstanceId: ruleInstanceId,
            UserId: subordinateId,
            Messages: sortedMessages,
          };
          dispatch({ type: GET_SUBORDINATE_RESPONSES, payload: response });
          callback(response.Messages);
        }
        else {
          callback(undefined);
        }
      })
      .catch(() => {
        callback(undefined);
      })
  }
}

export const escalateFeedback = (request: EscalateFeedbackRequest, callback: (result: boolean) => void)=> {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .post("api/EscalateFeedback", request)
      .then((data) => {
        if(data.status === 200) {
          dispatch(fetchSuccess());
          callback(true);
          dispatch(showMessage("Chat escalated successfully!"));
        }
        else {
          dispatch(fetchError("Failed to escalate feedback. Please try again. Status: " + data.status));
          callback(false);
        }
      })
      .catch((error) => {
        if(error.response.data.detail !== undefined) {
          dispatch(fetchError("Failed to escalate feedback. Error: " + error.response.data.detail));
        }
        else {
          dispatch(fetchError("Failed to escalate feedback. Please try again or contact Shield support."));
        }

        callback(false);
      });
  };
};

export const escalateFeedbackInVirtualTeam = (request: EscalateFeedbackInVirtualTeamRequest, callback: (result: boolean) => void) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .post("api/EscalateFeedbackInVirtualTeam", request)
      .then((data) => {
        if(data.status === 200) {
          dispatch(fetchSuccess());
          callback(true);
          dispatch(showMessage("Chat escalated successfully!"));
        }
        else {
          dispatch(fetchError("Failed to escalate feedback. Please try again. Status: " + data.status));
          callback(false);
        }
      })
      .catch((error) => {
        if(error.response.data.detail !== undefined) {
          dispatch(fetchError("Failed to escalate feedback. Error: " + error.response.data.detail));
        }
        else {
          dispatch(fetchError("Failed to escalate feedback. Please try again or contact Shield support."));
        }

        callback(false);
      })
  }
}