import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { Fonts } from "../constants/AppEnums";
import { fontColor } from "../constants/ColorSets";
import AppLogo from "./AppBase/AppLogo";

const LoadingError = () => {

  const { instance, accounts } = useMsal();

  const logout = () => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts[0].homeAccountId),
      postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI
    };
    instance.logoutRedirect(logoutRequest);
  }

  return (
    <Box
      sx={{
        py: { xl: 8 },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          margin: 8,
          backgroundColor: "black",
          width: "100%",
          height: "50px",
          maxWidth: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <AppLogo />
      </Box>
      <Box sx={{ mb: { xs: 4, xl: 5 } }}>
        <Box
          component="h3"
          sx={{
            mb: { xs: 3, xl: 4 },
            fontSize: { xs: 20, md: 24 },
            fontWeight: Fonts.MEDIUM,
          }}
        >
          Failed
        </Box>
        <Box
          sx={{
            mb: { xs: 4, xl: 5 },
            color: fontColor.subTitleFontColor,
            fontSize: 16,
            fontWeight: Fonts.MEDIUM,
          }}
        >
          <Typography>Failed to request. Please log out and log in again.</Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          sx={{
            fontWeight: Fonts.MEDIUM,
            fontSize: 16,
            textTransform: "capitalize",
          }}
          onClick={() => logout()}
        >
          Logout
        </Button>
      </Box>
    </Box>
  );
};

export default LoadingError;
