import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, darken, TextField, Tooltip, Fab, alpha, CircularProgress, InputAdornment } from '@mui/material';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import SouthOutlinedIcon from '@mui/icons-material/SouthOutlined';
import { escalateFeedback, escalateFeedbackInVirtualTeam, getMyChatThreads, getResponsesInThread, getSubordinateResponses, getSubordinateResponsesForTeam, sendFeedbackResponse, sendFeedbackResponseForVirtualTeam, sendTeamFeedbackResponse, startFeedbackThread } from '../../redux/actions/Chat';
import { theme } from '../../themes/theme';
import { AppState } from '../../redux/store';
import { ChatState, SubordinateChatKey } from '../../types/models/ChatState';
import { ChatMessage } from '../../types/models/ChatMessage';
import { ChatResponse, VirtualTeamChatResponse } from '../../types/models/ChatResponse';
import { PostFeedbackRequest } from '../../types/models/PostFeedbackRequest';
import { getAuthUserInfoFromToken } from '../AppBase/AppAuthProvider';
import { AuthUserInfo } from '../../types/models/AuthUserInfo';
import { RuleUnitState } from '../../types/models/RuleUnitState';
import AppScrollbar from '../AppBase/AppScrollbar';
import MessageList from './MessageList';
import { Acknowledgment } from '../../types/models/Acknowledgement';
import { TeamMemberProgress } from '../../types/models/TeamMember';
import AppConfirmDialog from '../AppBase/AppConfirmDialog';
import { EscalateFeedbackInVirtualTeamRequest, EscalateFeedbackRequest } from '../../types/models/EscalateFeebackRequest';
import { VirtualTeam } from '../../types/models/Team';
import { MyTeamId } from '../../pages/your-teams';
import { EscalateIcon } from '../AppBase/EscalateIcon';
import { escalatedMessage } from '../../constants/AppEnums';

interface ChatProp {
    ruleInstanceId: string,
    responseMode?: boolean,
    subordinateId?: string,
    displayDateTime?: boolean
    userProgress?: Acknowledgment[];
    selectedMember?: TeamMemberProgress | undefined;
    setSelectedMember?: (selectedMember: TeamMemberProgress) => void;
    enableEscalation: boolean;
    currentTeam: VirtualTeam | undefined;
}

export default function Chat(prop: ChatProp) {

    const CHAT_REFRESH_INTERVAL = 30000;

    const dispatch = useDispatch();
    const chatState = useSelector<AppState, ChatState>((state) => state.chat);
    const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
    const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
    const [newMessage, setNewMessage] = useState<string>();
    const [isThreadExist, setIsThreadExist] = useState<boolean>(false);
    const [displayScrollDownFab, setDisplayScrollDownFab] = useState<boolean>(false);
    // const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [senderInfo, setSenderInfo] = useState<AuthUserInfo | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isRequesting, setIsRequesting] = useState<boolean>(false);
    const [escalateDialogOpen, setEscalateDialogOpen] = useState<boolean>(false);
    const [hasBeenEscalated, setHasBeenEscalated] = useState<boolean>(false);

    // const openEmoji = Boolean(anchorEl);
    // const emojiId = openEmoji ? 'simple-popover' : undefined;
    const messagesEndRef = React.createRef<HTMLDivElement>();
    const myTimer = useRef<NodeJS.Timer>();

    const updateTeamProgress = (messages: ChatMessage[] | undefined) => {
        if(prop.selectedMember && messages) {
            const selectedMemberCopy = {...prop.selectedMember};
            const userProgressCopy = [...prop.selectedMember.progress ?? []];
            const returnedMessages = messages.sort((a, b) => new Date(a.SentAt).getTime() - new Date(b.SentAt).getTime());
            const lastReturnedMessage = returnedMessages[returnedMessages.length - 1];
            const newProgressObj = userProgressCopy.find((obj) => obj.RuleInstanceId === lastReturnedMessage.RuleInstanceId);

            if(newProgressObj && newProgressObj?.UserId !== lastReturnedMessage.UserId) {
                newProgressObj.SenderId = lastReturnedMessage.UserId;
                const updatedProgress = userProgressCopy.map((obj) => obj.RuleInstanceId === newProgressObj?.RuleInstanceId ? newProgressObj : obj);
                selectedMemberCopy.progress = updatedProgress;
                if(prop.setSelectedMember) prop.setSelectedMember(selectedMemberCopy);
            }
            else {
                selectedMemberCopy.progress =  userProgressCopy;
                if(prop.setSelectedMember) prop.setSelectedMember(selectedMemberCopy);
            }


        }
    }

    useEffect(() => {
        if(chatMessages && chatMessages.length > 0) {
            setHasBeenEscalated(chatMessages.some((message) => 
                message.Message.includes(escalatedMessage)));
        }
    }, [chatMessages])

    useEffect(() => {
        setSenderInfo(getAuthUserInfoFromToken());
    }, []);

    useEffect(() => {
        if (messagesEndRef.current) {
            // Check if the bottom component is displaying
            const observer = new IntersectionObserver(([entry]) =>
                setDisplayScrollDownFab(!entry.isIntersecting)
            );

            observer.observe(messagesEndRef.current);
            return () => {
                observer.disconnect();
            };
        }
    }, [messagesEndRef.current]);

    useEffect(() => {
        if (prop.responseMode) {
            // Display the messages in memory first
            const key: SubordinateChatKey = { RuleInstanceId: prop.ruleInstanceId, UserId: prop.subordinateId ?? "" };
            if (chatState.subordinateChatMessageMap && chatState.subordinateChatMessageMap.has(key)) {
                setChatMessages(chatState.subordinateChatMessageMap.get(key) ?? []);
            }

            refreshThreadResponse();

        } else {
            // Display the messages in memory first
            if (chatState.chatMessageMap && chatState.chatMessageMap.has(prop.ruleInstanceId)) {
                setChatMessages(chatState.chatMessageMap.get(prop.ruleInstanceId) ?? []);
            }

            // Get current messages even if the messages is already loaded for this alert
            if (!chatState.chatThreads) {
                dispatch(getMyChatThreads((threads) => {
                    if (threads && threads.some(thread => thread.ruleInstanceId === prop.ruleInstanceId)) {
                        setIsThreadExist(true);
                        refreshThreadResponse();
                    } else {
                        setChatMessages([]);
                    }
                }));
            } else if (chatState.chatThreads.some(thread => thread.ruleInstanceId === prop.ruleInstanceId)) {
                setIsThreadExist(true);
                refreshThreadResponse();
            } else {
                setChatMessages([]);
            }
        }

        function startTimer() {
            myTimer.current = setInterval(() => {
                // Repeat loading while the chat window opening
                if (chatState.chatThreads?.some(thread => thread.ruleInstanceId === prop.ruleInstanceId)) {
                    refreshThreadResponse();
                }
            }, CHAT_REFRESH_INTERVAL);
        }

        startTimer();
        return () => clearInterval(myTimer.current); // cleanup

    }, [prop.ruleInstanceId]);

    const scrollToBottom = useCallback((withAnimation: boolean) => {
        try {
            if (withAnimation) {
                messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
            } else {
                messagesEndRef.current?.scrollIntoView();
            }
        } catch (error) {
            console.log(error);
        }
    }, [messagesEndRef]);

    useEffect(() => {
        // Scroll to bottom to show the new message
        scrollToBottom(false);
    }, [chatMessages]);

    // const handleEmojiClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };

    // const handleEmojiClose = () => {
    //     setAnchorEl(null);
    // };

    const refreshThreadResponse = () => {
        setIsLoading(true);
        if (prop.responseMode) {
            if(prop.currentTeam && prop.currentTeam.TeamId !== MyTeamId) {
                dispatch(getSubordinateResponsesForTeam(prop.ruleInstanceId, prop.subordinateId ?? "", prop.currentTeam.TeamId, (messages) => {
                    setChatMessages(messages ?? []);
                    updateTeamProgress(messages);
                    setIsLoading(false);
                }))
            }
            else {
                dispatch(getSubordinateResponses(prop.ruleInstanceId, prop.subordinateId ?? "", (messages) => {
                    setChatMessages(messages ?? []);
                    updateTeamProgress(messages);
                    setIsLoading(false);
                }));
            }
        }
        else {
            dispatch(getResponsesInThread(prop.ruleInstanceId, (messages) => {
                setChatMessages(messages ?? []);
                setIsLoading(false);
            }));
        }
    };

    const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && event.ctrlKey) {
            event.preventDefault();
            onClickSendMessage();
        }
    };

    const onClickSendMessage = () => {
        setIsRequesting(true);
        if (newMessage && senderInfo) {
            if (prop.responseMode) {
                if(prop.currentTeam && prop.currentTeam.TeamId !== MyTeamId) {
                    const request: VirtualTeamChatResponse = {
                        RuleInstanceId: prop.ruleInstanceId,
                        SenderDisplayName: senderInfo.Name || senderInfo.Email,
                        Message: newMessage,
                        UserId: prop.subordinateId,
                        TeamId: prop.currentTeam.TeamId,
                    }
                    dispatch(sendFeedbackResponseForVirtualTeam(request, () => {
                        setNewMessage('');
                        refreshThreadResponse()
                        setIsRequesting(false);
                    }));
                }
                else {
                    const request: ChatResponse = {
                        RuleInstanceId: prop.ruleInstanceId,
                        SenderDisplayName: senderInfo.Name || senderInfo.Email,
                        Message: newMessage,
                        UserId: prop.subordinateId
                    };
                    dispatch(sendTeamFeedbackResponse(request, () => {
                        setNewMessage('');
                        refreshThreadResponse();
                        setIsRequesting(false);
                    }));
                }
            }
            else {
                // Send message
                if (isThreadExist) {
                        const request: ChatResponse = {
                            RuleInstanceId: prop.ruleInstanceId,
                            SenderDisplayName: senderInfo.Name || senderInfo.Email,
                            Message: newMessage
                        };
                        dispatch(sendFeedbackResponse(request, () => {
                            setNewMessage('');
                            refreshThreadResponse();
                            setIsRequesting(false);
                        }));
                } else {
                    setIsThreadExist(true);
                    const request: PostFeedbackRequest = {
                        RuleInstanceId: prop.ruleInstanceId,
                        Message: newMessage
                    };
                    dispatch(startFeedbackThread(request, () => {
                        dispatch(getMyChatThreads(() => {
                            setNewMessage('');
                            refreshThreadResponse();
                            setIsRequesting(false);
                        }));
                    }));
                }
            }
        }
        else {
            console.log("[Warning] Unable to send the message. The message content or sender's name is empty.");
            setIsRequesting(false);
        }
    }

    const escalateChat = () => {
        if(senderInfo) {
            // Create loading spinner
            setIsRequesting(true);
            if(prop.currentTeam && prop.currentTeam.TeamId !== MyTeamId) {
                const request: EscalateFeedbackInVirtualTeamRequest = {
                    RuleInstanceId: prop.ruleInstanceId,
                    SenderDisplayName: senderInfo.Name || senderInfo.Email,
                    UserId: prop.subordinateId,
                    TeamId: prop.currentTeam.TeamId,
                };
                // Call API for virtual team
                dispatch(escalateFeedbackInVirtualTeam(request, (success) => {
                    setIsRequesting(false);
                    if(success) {
                        setEscalateDialogOpen(false);
                        refreshThreadResponse();
                    }
                }));
            }
            else {
                const request: EscalateFeedbackRequest = {
                    RuleInstanceId: prop.ruleInstanceId,
                    SenderDisplayName: senderInfo.Name || senderInfo.Email,
                    UserId: prop.subordinateId,
                };
                // Call API for default org team
                dispatch(escalateFeedback(request, (success) => {
                    setIsRequesting(false);
                    if(success) {
                        setEscalateDialogOpen(false);
                        refreshThreadResponse();
                    }
                }));
            }
        }
    }

    return (
        <Box>
            <Box>
                <AppScrollbar
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: "60vh",
                        px: 1.5
                    }} >
                    {isLoading ? (
                        <Box sx={{ height: "40vh", width: "100%", alignItems: "center", justifyContent: "center", display: "flex" }}>
                            <CircularProgress size="3rem" sx={{ color: theme.palette.primary.main }} />
                        </Box>
                    ) : (
                        <MessageList messages={chatMessages} senderId={ruleUnitState.userShieldId} displayDateTime={prop.displayDateTime}></MessageList>
                    )}
                    <Box sx={{ height: "2px" }} ref={messagesEndRef}></Box>
                </AppScrollbar>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    mt: 2,
                    py: 0.25,
                    alignItems: "center"
                }}>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                }}>
                    {prop.enableEscalation &&  
                        <Tooltip title={hasBeenEscalated ? "Chat has already been escalated" : "Escalate chat"}>
                            <IconButton 
                            sx={{
                                height: 35,
                                width: 35,
                                backgroundColor: hasBeenEscalated || isLoading ? theme.palette.grey[400] : theme.palette.primary.main,
                                mr: 2,
                                color: "white",
                                '&:hover, &:focus': {
                                    backgroundColor: hasBeenEscalated || isLoading ? theme.palette.grey[400] : darken(theme.palette.primary.main, 0.1),
                                },
                                pointerEvents: isLoading ? "none" : ""
                            }}
                            onClick={hasBeenEscalated ? undefined : () => setEscalateDialogOpen(true)}
                                disabled={isRequesting}
                                color='primary'
                                >
                                <EscalateIcon color='#FFFFFF' />
                            </IconButton>
                        </Tooltip> 
                    }
                    {/* <IconButton sx={{ p: 0, mr: 1 }} onClick={handleEmojiClick}>
                        <SentimentSatisfiedAltIcon />
                    </IconButton>
                    <Popover
                        id={emojiId}
                        open={openEmoji}
                        anchorEl={anchorEl}
                        onClose={handleEmojiClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <EmojiPicker
                            onEmojiClick={(emoji) => {
                                setNewMessage((newMessage ?? "") + emoji.emoji);
                                handleEmojiClose();
                            }} />
                    </Popover> */}
                </Box>
                {displayScrollDownFab && (
                    <Box sx={{ position: "absolute", width: "100%", display: "flex", justifyContent: "center", bottom: 56 }}>
                        <Fab
                            onClick={() => scrollToBottom(true)}
                            size='small'
                            sx={{
                                boxShadow: "none",
                                backgroundColor: alpha(theme.palette.primary.main, 0.5),
                                color: "white",
                                "&:hover": {
                                    backgroundColor: alpha(theme.palette.primary.main, 0.7),
                                }
                            }}>
                            <SouthOutlinedIcon />
                        </Fab>
                    </Box>
                )}
                <TextField
                    sx={{
                        width: '100%',
                        position: 'relative',
                        transition: 'all 0.5s ease',
                        '& .MuiOutlinedInput-root': {
                            padding: '12px 14px',
                        },
                    }}
                    multiline
                    maxRows={4}
                    variant="outlined"
                    placeholder="Send Message..."
                    value={newMessage}
                    onChange={(event) => {
                        if (event.target.value !== '\n') setNewMessage(event.target.value);
                    }}
                    onKeyPress={onKeyPress}
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Tooltip title="Ctrl (Windows) or control (Mac) + Enter to send, Enter for newline.">
                                <IconButton
                                    disabled={isRequesting || isLoading}
                                    sx={{
                                        height: 35,
                                        width: 35,
                                        backgroundColor: theme.palette.primary.main,
                                        color: "white",
                                        '&:hover, &:focus': {
                                            backgroundColor: darken(theme.palette.primary.main, 0.1),
                                        },
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 20,
                                            marginLeft: 0.5,
                                        },
                                    }}
                                    onClick={onClickSendMessage} size="large">
                                    <SendOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        )
                    }}
                />
            </Box>
            <AppConfirmDialog
                dialogTitle="Escalate this chat?" 
                title="This will send the chat history to the KiwiRail Rules and Standards team. Your email address will be included so that they can provide a response."
                onConfirm={escalateChat}  
                open={escalateDialogOpen} onDeny={() => setEscalateDialogOpen(false)}
                loading={isRequesting}
            />
        </Box>
    );
}
