import { Box, Dialog, DialogContent, DialogTitle, Switch, Typography } from "@mui/material";
import { Fonts } from "../../constants/AppEnums";
import { fontColor } from "../../constants/ColorSets";

interface Props {
  muteAudioChecked: boolean;
  handleAudioSwitch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  settingsDialogOpen: boolean;
  setSettingsDialogOpen: (settingsDialogOpen: boolean) => void;
}

export default function UserSettingsDialog({muteAudioChecked, handleAudioSwitch, setSettingsDialogOpen, settingsDialogOpen}: Props) {
  return <Dialog fullWidth onClose={() => setSettingsDialogOpen(false)} open={settingsDialogOpen}>
    <DialogTitle sx={{borderBottom: `1px solid ${fontColor.grayBackground}`}}>        
      <Typography
          sx={{
            fontWeight: Fonts.SEMI_BOLD,
          }}
          id="alert-dialog-title"
        >
          Settings
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{ mt: 2, color: "text.secondary", fontSize: 14, whiteSpace: "pre-wrap" }}
        id="alert-dialog-description"
      >
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Typography>Mute Audio Playback</Typography>
          <Switch checked={muteAudioChecked} onChange={handleAudioSwitch} />
        </Box>
      </DialogContent>
  </Dialog>
}