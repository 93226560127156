/**
 * @name ModuleView
 * @description Displays the content of a rule unit.
 */

import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SourceIcon from '@mui/icons-material/Source';
import { AppState } from "../../../redux/store";
import { ContentRevision } from "../../../types/models/ContentRevision";
import { RuleUnitState } from "../../../types/models/RuleUnitState";
import { theme } from "../../../themes/theme";
import ContentRevisionView from "../ContentRevisionView";
import { useRules } from "../../../hooks/useRules";

interface ModuleViewProps {
    moduleName: string,
    contentRevisions: ContentRevision[] | undefined,
    forDocumentDisplay: boolean;
    subtitle?: string;
}

const ModuleView: React.FC<ModuleViewProps> = (prop) => {
    const { targetItem } = useRules();
    const [expanded, setExpanded] = useState<boolean>(false);
    const ruleUnitState: RuleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);

    // If we're linking to an item we want to expand the module if it contains the item
    useEffect(() => {
        if (targetItem && targetItem.id) {
            const isRuleInModule = prop.contentRevisions?.some(item => {
                return item.ContentResourceId === targetItem.id;
            });

            let isCurrentModule = false;

            if (ruleUnitState.contentRevisions?.entries) {
                for (const value of Object.values(ruleUnitState.contentRevisions)) {
                    if (value.some((item: ContentRevision) => item.ContentRevisionId === targetItem.id)) {
                        isCurrentModule = true;
                        break;
                    }
                }
            }
            setExpanded(
                isRuleInModule
                || isCurrentModule
            );
        }
    }, [targetItem, ruleUnitState.contentRevisionDetails, ruleUnitState.contentRevisions, prop.contentRevisions]);

    const isRevisionExpanded = useCallback((contentResourceId: string) => {
        if (!targetItem || !targetItem.id) return
        return contentResourceId === targetItem.id;
    }, [targetItem, ruleUnitState.contentRevisionDetails, ruleUnitState.contentRevisions, prop.contentRevisions]);

    return (
      <Accordion
        disableGutters={true}
        sx={{
          position: "relative",
          boxShadow: "none",
          borderBottom: (theme) => `solid 1px ${theme.palette.grey[400]}`,
          mb: "-1px",
          "&:last-of-type": {
            borderRadius: 0,
          },
        }}
        key={prop.moduleName}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          sx={{
            paddingX: 1,
            m: 0,
            py: 0,
            "& .MuiSvgIcon-root": {
              color: (theme) => theme.palette.grey[500],
            },
            "&.Mui-expanded": {
              borderTop: (theme) => `solid 1px ${theme.palette.grey[400]}`,
              borderBottom: (theme) => `solid 1px ${theme.palette.grey[400]}`,
            },
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <SourceIcon sx={{ marginRight: 1 }}></SourceIcon>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ color: theme.palette.text.primary }}>
                  {prop.moduleName}
                </Typography>
                <Typography
                  sx={{ color: theme.palette.text.primary, fontSize: 14 }}
                >
                  {prop.subtitle}
                </Typography>
              </Box>
            </Box>
            {(ruleUnitState.searchText?.replace(/"/g, '').length ?? 0) > 1 && (
              <Typography
                sx={{ color: theme.palette.primary.main, fontSize: 15 }}
              >
                {prop.contentRevisions?.flatMap(
                  (content) => content.RuleInstances
                ).length ?? 0}{" "}
                items
              </Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          {prop.contentRevisions && prop.contentRevisions.length > 0
            ? prop.contentRevisions.map((data) => (
                <ContentRevisionView
                  isExpanded={isRevisionExpanded(data.ContentResourceId)}
                  key={data.ContentRevisionId}
                  contentRevision={data}
                  forDocumentDisplay={prop.forDocumentDisplay}
                />
              ))
            : null}
        </AccordionDetails>
      </Accordion>
    );
}

ModuleView.displayName = "ModuleView";

export default ModuleView;