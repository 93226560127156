import React, { ReactElement } from 'react';
import { Box, List, ListItem, ListSubheader } from '@mui/material';
import { ChatMessage } from '../../types/models/ChatMessage';
import MessageItem from './MessageItem';
import { formatDate, formatDateTime, formatTime } from '../../utils/StringUtils';
import { theme } from '../../themes/theme';

interface MessageListProp {
    messages: ChatMessage[],
    senderId: string,
    displayDateTime?: boolean,
}

const MessageList: React.FC<MessageListProp> = (prop) => {

    const createMessageItems = () => {
        const items: ReactElement[] = [];
        let previousHour = "";
        let previousTime = "";

        if (!prop.messages || prop.messages.length === 0) return items;
        const feedBackMessage = prop.messages[0];
        items.push(
            <ListItem key="feedback_time" sx={{ width: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <Box sx={{ p: 1, fontSize: 14 }}>
                        {formatDate(feedBackMessage.SentAt)}
                    </Box>
                </Box>
            </ListItem>
        );

        items.push(
            <ListItem key="feedback_message" sx={{ width: "100%" }}>
                <Box sx={{ fontSize: 14, width: "100%", color: theme.palette.text.secondary }}>
                    Feedback
                    <Box sx={{
                        borderRadius: 2,
                        backgroundColor: theme.page.bgColor,
                        border: "1px solid silver",
                        p: 1,
                        color: theme.palette.text.primary
                    }}>
                        {feedBackMessage.Message}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        {formatTime(feedBackMessage.SentAt)}
                    </Box>
                </Box>
            </ListItem>
        );

        const responses = prop.messages.slice(1);
        responses.forEach(((value, index) => {
            const date = new Date(value.SentAt);
            const hourStr = date.getDate().toString() + date.getHours().toString();
            const timeStr = hourStr + date.getMinutes().toString();
            if (previousHour != hourStr) {
                items.push(
                    <ListSubheader key={index + value.SentAt} disableSticky={true}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            {formatDateTime(value.SentAt)}
                        </Box>
                    </ListSubheader>);
            }
            items.push(
                <ListItem key={index + value.MessageId} sx={{ width: "100%" }}>
                    <MessageItem
                        message={value}
                        senderId={prop.senderId}
                        isLast={index === prop.messages.length - 1}
                        hideSenderAndTime={previousHour != hourStr || previousTime === timeStr}
                        displayDateTime={prop.displayDateTime}
                    />
                </ListItem>
            );
            previousHour = hourStr;
            previousTime = timeStr;
        }));
        return items;
    };

    return (
        <List>
            {createMessageItems()}
        </List>
    );
}

export default MessageList;
