import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store";
import { getContentById, getContentList, getMyAcknowledgement, setSelectedUserRoles, clearAllState, getFavouriteRules, postAcknowledgeStatus, setSelectedUserLocations } from "../redux/actions";
import { getMyChatThreads } from "../redux/actions/Chat";
import { RuleUnitState } from "../types/models/RuleUnitState";
import { RoleState } from "../types/models/RoleState";
import { PostAcknowledgedRequest } from "../types/models/PostAcknowledgedRequest";

export function useAlerts() {
  const dispatch = useDispatch();

  const [invalidAccess, seInvalidAccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
  const roleState = useSelector<AppState, RoleState>((state) => state.role);

  useEffect(() => {
    if (ruleUnitState.baseAlertContentRevisions) {
      // Already called this API
      return;
    }
    getContents();
  }, []);

  useEffect(() => {
    if (ruleUnitState.baseAlertContentRevisions &&
      (!ruleUnitState.baseAlertContentRevisionDetails || ruleUnitState.baseAlertContentRevisionDetails.length === 0)) {
      // Call detail API if it's not called yet
      ruleUnitState.baseAlertContentRevisions.forEach(alertContent => {
        dispatch(getContentById(alertContent.ContentRevisionId, (content) => {
          //only call the postAcknowledgeStatus API if the content comes from the API
          if (content && !content.fromCache) {
            content.RuleInstances?.forEach((ruleInstance) => {
              const request: PostAcknowledgedRequest = {
                AcknowledgementType: "delivered",
                DeviceId: null,
                RuleInstanceId: ruleInstance.RuleInstanceId,
                UsersTime: new Date(),
              }
              dispatch(postAcknowledgeStatus(request, undefined));
            })
          }
        }));
      });
    }
  }, [ruleUnitState.baseAlertContentRevisions])

  const getContents = useCallback(() => {
    setIsLoading(true);
    // Get list of content revisions
    dispatch(getMyAcknowledgement((result) => {
      if (result) {
        dispatch(getMyChatThreads(() => {
          dispatch(getFavouriteRules(() => {
            setIsLoading(false);
          }));
        }));
      }
      else {
        seInvalidAccess(true);
        setIsLoading(false);
      }
    }));
  }, [dispatch]);

  const refreshAlerts = useCallback(() => {
    dispatch(clearAllState());
    // Set selected roles from Role redux
    dispatch(setSelectedUserRoles(roleState.myRoles?.map(role => role.RoleId) ?? []));
    dispatch(setSelectedUserLocations(roleState.myLocations?.map((location) => location.LNIId) ?? []))
    dispatch(getContentList(() => {
      getContents();
    }));
  }, [dispatch, roleState.myRoles, getContents]);

  return {
    invalidAccess,
    isLoading,
    refreshAlerts
  };
}