import ContentLoader from "react-content-loader"

const RuleInstanceDetailSkeleton = () => {
    return (
        <ContentLoader
            speed={2}
            width={700}
            height={320}
            viewBox="0 0 700 320"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="48" y="32" rx="3" ry="3" width="288" height="20" />
            <rect x="0" y="78" rx="3" ry="3" width="410" height="18" />
            <rect x="0" y="114" rx="3" ry="3" width="178" height="16" />
            <rect x="0" y="160" rx="3" ry="3" width="800" height="16" />
            <rect x="0" y="190" rx="3" ry="3" width="800" height="16" />
            <rect x="0" y="220" rx="3" ry="3" width="500" height="16" />
            <rect x="0" y="250" rx="3" ry="3" width="500" height="16" />
            <rect x="0" y="280" rx="3" ry="3" width="620" height="16" />
            <circle cx="20" cy="40" r="20" />
        </ContentLoader>
    )
}

export default RuleInstanceDetailSkeleton;