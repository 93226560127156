import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { AppState } from "../../redux/store";
import { getOrgTeams, getPotentialTeamMembers } from "../../redux/actions";
import { TeamMembersState } from "../../types/models/TeamMembersState";
import { VirtualTeam } from "../../types/models/Team";
import { theme } from "../../themes/theme";
import { useAlerts } from "../../hooks/useAlerts";
import AppInfoView from "../../components/AppBase/AppInfoView";
import TeamTable from "./TeamTable";
import EditTeam from "./EditTeam";
import TeamPageHeader from "./TeamHeader";
import AppLoader from "../../components/AppBase/AppLoader";

export default function AllTeamsPage() {
    const dispatch = useDispatch();
    const teamState = useSelector<AppState, TeamMembersState>((state) => state.teamMembers);

    const [loading, setLoading] = useState<boolean>(false);
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [editTeam, setEditTeam] = useState<VirtualTeam>();

    // Calling the alert related APIs to get the number of alerts in sidebar 
    useAlerts();

    const getMembersAndTeams = useCallback(() => {
        setLoading(true);
        //getSubordinates
        dispatch(getPotentialTeamMembers(() => {
            dispatch(getOrgTeams(() => {
                setLoading(false);
            }));
        }))
    }, [dispatch]);

    useEffect(() => {
        getMembersAndTeams();
    }, []);

    const handleCloseTeamModal = () => {
        setEditTeam(undefined);
        setOpenEditModal(false);
    };

    return (
        <>
            {loading && <Box sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <AppLoader />
            </Box>}
            <Box sx={{ backgroundColor: theme.page.bgColor, minHeight: "100vh" }}>
                <TeamPageHeader
                    title="Manage Teams"
                    action={
                        <>
                        <Button
                            disabled={loading || !teamState.users}
                            startIcon={<AddCircleOutlineOutlinedIcon />}
                            variant="text"
                            onClick={() => {
                                setEditTeam(undefined);
                                setOpenEditModal(true);
                            }}
                            sx={{ width: "130px", textTransform: "none", borderRadius: 1 }}>
                            New Team
                        </Button>
                        </>
                    }
                />
                <Box sx={{ m: 4 }}>
                    <TeamTable
                        teams={teamState.orgTeams}
                        onOpenEditDialog={(team) => {
                            setEditTeam(team);
                            setOpenEditModal(true);
                        }}
                    />
                </Box>
            </Box>
            <EditTeam
                team={editTeam}
                isOpen={openEditModal}
                onClose={() => handleCloseTeamModal()}
                allUsers={teamState.users ?? []}
                isYourTeamsPage={false}
            ></EditTeam>
            <AppInfoView />
        </>
    );
}
