import { RuleFile } from "../models/RuleFile";

export const GET_FILE_LIST = "GET_FILE_LIST";
export const SEARCH_FILE = "SEARCH_FILE";

export interface GetFileList {
  type: typeof GET_FILE_LIST;
  payload: Array<RuleFile> | undefined;
}

export interface SearchFile {
  type: typeof SEARCH_FILE;
  payload: string | undefined;
}

export type RuleFileActions =
  | GetFileList
  | SearchFile
