import React, { ReactNode } from "react";
import ListItem from "@mui/material/ListItem";
import { alpha } from "@mui/material";
import { sidebarColor } from "../../constants/ColorSets";
import { Fonts } from "../../constants/AppEnums";
import { theme } from "../../themes/theme";

interface VerticalNavItemProps {
  children: ReactNode;
  level: number;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

const VerticalNavItem: React.FC<VerticalNavItemProps> = ({
  children,
  level,
  ...rest
}) => {

  return (
    <ListItem
      sx={{
        height: 40,
        my: 0.25,
        cursor: "pointer",
        textDecoration: "none !important",
        width: "calc(100% - 32px)",
        pl: 22 + 33 * level + "px",
        pr: 3,
        borderRadius: 1,
        position: "relative",
        transition: "all 0.4s ease",
        whiteSpace: "nowrap",
        "& .nav-item-icon": {
          color: alpha(sidebarColor.sidebarTextColor, 0.7),
          fontSize: 20,
          display: "block",
        },
        "& .nav-item-text": {
          color: alpha(sidebarColor.sidebarTextColor, 0.7),
          fontWeight: Fonts.BOLD,
          fontSize: 16,
        },
        "& .MuiTouchRipple-root": {
          zIndex: 1,
        },
        "&.nav-item-header": {
          textTransform: "uppercase",
        },
        "&:hover, &:focus": {
          "& .nav-item-text, & .nav-item-icon, & .nav-item-icon-arrow": {
            color: sidebarColor.sidebarTextColor,
          },
        },
        "&.active": {
          backgroundColor: theme.palette.primary.main,
          pointerEvents: "none",
          "& .nav-item-text": {
            color: "white",
            fontWeight: Fonts.BOLD,
          },
          "& .nav-item-icon": {
            color: "white",
          },
        },
        "&.standard-menu": {
          mx: 2,
          pl: 10 + 33 * level + "px",
          pr: 0,
          borderRadius: 4,
        }
      }}
      {...rest}
    >
      {children}
    </ListItem>
  );
};

export default VerticalNavItem;
