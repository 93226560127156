import { ChatThread } from "../models/ChatThread";
import { ChatResponse } from "../models/ChatResponse";
import { ChatMessageResponse } from "../models/ChatMessageResponse";

export const MY_CHAT_THREADS = "MY_CHAT_THREADS";
export const GET_RESPONSES_IN_THREAD = "GET_RESPONSES_IN_THREAD";
export const SEND_FEEDBACK_RESPONSE = "SEND_FEEDBACK_RESPONSE";
export const GET_TEAM_QUESTIONS = "GET_TEAM_QUESTIONS";
export const GET_SUBORDINATE_RESPONSES = "GET_SUBORDINATE_RESPONSES";

export interface MyChatThreads {
  type: typeof MY_CHAT_THREADS;
  payload: Array<ChatThread> | undefined;
}

export interface GetResponsesInThread {
  type: typeof GET_RESPONSES_IN_THREAD;
  payload: ChatMessageResponse | undefined;
}

export interface SendFeedbackResponse {
  type: typeof SEND_FEEDBACK_RESPONSE;
  payload: ChatResponse;
}

export interface GetSubordinateResponses {
  type: typeof GET_SUBORDINATE_RESPONSES;
  payload: ChatMessageResponse | undefined;
}

export type ChatActions =
  | MyChatThreads
  | GetResponsesInThread
  | SendFeedbackResponse
  | GetSubordinateResponses;
