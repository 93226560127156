import axios from "axios";

const contentAxios = axios.create({
  baseURL: process.env.REACT_APP_CONTENT_BASE_URL,
  withCredentials: false,
});

contentAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    console.error("Got an error from contents server:")
    console.error(err.response);
    return Promise.reject(err);
  }
);

export default contentAxios;
