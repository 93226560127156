import React, { useEffect, useState } from "react";
import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { PieChart } from '@mui/x-charts/PieChart';
import { theme } from "../../themes/theme";

interface ProgressPieChartProp {
    total: number,
    completed: number
}

const ProgressPieChart: React.FC<ProgressPieChartProp> = (prop) => {

    const [markedCount, setMarkedCount] = useState<number>(prop.completed);
    const [unMarkedCount, setUnMarkedCount] = useState<number>(prop.total - prop.completed);
    useEffect(() => {
        if (prop.total === 0 && prop.completed === 0) {
            // if no alerts assigned, display fully circle and tick 
            setMarkedCount(100);
            setUnMarkedCount(0);
        }
    }, []);

    return (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <PieChart
                tooltip={{ trigger: "none" }}
                series={[
                    {
                        data: [
                            { id: 0, value: markedCount, color: theme.table.pieChartCompletedColor },
                            { id: 1, value: unMarkedCount, color: theme.table.pieChartUncompletedColor },
                        ],
                        innerRadius: 14,
                        outerRadius: 20,
                        paddingAngle: 0,
                        cornerRadius: 3,
                        cx: 20,
                    },
                ]}
                height={50}
                width={50}
            />
            {prop.completed === prop.total && (
                <Box sx={{ position: "absolute", mt: 1.6 }}>
                    <CheckIcon
                        sx={{
                            mt: 0,
                            height: "26px",
                            width: "26px",
                            strokeWidth: 2,
                            stroke: theme.table.pieChartCompletedColor,
                            color: theme.table.pieChartCompletedColor
                        }} />
                </Box>
            )}
        </Box>
    );

};

export default ProgressPieChart;
