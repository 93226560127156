import { KnownLocation } from "../models/KnownLocation";
import { KnownRole } from "../models/KnownRole";
import { UserRole } from "../models/MyRole";
import { PostUpdateSubscriptionsRequest } from "../models/PostUpdateSubscriptionsRequest";

export const GET_MY_ROLES = "GET_MY_ROLES";
export const GET_KNOWN_ROLES = "GET_KNOWN_ROLES";
export const UPDATE_ROLE_SUBSCRIPTIONS = "UPDATE_ROLE_SUBSCRIPTIONS";

export interface GetMyRoles {
  type: typeof GET_MY_ROLES;
  payload: UserRole | undefined;
}

export interface GetKnownRoles {
  type: typeof GET_KNOWN_ROLES;
  payload: {roles: Array<KnownRole>, locations: Array<KnownLocation>} | undefined;
}

export interface UpdateRoleSubscriptions {
  type: typeof UPDATE_ROLE_SUBSCRIPTIONS;
  payload: PostUpdateSubscriptionsRequest | undefined;
}

export type RoleActions =
  | GetMyRoles
  | GetKnownRoles
  | UpdateRoleSubscriptions;
