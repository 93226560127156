import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Button, CircularProgress, FormControl, FormHelperText, IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { VirtualTeam } from "../../../types/models/Team";
import { Fonts } from "../../../constants/AppEnums";
import AppDialog from "../../../components/AppBase/AppDialog";
import { MakeTeamRequest } from "../../../types/models/MakeTeamRequest";
import { UpdateTeamRequest } from "../../../types/models/UpdateTeamRequest";
import { useDispatch } from "react-redux";
import { RISUser } from "../../../types/models/RISUser";
import { fetchError, getMyTeams, getOrgTeams, makeTeam, showMessage, updateTeam } from "../../../redux/actions";
import { theme } from "../../../themes/theme";
import AppConfirmDialog from "../../../components/AppBase/AppConfirmDialog";
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { fontColor } from "../../../constants/ColorSets";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { UploadModal } from "../UploadModal";
import { CSVLink } from "react-csv";
import { TeamCSVData } from "../../../types/models/TeamCSVData";
import Papa from "papaparse";
import { MemberAutocomplete } from "./MemberAutocomplete";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { TeamMemberProgress } from "../../../types/models/TeamMember";

interface EditTeamProp {
    isOpen: boolean,
    onClose: () => void,
    team?: VirtualTeam,
    allUsers: RISUser[],
    setTargetTeamProgress?: (targetTeamProgress: TeamMemberProgress[] | undefined) => void;
    isYourTeamsPage: boolean;
}

const EditTeam: React.FC<EditTeamProp> = (prop) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [formDisplayName, setFormDisplayName] = useState<string | undefined>();
    const [formLeaderIds, setFormLeaderIds] = useState<string[] | undefined>();
    const [formMemberIds, setFormMemberIds] = useState<string[] | undefined>();

    const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState<boolean>(false);
    const [deletedList, setDeletedList] = useState<string[]>([]);
    const [formDisplayNameError, setFormDisplayNameError] = useState<string>();
    const [formLeaderIdsError, setFormLeaderIdsError] = useState<string>();
    const [formMemberIdsError, setFormMemberIdsError] = useState<string>();

    const [teamName, setTeamName] = useState<string | undefined>(formDisplayName);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [openMessageAlert, setOpenMessageAlert] = useState<boolean>(false);
    const [uploadMessage, setUploadMessage] = useState<string>("");
    const [nonOrgUsers, setNonOrgUsers] = useState<string[]>([]);

    const usersMap = new Map(prop.allUsers.map(user => [user.UserId, user]));

    const teamCsvHeaders = [{label: "userEmail", key: "email"}, {label: "isLeader", key: "isLeader"}];

    const UNKNOWN_USER_NAME = "Unknown";

    useEffect(() => {
      if(prop.isOpen)
      {
        if (prop.team) {
            setFormDisplayName(prop.team.DisplayName);
            setFormLeaderIds(prop.team.Members.filter(member => member.TeamLeader === true).map(member => member.UserId));
            setFormMemberIds(prop.team.Members.filter(member => member.TeamLeader === false).map(member => member.UserId));
        } else {
            clearErrors();
            clearForm();
        }
      }
    }, [prop.isOpen]);

    const copyNonOrgUsers = () => {
        const nonOrgUsersString = nonOrgUsers.join("\n");

        navigator.clipboard.writeText(nonOrgUsersString)
        .then(() => {
            dispatch(showMessage("Chat escalated successfully!"));
        })
        .catch(() => {
            dispatch(fetchError("Failed to copy to clipboard. Please try again."));
        })
    }

    const removeDuplicateIds = (array: string[]) => {
        const uniqueIds = new Set(array);

        return Array.from(uniqueIds);
    }

    const checkDuplicateIds = (arr1: string[], arr2: string[]) => {
        return arr1.some(value => arr2.includes(value));
    }

    const handleSubmit = () => {

        if (!validateForm()) return;

        const duplicatesExist = checkDuplicateIds(formLeaderIds ?? [], formMemberIds ?? []);

        if(duplicatesExist) {
            dispatch(fetchError("Warning: Users cannot be both a leader and member. Please update the team and try again."))
            return;
        }

        if(!prop.isYourTeamsPage) {
          setIsSubmitting(true);
        }

        setDeletedList([]);
        setOpenDeleteConfirmDialog(false);

        // Split outgoing request depending on if we're updating a team or creating a new one.
        if (prop.team) {
            // Update
            const updateTeamRequest: UpdateTeamRequest = {
                TeamId: prop.team.TeamId,
                DisplayName: formDisplayName ?? "",
                Members: formMemberIds ?? [],
                Leaders: formLeaderIds ?? []
            };
            dispatch(updateTeam(updateTeamRequest, (result) => {
                if (result) {
                  // Refresh team list
                  if(prop.isYourTeamsPage) {
                    // Set team id in param to keep the team selection in YourTeams Header
                    navigate("/your-teams/" + prop.team?.TeamId ?? "");
                    // Refresh team data, and this will trigger to get team progress
                    dispatch(getMyTeams(() => {
                      setIsSubmitting(false);
                      dispatch(showMessage("The team has been updated."));
                      prop.onClose();
                    }));
                  }
                  else {
                    dispatch(getOrgTeams(() => {
                      setIsSubmitting(false);
                      dispatch(showMessage("The team has been updated."));
                      prop.onClose();
                  }));
                  }
                }
                else {
                    setIsSubmitting(false);
                }
            }));
        } else {
            // New
            const createTeamRequest: MakeTeamRequest = {
                DisplayName: formDisplayName ?? ""
            };
            dispatch(makeTeam(createTeamRequest, (teamId) => {
                if (teamId) {
                    const updateTeamRequest: UpdateTeamRequest = {
                        TeamId: teamId,
                        DisplayName: formDisplayName ?? "",
                        Members: formMemberIds ?? [],
                        Leaders: formLeaderIds ?? []
                    };
                    dispatch(updateTeam(updateTeamRequest, (result) => {
                        if (result) {
                            // Update my teams list to handle in Your Teams page
                            dispatch(getMyTeams(() => {
                                // Refresh team list
                                dispatch(getOrgTeams(() => {
                                    setIsSubmitting(false);
                                    dispatch(showMessage("The team has been created."));
                                    closeDialog();
                                }));
                            }));
                        }
                        else {
                            setIsSubmitting(false);
                        }
                    }));
                } else {
                    setIsSubmitting(false);
                }
            }));
        }
    };

    const clearErrors = () => {
        setFormDisplayNameError(undefined);
        setFormLeaderIdsError(undefined);
        setFormMemberIdsError(undefined);
    };

    const clearForm = () => {
        setFormDisplayName(undefined);
        setFormLeaderIds(undefined);
        setFormMemberIds(undefined);
    };

    const closeDialog = () => {
        setNonOrgUsers([]);
        setDeletedList([]);
        clearForm();
        clearErrors();
        prop.onClose();
    };

    const validateDisplayName = (value: string | undefined) => {
        if (value && value.length > 0 && value.length <= 256) {
            setFormDisplayNameError(undefined);
            return true;
        }
        else {
            setFormDisplayNameError("Please enter display name");
            return false;
        }
    };

    const validateLeaders = (value: string[] | undefined) => {
        if (value && value.length > 0) {
            setFormLeaderIdsError(undefined);
            return true;
        }
        else {
            setFormLeaderIdsError("Please select one or more leaders");
            return false;
        }
    };

    const validateMembers = (value: string[] | undefined) => {
        if (value && value.length > 0) {
            setFormMemberIdsError(undefined);
            return true;
        }
        else {
            setFormMemberIdsError("Please select one or more members");
            return false;
        }
    };

    const validateForm = () => {
        const result1 = validateDisplayName(formDisplayName);
        // const result2 = validateStartDate(formStartDate);
        // const result3 = validateEndDate(formEndDate);
        const result4 = validateLeaders(formLeaderIds);
        const result5 = validateMembers(formMemberIds);

        return result1 && result4 && result5;
    };

    const handleChange = (field: string, value: string | string[] | undefined) => {
        switch (field) {
            case "DisplayName":
                setFormDisplayName(value as string);
                validateDisplayName(value as string);
                break;
            case "LeaderIds":
                setFormLeaderIds(value as string[]);
                validateLeaders(value as string[]);
                break;
            case "MemberIds":
                setFormMemberIds(value as string[]);
                validateMembers(value as string[]);
                break;
        }
    };

    const handleChangeLeader = (event: React.ChangeEvent<unknown>, values: RISUser[] | null) => {
        const userIds = values?.map(user => user.UserId);
        setFormLeaderIds(userIds as string[]);
    };

    const handleChangeMember = (event: React.ChangeEvent<unknown>, values: RISUser[] | null) => {
        const userIds = values?.map(user => user.UserId);
        setFormMemberIds(userIds as string[]);
    };

    const getUserDisplayName = (userId: string) => {
        const user = usersMap.get(userId);
        if (user) {
            return user.Name || user.Email;
        } else {
            return UNKNOWN_USER_NAME;
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;
        const file = e.target.files[0];
        if(file.type === "text/csv") {
          setSelectedFile(file);
          setUploadMessage("");
          setOpenMessageAlert(false);
        }
        else {
          setUploadMessage("Files can only be of type CSV. Please check the file format and try again.");
          setOpenMessageAlert(true);
        }
      }

      const handleDragFile = (e: React.DragEvent) => {
        e.preventDefault();
      }
    
      const handleDropFile = (e: React.DragEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
    
        const file = e.dataTransfer.files[0];
        if(file.name.includes(".csv")) {
          setSelectedFile(file);
          setUploadMessage("");
          setOpenMessageAlert(false);
        }
        else {
          setUploadMessage("Files can only be of type CSV. Please check the file format and try again.");
          setOpenMessageAlert(true);
        }
      }

      const removeTemplateData = (data: TeamCSVData[]) => {
        return data.filter((row) => row.userEmail !== "test-user@kiwirail.com" 
        && row.userEmail !== "test-manager@kiwirail.com")
      }

      const getNonOrgUsers = (data: TeamCSVData[]): string[] => {
        // get the CSV emails without the default ones from the template
        const dataEmails = removeTemplateData(data).map((item) => item.userEmail.toLowerCase()).filter((email) => email !== "");
        // get all users in current org and remove any users with no UserId and return just the emails of the users
        const allUserEmails = prop.allUsers.filter((item) => item.UserId !== null).map((item => item.Email.toLowerCase()));

        const nonOrgUsers = dataEmails.filter((email) => !allUserEmails.includes(email.toLowerCase()));

        return nonOrgUsers;
      }

      const handleUpload = () => {
        if(!selectedFile) {
          setUploadMessage("Please select a CSV file to upload.");
          setOpenMessageAlert(true);
          return;
        }
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          if (!e.target) return;
          const csvData = e.target.result as string;
    
          Papa.parse<TeamCSVData>(csvData, {
            header: true,
            complete: (results) => {
              const data = results.data.map((item) => ({
                ...item,
                userEmail: item.userEmail.toLowerCase(),
              }));
              const nonOrgEmails = getNonOrgUsers(data);
              if(nonOrgEmails.length > 0) {
                setNonOrgUsers(nonOrgEmails);
              }
              else {
                setNonOrgUsers([]);
              }
              setFormDisplayName(teamName);
              setOpenMessageAlert(false);
              setSelectedFile(null);
              setUploadMessage("CSV Data Upload Successfully");
              handleUploadedCsvData(data, teamName ?? '');
            },
            error: (error: Error) => {
              console.error("Error Parsing CSV: ", error);
              setUploadMessage("Error uploading CSV. Please check the file format and try again.")
            }
          })
        };
        reader.readAsText(selectedFile);
      }

    const handleUploadedCsvData = (csvData: TeamCSVData[], teamName: string) => {
        const leaderIds: string[] = [...(formLeaderIds || [])];
        const memberIds: string[] = [...(formMemberIds || [])];

        if(!csvData) {
            if(prop.team) {
                dispatch(showMessage(`Failed to update ${prop.team.DisplayName}. Please try again later.`));
            }else {
                dispatch(showMessage("Failed to create new team. Please try again later."));
            }
            return;
        }

        csvData.forEach((data) => {
            const matchedUser = prop.allUsers.find((user) => data.userEmail.toLowerCase() === user.Email.toLowerCase());
            if(matchedUser?.UserId) {
                if(data.isLeader.toLowerCase() === "true") {
                    leaderIds?.push(matchedUser.UserId);
                }
                else {
                    memberIds?.push(matchedUser.UserId);
                }
            }
        });



        setFormLeaderIds(removeDuplicateIds(leaderIds));
        setFormMemberIds(removeDuplicateIds(memberIds));
        setFormDisplayName(teamName);

        setUploadModalOpen(false);
    }

    return (
      <>
        <AppDialog
          sxStyle={{ height: "105%", top: "-15px" }}
          open={prop.isOpen}
          dividers
          disableBackDropOffClose
          title={
            <Typography sx={{ mb: 1, fontWeight: Fonts.SEMI_BOLD }}>
              {prop.team ? "Edit Team" : "Create Team"}
            </Typography>
          }
          extraMenu={
            <Box>
              <CSVLink
                filename={`${prop.team ? formDisplayName : "newTeam"}.csv`}
                data={[
                  { email: "test-user@kiwirail.com", isLeader: "TRUE" },
                  { email: "test-manager@kiwirail.com", isLeader: "FALSE" },
                ]}
                headers={teamCsvHeaders}
              >
                <Tooltip title="Download Template">
                  <IconButton sx={{ color: fontColor.orangeTitle }}>
                    <FileDownloadOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </CSVLink>
              <Tooltip
                title={
                  prop.team ? "Update team via CSV" : "Create team via CSV"
                }
              >
                <IconButton
                  onClick={() => setUploadModalOpen(true)}
                  sx={{ color: fontColor.orangeTitle }}
                >
                  <UploadFileOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          }
          onClose={closeDialog}
        >
          <Box>
            {isSubmitting && (
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  size="3rem"
                  sx={{ color: theme.palette.primary.main }}
                />
              </Box>
            )}
            <>
              <Box>
                <Box sx={{ px: 2, py: 1 }}>
                  <TextField
                    required
                    name="DisplayName"
                    value={formDisplayName}
                    variant="outlined"
                    fullWidth
                    id="DisplayName"
                    label="Display name"
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) =>
                      handleChange("DisplayName", event.target.value)
                    }
                    error={formDisplayNameError ? true : undefined}
                    helperText={formDisplayNameError}
                  />
                </Box>
                <Box sx={{ p: 2, mt: 1, width: "100%" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <MemberAutocomplete
                      allUsers={prop.allUsers}
                      emptyError={formLeaderIdsError}
                      getUserDisplayName={getUserDisplayName}
                      handleChange={handleChangeLeader}
                      leaderIds={formLeaderIds}
                      memberIds={formMemberIds}
                      name="leaders"
                    />
                    {formLeaderIdsError && (
                      <FormHelperText className="Mui-error">
                        {formLeaderIdsError}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box sx={{ px: 2, py: 1, width: "100%" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <MemberAutocomplete
                      allUsers={prop.allUsers}
                      emptyError={formMemberIdsError}
                      getUserDisplayName={getUserDisplayName}
                      handleChange={handleChangeMember}
                      leaderIds={formLeaderIds}
                      memberIds={formMemberIds}
                      name="members"
                    />
                    {formMemberIdsError && (
                      <FormHelperText className="Mui-error">
                        {formMemberIdsError}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Box>
              {nonOrgUsers && nonOrgUsers.length > 0 ? (
                <Box
                  sx={{
                    m: "auto",
                    width: "94%",
                    border: "1px solid #d22f2e",
                    borderRadius: "5px",
                  }}
                >
                  <Alert variant="filled" severity="error" sx={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}>
                    The below users were not able to be added to this team as
                    they are either not in Shield or not noted as part of your
                    organisation.
                  </Alert>
                  <ul
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      overflowY: "auto",
                      maxHeight: "100px",
                    }}
                  >
                    {nonOrgUsers.map((user) => (
                      <li key={user}>{user}</li>
                    ))}
                  </ul>
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Tooltip title="Copy values">
                      <IconButton onClick={copyNonOrgUsers}>
                        <ContentCopyIcon
                          sx={{ color: theme.palette.primary.main }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              ) : null}
                <Stack direction="row" spacing={2} sx={{ display: "flex", alignItems: "center", justifyContent: "end", my: 2, px: 2}}>
                  <Button
                    sx={{
                      minWidth: 100,
                      color: "white",
                    }}
                    variant="contained"
                    onClick={() =>
                      deletedList.length > 0
                        ? setOpenDeleteConfirmDialog(true)
                        : handleSubmit()
                    }
                  >
                    Save
                  </Button>
                  <Button
                    sx={{
                      minWidth: 100,
                      textTransform: "none",
                    }}
                    color="primary"
                    variant="outlined"
                    onClick={() => closeDialog()}
                  >
                    Cancel
                  </Button>
                </Stack>
            </>
          </Box>
        </AppDialog>
        {prop.team && (
          <AppConfirmDialog
            open={openDeleteConfirmDialog}
            onDeny={() => setOpenDeleteConfirmDialog(false)}
            onConfirm={() => handleSubmit()}
            title="Are you sure you want to delete these users from this team?"
            dialogTitle="Delete Users?"
          ></AppConfirmDialog>
        )}

        <UploadModal
          setUploadModalOpen={setUploadModalOpen}
          uploadModalOpen={uploadModalOpen}
          formDisplayName={formDisplayName}
          handleUploadCsvData={handleUploadedCsvData}
          handleDragFile={handleDragFile}
          handleDropFile={handleDropFile}
          handleFileChange={handleFileChange}
          handleUpload={handleUpload}
          openMessageAlert={openMessageAlert}
          selectedFile={selectedFile}
          setOpenMessageAlert={setOpenMessageAlert}
          setSelectedFile={setSelectedFile}
          setTeamName={setTeamName}
          setUploadMessage={setUploadMessage}
          teamName={teamName}
          uploadMessage={uploadMessage}
          team={prop.team}
        />
      </>
    );
};

export default EditTeam;
