/**
 * @name Hook: useGetRules
 * @description A custom hook that retrieves rule unit and role state from the Redux store,
 *    and provides functions to get content revisions, refresh the page, and handle target items.
 * @returns An object containing the content revision, invalid access flag, loading state, refresh function, target item and selectedRule.
 */

import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store";
import { clearAllState, getContentById, getContentList, getFavouriteRules, getMyAcknowledgement, selectRule, setSelectedUserLocations, setSelectedUserRoles } from "../redux/actions";
import { getMyChatThreads } from "../redux/actions/Chat";
import { RuleUnitState } from "../types/models/RuleUnitState";
import { RoleState } from "../types/models/RoleState";
import { useSearchParams } from "react-router-dom";
import { getOrigin } from "../utils/RuleUtil";

export function useRules() {
  const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
  const { selectedRule, baseContentRevisionDetails, baseSupportDocumentContentRevisionDetails } = ruleUnitState;
  const roleState = useSelector<AppState, RoleState>((state) => state.role);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [invalidAccess, seInvalidAccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [targetItem, setTargetItem] = useState<{ type: 'rule' | 'module', id: string } | undefined>();

  const getCurrentContentRevision = useCallback(() => {
    const contentRevision = (baseContentRevisionDetails ?? [])
      .concat(baseSupportDocumentContentRevisionDetails ?? [])
      .find(detail =>
        detail.RuleInstances?.some(rule => rule.RuleInstanceId === selectedRule?.RuleInstanceId)
      );

    return contentRevision;
  }, [selectedRule, baseContentRevisionDetails, baseSupportDocumentContentRevisionDetails]);

  useEffect(() => {
    const ruleInstanceId = searchParams.get('ruleId');
    const moduleId = searchParams.get('moduleId');

    const content = baseContentRevisionDetails?.concat(baseContentRevisionDetails).map((content) => content.RuleInstances).flat();
    const origin = content?.find((rule) => getOrigin(rule?.Metadata) === ruleInstanceId);

    if(origin) {
      dispatch(selectRule(origin));
    }

    if (ruleInstanceId) {
      setTargetItem({ type: 'rule', id: ruleInstanceId });
    }
    else if (moduleId) {
      setTargetItem({ type: 'module', id: moduleId });
    }
    else {
      setTargetItem(undefined);
    }
  }, [searchParams]);

  useEffect(() => {
    // Waiting for the KnownRoles API call to prevent conflicts during the first user registration.
    // This resolves an API error that occurs when creating a new user.
    if (!roleState.knownRoles) return;

    if ((ruleUnitState.baseContentRevisions && ruleUnitState.baseContentRevisions.size > 0) ||
    (ruleUnitState.baseSupportDocumentContentRevisions && ruleUnitState.baseSupportDocumentContentRevisions.length > 0)) {
    // Already called this API
    return;
  }
  
    getRules();
  }, [roleState.knownRoles]);

  // Get Alert rule details to display alert count on left bar
  useEffect(() => {
    if (ruleUnitState.baseAlertContentRevisions &&
      (!ruleUnitState.baseAlertContentRevisionDetails || ruleUnitState.baseAlertContentRevisionDetails.length === 0)) {
      // Call detail API if it's not called yet
      ruleUnitState.baseAlertContentRevisions.forEach(alertContent => {
        dispatch(getContentById(alertContent.ContentRevisionId, null));
      });
    }
  }, [ruleUnitState.baseAlertContentRevisions])


  useEffect(() => {
    if (selectedRule) {
      // Clear selection if the searched rules does not include selected rule
      if (!ruleUnitState.contentRevisionDetails?.find(detail => detail.RuleInstances?.some(rule => rule.RuleId === selectedRule.RuleId)) &&
        !ruleUnitState.supportDocumentContentRevisionDetails?.find(detail => detail.RuleInstances?.some(rule => rule.RuleId === selectedRule.RuleId))) {
        dispatch(selectRule(undefined));
      }
    }
  }, [dispatch, ruleUnitState.contentRevisionDetails, ruleUnitState.supportDocumentContentRevisionDetails, selectedRule]);

  const getRules = useCallback(() => {
    setIsLoading(true);
    // Get list of content revisions
    dispatch(getMyAcknowledgement((result) => {
      if (result) {
        dispatch(getMyChatThreads(() => {
          dispatch(getFavouriteRules(() => {
            setIsLoading(false);
          }));
        }));
      }
      else {
        seInvalidAccess(true);
        setIsLoading(false);
      }
    }));
  }, [dispatch]);

  const refreshRules = useCallback(() => {
    dispatch(clearAllState());
    // Set selected roles from Role redux
    dispatch(setSelectedUserRoles(roleState.myRoles?.map(role => role.RoleId) ?? []));
    dispatch(setSelectedUserLocations(roleState.myLocations?.map((location) => location.LNIId) ?? []));
    dispatch(getContentList(() => {
      getRules();
    }));
  }, [dispatch, roleState.myRoles, getRules]);

  return { getCurrentContentRevision, baseContentRevisionDetails, invalidAccess, isLoading, refreshRules, targetItem, selectedRule };
}