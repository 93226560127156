/* eslint-disable no-case-declarations */
import { ChatActions, GET_RESPONSES_IN_THREAD, GET_SUBORDINATE_RESPONSES, MY_CHAT_THREADS } from "../../types/actions/Chat.actions";
import { ChatMessage } from "../../types/models/ChatMessage";
import { ChatState, SubordinateChatKey } from "../../types/models/ChatState";

const initialSettings: ChatState = {
  chatMessageMap: undefined,
  chatThreads: undefined,
  subordinateChatMessageMap: undefined
};

const ChatReducer = (state = initialSettings, action: ChatActions) => {

  switch (action.type) {
    case MY_CHAT_THREADS:
      return {
        ...state,
        chatThreads: action.payload,
      };
    case GET_RESPONSES_IN_THREAD:
      if (!action.payload) {
        return { ...state };
      }

      let newMap: Map<string, ChatMessage[]>;
      if (state.chatMessageMap) {
        newMap = new Map(state.chatMessageMap);
      } else {
        newMap = new Map();
      }
      newMap.set(action.payload.RuleInstanceId, action.payload.Messages);
      return {
        ...state,
        chatMessageMap: newMap,
      };
    case GET_SUBORDINATE_RESPONSES:
      if (!action.payload) {
        return { ...state };
      }

      let newSubordinateMap: Map<SubordinateChatKey, ChatMessage[]>;
      if (state.subordinateChatMessageMap) {
        newSubordinateMap = new Map(state.subordinateChatMessageMap);
      } else {
        newSubordinateMap = new Map();
      }
      newSubordinateMap.set({ RuleInstanceId: action.payload.RuleInstanceId, UserId: action.payload.UserId ?? "" }, action.payload.Messages);
      return {
        ...state,
        subordinateChatMessageMap: newSubordinateMap,
      };
    default:
      return state;
  }
};

export default ChatReducer;
