/* eslint-disable no-case-declarations */
import {
  RuleUnitActions,
  GET_CONTENT_LIST,
  GET_CONTENT_BY_ID,
  SELECT_RULE,
  TOGGLE_RULE_MODAL,
  DOWNLOAD_HTML,
  SEARCH_TEXT,
  ACTIVE_AUDIO_INDEX,
  POST_ACKNOWLEDGED,
  SELECT_ROLES,
  SET_ENABLE_FILTER_BY_ROLES,
  SELECT_LOCATIONS,
  GET_MY_ACKNOWLEDGEMENT,
  HISTORY_SEARCH_TEXT,
  CLEAR_ALL_DATA,
  CHECK_CONTENT_UPDATE,
  GET_MY_FAVOURITES,
  UPDATE_FAVOURITE_RULE
} from "../../types/actions/RuleUnit.actions";
import { ContentRevision } from "../../types/models/ContentRevision";
import { PostAcknowledgedRequest } from "../../types/models/PostAcknowledgedRequest";
import { RuleInstance } from "../../types/models/RuleInstance";
import { RuleUnitState } from "../../types/models/RuleUnitState";
import { FavouriteActionType } from '../../types/models/PostUpdateFavouriteRequest';
import {
  checkConformance,
  getAlertRuleInstancesForRoles,
  getCategorisedContentRevisions,
  getModuleNameFromDisplayName,
  getRuleInstancesForRoles,
  getRuleInstancesForSearchText,
  getSortedFavouriteRules
} from "../../utils/RuleUtil";
import { FavouriteRule } from "../../types/models/FavouriteRule";

const initialSettings: RuleUnitState = {
  userShieldId: "",
  contentRevisions: undefined,
  baseContentRevisions: undefined,
  contentRevisionDetails: undefined,
  baseContentRevisionDetails: undefined,
  selectedRule: undefined,
  showRuleModal: false,
  ruleWebPages: undefined,
  searchText: undefined,
  activeAudioIndex: undefined,
  selectedRoles: undefined,
  filterByRoles: false,
  accessibleRuleInstanceIds: undefined,
  selectedLocations: undefined,
  baseAlertContentRevisions: undefined,
  baseAlertContentRevisionDetails: undefined,
  baseAlertRules: undefined,
  roleFilteredAlertRules: undefined,
  alertRules: undefined,
  baseAlertHistories: undefined,
  alertHistories: undefined,
  supportDocumentContentRevisions: undefined,
  baseSupportDocumentContentRevisions: undefined,
  supportDocumentContentRevisionDetails: undefined,
  baseSupportDocumentContentRevisionDetails: undefined,
  historySearchText: undefined,
  isContentUpdated: false,
  myFavourites: undefined,
  baseFavouriteRules: undefined,
  favouriteRules: undefined,
};

const RuleUnitReducer = (state = initialSettings, action: RuleUnitActions) => {
  switch (action.type) {
    case GET_CONTENT_LIST:
      const categorisedContents = getCategorisedContentRevisions(action.payload ?? []);
      const ruleMap = getMapForModules(categorisedContents.Modules);

      return {
        ...state,
        userShieldId: action.shieldId,
        contentRevisions: ruleMap,
        baseContentRevisions: ruleMap,
        supportDocumentContentRevisions: categorisedContents.Documents,
        baseSupportDocumentContentRevisions: categorisedContents.Documents,
        baseAlertContentRevisions: categorisedContents.Alerts,
        isContentUpdated: false
      };

    case GET_CONTENT_BY_ID:
      let details = state.contentRevisionDetails ?? [];

      if (state.supportDocumentContentRevisionDetails) {
        details = details.concat(state.supportDocumentContentRevisionDetails);
      }

      if (state.baseAlertContentRevisionDetails) {
        details = details.concat(state.baseAlertContentRevisionDetails);
      }

      if (action.payload) {
        const content = action.payload;

        // Get Taxonomy from ContentForUser response. This can be deleted if the ContentByID returns Taxonomy
        let taxonomy: string | null = null;
        state.baseContentRevisions?.forEach(module => {
          const baseContent = module.find(c => c.ContentRevisionId === content.ContentRevisionId);
          if (baseContent) {
            taxonomy = baseContent.Taxonomy;
          }
        });
        if (!taxonomy) {
          const baseContent = state.baseAlertContentRevisions?.find(c => c.ContentRevisionId === content.ContentRevisionId);
          if (baseContent) {
            taxonomy = baseContent.Taxonomy;
          }
        }
        content.Taxonomy = taxonomy;

        if (details) {
          if (!details.some(detail => detail.ContentRevisionId === content.ContentRevisionId)) {
            details.push(content);
          }
        } else {
          details = [content];
        }
      }

      const contents = getCategorisedContentRevisions(details ?? []);

      let ruleIdsForRoles: string[] = [];
      contents.Modules.forEach(detail => {
        const rules = getRuleInstancesForRoles(detail.RuleInstances ?? [], state.selectedRoles ?? []) ?? [];
        ruleIdsForRoles = ruleIdsForRoles.concat(rules.map(rule => rule.RuleInstanceId));
      });

      const ruleIdsForLocations: string[] = [];
      state.baseContentRevisionDetails?.forEach(detail => {
        detail.RuleInstances?.forEach((rule) => {
          const relatedToSelectedLocationsRule = checkConformance(rule, state.selectedLocations ?? []);
          if(relatedToSelectedLocationsRule) {
            ruleIdsForLocations.push(relatedToSelectedLocationsRule.RuleInstanceId);
          }
        });
      });

      const rules = getAlertsAndHistories(addCreatedAt(state.baseAlertContentRevisions, contents.Alerts), state.acknowledgedRuleIds ?? [])
      const filteredByRoleAlertRules = getAlertRuleInstancesForRoles(rules.AlertRules, state.selectedRoles ?? [], state.selectedLocations ?? []);

      // Favourite Rules
      let favouriteMarkedRules: RuleInstance[] = [];
      if (state.myFavourites && state.myFavourites.length > 0) {
        favouriteMarkedRules = getSortedFavouriteRules(
          details
            .flatMap(detail => detail.RuleInstances ?? [])
            .filter(rule => state.myFavourites?.some(favourite => favourite.rule_id === rule?.RuleId)),
          state.myFavourites);
      }

      return {
        ...state,
        contentRevisionDetails: contents.Modules,
        baseContentRevisionDetails: contents.Modules,
        accessibleRuleInstanceIds: ruleIdsForRoles.concat(ruleIdsForLocations),
        supportDocumentContentRevisionDetails: contents.Documents,
        baseSupportDocumentContentRevisionDetails: contents.Documents,
        baseAlertContentRevisionDetails: contents.Alerts,
        baseAlertRules: rules.AlertRules,
        roleFilteredAlertRules: filteredByRoleAlertRules,
        alertRules: filteredByRoleAlertRules,
        alertHistories: rules.HistoryRules,
        baseAlertHistories: rules.HistoryRules,
        baseFavouriteRules: favouriteMarkedRules,
        favouriteRules: favouriteMarkedRules
      };
    case SELECT_RULE:
      return {
        ...state,
        selectedRule: action.payload,
      };
    case TOGGLE_RULE_MODAL:
      return {
        ...state,
        showRuleModal: action.payload,
      };
    case DOWNLOAD_HTML:
      let pages;
      if (state.ruleWebPages) {
        pages = new Map<string, string>(Object.entries(state.ruleWebPages));
      }
      else {
        pages = new Map<string, string>();
      }
      if (action.payload) {
        if (!pages.has(action.fileName)) {
          pages.set(action.fileName, action.payload);
        }
      }
      return {
        ...state,
        ruleWebPages: Object.fromEntries(pages)
      };
    case SEARCH_TEXT:
      const newSearchText = (action.payload?.length ?? 0) > 1 ? action.payload : ""; // start search from 2 chars
      const searchedResult: Array<ContentRevision> = getFilteredContentDetails(
        state.baseContentRevisionDetails ?? [],
        newSearchText,
        state.filterByRoles ?? false,
        state.selectedRoles ?? []
      );

      const filteredContents = getFilteredContents(
        state.baseContentRevisions ?? new Map(),
        searchedResult,
        newSearchText,
        state.filterByRoles ?? false,
        false
      );

      const searchedDocuments: Array<ContentRevision> = getFilteredContentDetails(
        state.baseSupportDocumentContentRevisionDetails ?? [],
        newSearchText,
        state.filterByRoles ?? false,
        state.selectedRoles ?? []
      );

      const filteredAlertRules = (newSearchText.length > 1)
        ? getRuleInstancesForSearchText(state.roleFilteredAlertRules ?? [], newSearchText)
        : state.roleFilteredAlertRules;

      const filteredHistory = (newSearchText.length > 1)
        ? getRuleInstancesForSearchText(state.baseAlertHistories ?? [], newSearchText)
        : state.baseAlertHistories;

      let filteredFavouriteRules = (newSearchText.length > 1)
        ? getRuleInstancesForSearchText(state.baseFavouriteRules ?? [], newSearchText)
        : state.baseFavouriteRules;
      // Filter favourite rules by roles
      if (state.filterByRoles) filteredFavouriteRules = getRuleInstancesForRoles(filteredFavouriteRules ?? [], state.selectedRoles ?? []);

      return {
        ...state,
        searchText: action.payload,
        contentRevisions: filteredContents,
        contentRevisionDetails: searchedResult,
        supportDocumentContentRevisions: searchedDocuments, // Overwrite by the detail list
        supportDocumentContentRevisionDetails: searchedDocuments,
        alertRules: filteredAlertRules,
        alertHistories: filteredHistory,
        favouriteRules: filteredFavouriteRules
      };
    case ACTIVE_AUDIO_INDEX:
      return {
        ...state,
        activeAudioIndex: action.payload
      };
    case POST_ACKNOWLEDGED:
      if (action.payload.AcknowledgementType === "acknowledged") {
        const body: PostAcknowledgedRequest = action.payload;
        let ids = state.acknowledgedRuleIds;
        if (ids) {
          ids.push(body.RuleInstanceId);
        }
        else {
          ids = [body.RuleInstanceId]
        }
        const newSeparatedRules = getAlertsAndHistories(addCreatedAt(state.baseAlertContentRevisions, state.baseAlertContentRevisionDetails ?? []), ids);

        const acknowledgedRuleIndex = state.alertRules?.findIndex(rule => rule.RuleInstanceId === body.RuleInstanceId) ?? -1;
        const searchedAlerts = state.alertRules;
        if (acknowledgedRuleIndex > -1) {
          searchedAlerts?.splice(acknowledgedRuleIndex, 1);
        }

        return {
          ...state,
          acknowledgedRuleIds: ids,
          baseAlertRules: newSeparatedRules.AlertRules,
          roleFilteredAlertRules: getAlertRuleInstancesForRoles(newSeparatedRules.AlertRules, state.selectedRoles ?? [], state.selectedLocations ?? []),
          alertRules: searchedAlerts,
          baseAlertHistories: newSeparatedRules.HistoryRules,
          alertHistories: newSeparatedRules.HistoryRules
        };
      } else {
        return {
          ...state
        };
      }
    case SELECT_ROLES:
      const searchedResultByRole: Array<ContentRevision> = getFilteredContentDetails(
        state.baseContentRevisionDetails ?? [],
        state.searchText ?? "",
        state.filterByRoles ?? false,
        action.payload
      )

      const filteredContentsByRole = getFilteredContents(
        state.baseContentRevisions ?? new Map(),
        searchedResultByRole,
        state.searchText ?? "",
        state.filterByRoles ?? false,
        false
      );

      const searchedDocumentsByRole: Array<ContentRevision> = getFilteredContentDetails(
        state.baseSupportDocumentContentRevisionDetails ?? [],
        state.searchText ?? "",
        state.filterByRoles ?? false,
        action.payload
      )

      let ruleIdsForRolesByRole: string[] = [];
      state.baseContentRevisionDetails?.forEach(detail => {
        const rules = getRuleInstancesForRoles(detail.RuleInstances ?? [], action.payload ?? []) ?? [];
        ruleIdsForRolesByRole = ruleIdsForRolesByRole.concat(rules.map(rule => rule.RuleInstanceId));
      });

      const ruleIdsForLocationsBySelectedLocations: string[] = [];
      state.baseContentRevisionDetails?.forEach(detail => {
        detail.RuleInstances?.forEach((rule) => {
          const relatedToSelectedLocationsRule = checkConformance(rule, state.selectedLocations ?? []);
          if(relatedToSelectedLocationsRule) {
            ruleIdsForLocationsBySelectedLocations.push(relatedToSelectedLocationsRule.RuleInstanceId);
          }
        });
      });


      const roleFilteredAlertRules = getAlertRuleInstancesForRoles(state.baseAlertRules ?? [], action.payload ?? [], state.selectedLocations ?? []);

      const filteredAndSearchedAlertRules = (state.searchText && state.searchText.length > 1)
        ? getRuleInstancesForSearchText(roleFilteredAlertRules ?? [], state.searchText)
        : roleFilteredAlertRules;

      return {
        ...state,
        selectedRoles: action.payload,
        contentRevisions: filteredContentsByRole,
        contentRevisionDetails: searchedResultByRole,
        supportDocumentContentRevisions: searchedDocumentsByRole, // Overwrite by the detail list
        supportDocumentContentRevisionDetails: searchedDocumentsByRole,
        accessibleRuleInstanceIds: ruleIdsForRolesByRole.concat(ruleIdsForLocationsBySelectedLocations),
        roleFilteredAlertRules: roleFilteredAlertRules,
        alertRules: filteredAndSearchedAlertRules
      };
    case SET_ENABLE_FILTER_BY_ROLES:
      const searchedResultByEnableRole: Array<ContentRevision> = getFilteredContentDetails(
        state.baseContentRevisionDetails ?? [],
        state.searchText ?? "",
        action.payload,
        state.selectedRoles ?? []
      )

      const filteredContentsByEnableRole = getFilteredContents(
        state.baseContentRevisions ?? new Map(),
        searchedResultByEnableRole,
        state.searchText ?? "",
        action.payload,
        false
      );

      const searchedDocumentsByEnableRole: Array<ContentRevision> = getFilteredContentDetails(
        state.baseSupportDocumentContentRevisionDetails ?? [],
        state.searchText ?? "",
        action.payload,
        state.selectedRoles ?? []
      )

      let roleFilteredFavouriteRules = (state.searchText && state.searchText.length > 1)
        ? getRuleInstancesForSearchText(state.baseFavouriteRules ?? [], state.searchText)
        : state.baseFavouriteRules;
      // Filter favourite rules by roles
      if (action.payload) roleFilteredFavouriteRules = getRuleInstancesForRoles(roleFilteredFavouriteRules ?? [], state.selectedRoles ?? []);

      return {
        ...state,
        filterByRoles: action.payload,
        contentRevisions: filteredContentsByEnableRole,
        contentRevisionDetails: searchedResultByEnableRole,
        supportDocumentContentRevisions: searchedDocumentsByEnableRole,
        supportDocumentContentRevisionDetails: searchedDocumentsByEnableRole,
        favouriteRules: roleFilteredFavouriteRules
      };
    case SELECT_LOCATIONS:

    let ruleForRoles: string[] = [];
    state.baseContentRevisionDetails?.forEach(detail => {
      const rules = getRuleInstancesForRoles(detail.RuleInstances ?? [], state.selectedRoles ?? []) ?? [];
      ruleForRoles = ruleForRoles.concat(rules.map(rule => rule.RuleInstanceId));
    });

      const ruleIdsForLocationsByLocation: string[] = [];
      state.baseContentRevisionDetails?.forEach(detail => {
        detail.RuleInstances?.forEach((rule) => {
          const relatedToSelectedLocationsRule = checkConformance(rule, action.payload);
          if(relatedToSelectedLocationsRule) {
            ruleIdsForLocationsByLocation.push(relatedToSelectedLocationsRule.RuleInstanceId);
          }
        });
      });

      const filteredAlerts = getAlertRuleInstancesForRoles(state.baseAlertRules ?? [], state.selectedRoles ?? [], action.payload ?? []);

      const filteredAndSearchedAlerts = (state.searchText && state.searchText.length > 1)
      ? getRuleInstancesForSearchText(filteredAlerts ?? [], state.searchText)
      : filteredAlerts;
      

      return {
        ...state,
        selectedLocations: action.payload,
        accessibleRuleInstanceIds: ruleIdsForLocationsByLocation.concat(ruleForRoles),
        alertRules: filteredAndSearchedAlerts,
      };
    case GET_MY_ACKNOWLEDGEMENT:
      if (state.baseAlertContentRevisionDetails) {
        const alertRules = getAlertsAndHistories(addCreatedAt(state.baseAlertContentRevisions, state.baseAlertContentRevisionDetails ?? []), action.payload ?? []);
        const filteredByRoleAlerts = getAlertRuleInstancesForRoles(alertRules.AlertRules, state.selectedRoles ?? [], state.selectedLocations ?? []);
        return {
          ...state,
          baseAlertRules: alertRules.AlertRules,
          roleFilteredAlertRules: filteredByRoleAlerts,
          alertRules: filteredByRoleAlerts,
          alertHistories: alertRules.HistoryRules,
          baseAlertHistories: alertRules.HistoryRules,
        };
      } else {
        return {
          ...state,
          acknowledgedRuleIds: action.payload
        };
      }
    case HISTORY_SEARCH_TEXT:
      const filteredHistories = (action.payload && action.payload.length > 0)
        ? getRuleInstancesForSearchText(state.baseAlertHistories ?? [], action.payload)
        : state.baseAlertHistories;

      return {
        ...state,
        historySearchText: action.payload,
        alertHistories: filteredHistories
      };
    case CLEAR_ALL_DATA:
      return initialSettings;
    case CHECK_CONTENT_UPDATE:
      return {
        ...state,
        isContentUpdated: checkIfContentsUpdated(state, action.payload ?? [])
      };
    case GET_MY_FAVOURITES:
      let favouriteRules: RuleInstance[] = [];
      if (state.baseContentRevisionDetails && state.baseSupportDocumentContentRevisionDetails) {
        favouriteRules = getSortedFavouriteRules(state.baseContentRevisionDetails
          .concat(state.baseSupportDocumentContentRevisionDetails)
          .flatMap(detail =>
            detail.RuleInstances?.find(rule => action.payload?.some(favourite => favourite.rule_id === rule.RuleId) ?? false) ?? []
          ), action.payload ?? []);
      }

      // Filter favourite rules by roles
      if (state.filterByRoles) favouriteRules = getRuleInstancesForRoles(favouriteRules ?? [], state.selectedRoles ?? []);

      return {
        ...state,
        myFavourites: action.payload,
        baseFavouriteRules: favouriteRules,
        favouriteRules: favouriteRules,
      };
    case UPDATE_FAVOURITE_RULE:
      let updateFavourites: FavouriteRule[] = [];
      let updateBaseFavouriteRules: RuleInstance[] = [];
      let updateFavouriteRules: RuleInstance[] = [];
      if (action.payload.Action == FavouriteActionType.Add) {
        updateFavourites = [...state.myFavourites ?? []];
        const newFavourite: FavouriteRule = {
          rule_id: action.payload.RuleId,
          created_at: new Date().toISOString()
        };
        updateFavourites.push(newFavourite);

        updateBaseFavouriteRules = [...state.baseFavouriteRules ?? []];
        updateFavouriteRules = [...state.favouriteRules ?? []];
        let contentList: ContentRevision[] = [];
        if (state.baseContentRevisionDetails) contentList = contentList.concat(state.baseContentRevisionDetails);
        if (state.baseSupportDocumentContentRevisionDetails) contentList = contentList.concat(state.baseSupportDocumentContentRevisionDetails);
        const addedRule = contentList
          .flatMap(detail => detail.RuleInstances ?? [])
          .find(rule => rule.RuleId === action.payload.RuleId);

        if (addedRule) {
          updateBaseFavouriteRules.push(addedRule);
          updateBaseFavouriteRules = getSortedFavouriteRules(updateBaseFavouriteRules, updateFavourites);
          updateFavouriteRules.push(addedRule);
          updateFavouriteRules = getSortedFavouriteRules(updateFavouriteRules, updateFavourites);
        }
      }
      else {
        updateFavourites = state.myFavourites?.filter((id) => { return id.rule_id !== action.payload.RuleId }) ?? [];
        updateBaseFavouriteRules = state.baseFavouriteRules?.filter((rule) => { return rule.RuleId !== action.payload.RuleId }) ?? [];
        updateFavouriteRules = state.favouriteRules?.filter((rule) => { return rule.RuleId !== action.payload.RuleId }) ?? [];
      }

      return {
        ...state,
        myFavourites: updateFavourites,
        baseFavouriteRules: updateBaseFavouriteRules,
        favouriteRules: updateFavouriteRules,
      };
    default:
      return state;
  }
};

const checkIfContentsUpdated = (state: RuleUnitState, contents: ContentRevision[]) => {
  let result = false;
  const categorisedContents = getCategorisedContentRevisions(contents);

  // Check if there is an update in Modules
  let currentContentCount = 0;
  state.baseContentRevisions?.forEach(contents => currentContentCount += contents.length);
  if (currentContentCount !== categorisedContents.Modules.length) {
    return true;
  }

  state.baseContentRevisions?.forEach(contents => {
    if (categorisedContents.Modules.length > 0) {
      if (!categorisedContents.Modules.some(latestContent =>
        contents.some(content =>
          content.ContentRevisionId === latestContent.ContentRevisionId && content.CreatedAt === latestContent.CreatedAt))) {

        result = true;
      }
    }
  });

  if (result) {
    return result;
  }

  // Check if there is an update in Documents
  currentContentCount = state.baseSupportDocumentContentRevisions?.length ?? 0;
  if (currentContentCount !== categorisedContents.Documents.length) {
    return true;
  }

  if (categorisedContents.Documents.length > 0) {
    if (!categorisedContents.Documents.some(latestContent =>
      state.baseSupportDocumentContentRevisions?.some(content =>
        content.ContentRevisionId === latestContent.ContentRevisionId && content.CreatedAt === latestContent.CreatedAt))) {
      result = true;
    }
  }

  if (result) {
    return result;
  }

  // Check if there is an update in Alerts
  if ((state.baseAlertContentRevisions?.length ?? 0) !== categorisedContents.Alerts.length) {
    return true;
  }

  if (categorisedContents.Alerts.length > 0) {
    if (!categorisedContents.Alerts.some(
      latestContent => state.baseAlertContentRevisions?.some(
        content => content.ContentRevisionId === latestContent.ContentRevisionId && content.CreatedAt === latestContent.CreatedAt))) {
      result = true;
    }
  }

  return result;
}

const getAlertsAndHistories = (alertContents: ContentRevision[], acknowledgedIds: string[]) => {
  let alertRules: RuleInstance[] = []
  let historyRules: RuleInstance[] = []
  if (alertContents) {
    // Divide active alerts and histories, replace DisplayName to Content.DisplayName
    historyRules = alertContents
      .flatMap(alert => alert.RuleInstances?.map(rule => { rule.DisplayName = alert.DisplayName; rule.CreatedAt = alert.CreatedAt; return rule; }) ?? [])
      .filter(ruleInstance => acknowledgedIds?.some(id => id === ruleInstance.RuleInstanceId)) ?? [];
    alertRules = alertContents
      .flatMap(alert => alert.RuleInstances?.map(rule => { rule.DisplayName = alert.DisplayName; rule.CreatedAt = alert.CreatedAt; return rule; }) ?? [])
      .filter(ruleInstance => !acknowledgedIds?.some(id => id === ruleInstance.RuleInstanceId)) ?? [];
  }

  // sort by prefix createdAt (or display name)
  historyRules = historyRules.sort((a, b) => {
    if(a.CreatedAt && b.CreatedAt) {
      return new Date(b.CreatedAt ?? "").getTime() - new Date(a.CreatedAt ?? "").getTime();
    }
    else {
      return a.DisplayName > b.DisplayName ? 1 : -1;
    }
  })
  alertRules = alertRules.sort((a, b) => {
    if (a.CreatedAt && b.CreatedAt) {
      return new Date(b.CreatedAt ?? "").getTime() - new Date(a.CreatedAt ?? "").getTime();
    }
    else {
      return a.DisplayName > b.DisplayName ? 1 : -1;
    }
  })

  return {
    "AlertRules": alertRules,
    "HistoryRules": historyRules
  }
}

const getMapForSupportDocument = (revisions: ContentRevision[]) => {

  const map = new Map<string, ContentRevision[]>();

  revisions = revisions.sort((a, b) => {
    return a.DisplayName > b.DisplayName ? 1 : -1
  })

  revisions.forEach(content => {
    const moduleName = getModuleNameFromDisplayName(content.DisplayName);
    if (map.has(moduleName)) {
      map.get(moduleName)?.push(content);
    } else {
      map.set(moduleName, [content]);
    }
  });
  return map
}

const getMapForModules = (revisions: ContentRevision[]) => {

  const map = new Map<string, ContentRevision[]>();

  revisions = revisions.sort((a, b) => {
    return a.DisplayName > b.DisplayName ? 1 : -1
  })

  revisions.forEach(content => {
    const moduleName = content.Taxonomy ? content.Taxonomy : "Module";
    if (map.has(moduleName)) {
      map.get(moduleName)?.push(content);
    } else {
      map.set(moduleName, [content]);
    }
  });

  const sortedArray = Array.from(map).sort((a, b) => (a[0] > b[0]) ? 1 : -1);
  return new Map(sortedArray);
}

const getFilteredContents = (
  baseContents: Map<string, ContentRevision[]>,
  filteredDetails: Array<ContentRevision>,
  searchText: string,
  filterByRole: boolean,
  isSupportDocument: boolean
) => {
  if (searchText.length === 0 && !filterByRole) {
    // no filters
    return baseContents;
  }
  else {

    return isSupportDocument ? getMapForSupportDocument(filteredDetails) : getMapForModules(filteredDetails);
  }
}

const getFilteredContentDetails = (
  baseDetails: Array<ContentRevision>,
  searchText: string,
  filterByRole: boolean,
  selectedRoles: string[]
) => {

  if (searchText.length <= 1 && !filterByRole) {
    // no filters
    return baseDetails;
  }
  else {

    let filteredList: Array<ContentRevision> = [];
    if (searchText.length > 1) {
      baseDetails.forEach(detail => {
        // deep copy
        const newDetail: ContentRevision = JSON.parse(JSON.stringify(detail));
        const filteredRules = getRuleInstancesForSearchText(detail.RuleInstances ?? [], searchText);

        if (filteredRules?.length && filteredRules?.length > 0) {
          newDetail.RuleInstances = filteredRules;
          filteredList.push(newDetail);
        }
      });
    }

    if (filterByRole) {
      const targetDetails: ContentRevision[] = JSON.parse(JSON.stringify(searchText.length > 0 ? filteredList : baseDetails));

      // clear list to filter by text and role
      filteredList = [];

      targetDetails.forEach(detail => {
        const newDetail: ContentRevision = JSON.parse(JSON.stringify(detail));
        const filteredRules = getRuleInstancesForRoles(detail.RuleInstances ?? [], selectedRoles);

        if (filteredRules?.length && filteredRules?.length > 0) {
          newDetail.RuleInstances = filteredRules;
          filteredList.push(newDetail);
        }
      });
    }

    return filteredList;
  }
}

const addCreatedAt = (array1: ContentRevision[] | undefined, array2: ContentRevision[]) => {
  const createdAtMap = new Map(array1?.map(item => [item.ContentRevisionId, item.CreatedAt]));

  return array2.map(item => {
    const createdAt = createdAtMap.get(item.ContentRevisionId);
    return {
      ...item,
      CreatedAt: createdAt ? createdAt : item.CreatedAt
    };
  });
}

export default RuleUnitReducer;
