/**
 * @name ModulesCard
 * @description Lists rules, grouped into modules, in a card.
 * @features 
 *  - Filters (by role, location)
 *  - Internal linking to rule (module is expanded, rule is highlighted)
 */

import React, { Fragment, ReactNode, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { AppState } from "../../../redux/store";
import { RuleUnitState } from "../../../types/models/RuleUnitState";
import { fontColor } from "../../../constants/ColorSets";
import AppCard from "../../../components/AppCard";
import RuleInstanceListSkeleton from "../../../components/RuleInstanceListSkeleton";
import ModuleView from "../ModuleView";
import EmptyResult from "../EmptyResult";

export default function ModulesCard() {
  const DEFAULT_CONTENT_NAME = "Other Content";
  const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
  const [groupedModules, setGroupedModules] = useState<Record<string, ReactNode[]>>();
  const [sectionHeaders, setSectionHeaders] = useState<string[]>();

  const splitTaxonomy = (taxonomy: string) => {
    if (!taxonomy) {
      return;
    }

    let header = "";
    let subHeader = "";
    let subTitle = "";

    let subText = "";
    // Get section name and others
    const headers = taxonomy.split(";");
    if (headers.length >= 2) {
      header = headers[0];
      subText = headers[1];
    } else {
      header = DEFAULT_CONTENT_NAME; // Default module name
      subText = taxonomy;
    }

    const subTexts = subText.split(":");
    if (subTexts.length >= 2) {
      subHeader = subTexts[0];
      subTitle = subTexts[1];
    } else {
      subHeader = subText;
      subTitle = "";
    }

    return { header, subHeader, subTitle };
  };

  interface ModuleCardProp {
    header: string,
    children: ReactNode
  }
  const ModuleCard: React.FC<ModuleCardProp> = ({ header, children }) => {
    return (<Box key={header} sx={{ mb: 2 }}>
      <AppCard
        contentStyle={{ px: 0 }}
        title={
          <Box
            component="h3"
            sx={{
              color: "card.headerTextColor",
              fontWeight: 600,
              fontSize: 18,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
              margin: 0,
              display: "flex",
            }}
          >
            <Box sx={{ marginLeft: 1.5 }}>{header}</Box>
          </Box>
        }
        headerStyle={{
          backgroundColor: fontColor.grayBackground,
        }}
      >
        {children}
      </AppCard>
    </Box>)
  }

  useEffect(() => {
    const headers: string[] = [];
    ruleUnitState.baseContentRevisions?.forEach((contents, moduleName) => {
      const splitModuleNames = splitTaxonomy(moduleName);
      if (splitModuleNames && !headers.includes(splitModuleNames.header)) {
        headers.push(splitModuleNames.header);
      }
    });
    headers.sort((a, b) => {
      if (a === DEFAULT_CONTENT_NAME) return 1;
      else if (b === DEFAULT_CONTENT_NAME) return -1;
      else return a > b ? 1 : -1;
    });
    setSectionHeaders(headers);
  }, [ruleUnitState.baseContentRevisions]);

  useEffect(() => {
    const groupedElements: Record<string, ReactNode[]> = {};
    ruleUnitState.contentRevisions?.forEach((contents, moduleName) => {
      const splitModuleNames = splitTaxonomy(moduleName);
      if (splitModuleNames) {
        if (!groupedElements[splitModuleNames.header]) {
          groupedElements[splitModuleNames.header] = [];
        }
        groupedElements[splitModuleNames.header].push(
          <ModuleView
            key={moduleName}
            forDocumentDisplay={false}
            moduleName={splitModuleNames?.subHeader}
            contentRevisions={contents}
            subtitle={splitModuleNames?.subTitle}
          />
        );
      }
    });
    setGroupedModules(groupedElements);
  }, [ruleUnitState.baseContentRevisions, ruleUnitState.contentRevisions]);

  const getModules = useCallback(() => {
    if (groupedModules) {
      return (
        <Box>
          {sectionHeaders?.map((header) => {
            const elements = groupedModules[header] as ReactNode[];
            return (
              <ModuleCard key={header} header={header}>
                {(!elements || elements.length === 0) ? (
                  <EmptyResult>No items matching your current search.</EmptyResult>
                ) : (
                  elements.map((element, idx) => (
                    <Fragment key={idx}>{element}</Fragment>
                  ))
                )}
              </ModuleCard>);
          })}
        </Box>
      );
    }
  }, [groupedModules]);

  const getDisplayModules = () => {
    if (ruleUnitState.baseContentRevisions === undefined) {
      // The API is no done yet
      return (
        <ModuleCard header="Modules">
          <RuleInstanceListSkeleton />
        </ModuleCard>
      );
    } else if (ruleUnitState.baseContentRevisions?.size === 0) {
      // The API returns no modules
      return (
        <ModuleCard header="Modules">
          <EmptyResult>No data to display.</EmptyResult>
        </ModuleCard>
      );
    } else {
      return getModules();
    }
  };

  return (
    <Box component="section" id="modules">
      {getDisplayModules()}
    </Box>
  );
}
