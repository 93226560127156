import React from "react";
import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { theme } from "../../themes/theme";
import { getAuthUserInfoFromToken } from "../../components/AppBase/AppAuthProvider";
import ProgressPieChart from "../../components/TableItems/ProgressPieChart";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store";
import { RuleUnitState } from "../../types/models/RuleUnitState";
import { getAlertRuleInstancesForRoles } from "../../utils/RuleUtil";
import { RoleMap } from "../../constants/AppEnums";
import { useAlerts } from "../../hooks/useAlerts";

const UserTable: React.FC = () => {
    const { isLoading } = useAlerts(); // Call APIs to get alert rules
    const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
    const userInfo = getAuthUserInfoFromToken();

    const getPieChartForUserProgress = () => {
        // Get alerts filtered by roles in "access token". This does not include selected roles on filter modal
        const alerts = getAlertRuleInstancesForRoles(ruleUnitState.baseAlertRules ?? [], userInfo?.Roles ?? [], []);
        const histories = getAlertRuleInstancesForRoles(ruleUnitState.baseAlertHistories ?? [], userInfo?.Roles ?? [], []);
        return (<ProgressPieChart total={alerts.length + histories.length} completed={histories.length} />);
    };

    const SkeletonRow = () => {
        return <TableRow >
            {Array.from({ length: 2 }, (_, i) => <TableCell key={i} height={"52px"}><Skeleton variant="text" height='1.5em' /></TableCell>)}
        </TableRow>
    };

    return (
        <TableContainer component={Paper} sx={{ borderRadius: 3 }}>
            <Table size="small">
                <TableHead sx={{ backgroundColor: theme.table.subHeaderBgColor }}>
                    <TableRow>
                        <TableCell>Roles</TableCell>
                        <TableCell align="center" width={"120px"}>Alerts</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading ?
                        (
                            <>
                                <SkeletonRow />
                                <SkeletonRow />
                            </>
                        ) : (
                            <TableRow >
                                <TableCell>{userInfo?.Roles?.map(role => RoleMap.get(role) ?? role).join(", ")}</TableCell>
                                <TableCell>{getPieChartForUserProgress()}</TableCell>
                            </TableRow>
                        )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UserTable;
