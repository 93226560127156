import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { ReactNode } from "react";
import { IdTokenClaimModel } from "../../types/models/IdTokenClaimModel";
import { UserRole } from "../../types/models/UserRole";

// MSAL configuration
import { AuthConfig } from "../../config/AuthConfig";
import { AuthUserInfo } from "../../types/models/AuthUserInfo";

export const msalInstance = new PublicClientApplication(AuthConfig);

interface AppAuthProviderProps {
    children: ReactNode;
}

const AppAuthProvider: React.FC<AppAuthProviderProps> = (prop) => {
    return (
        <MsalProvider instance={msalInstance}>
            {prop.children}
        </MsalProvider>
    );
};

export const getAuthUserInfoFromToken = () => {
    const UNKNOWN_USER_NAME = "Unknown";
    const accounts = msalInstance.getAllAccounts();
    if (!accounts || accounts.length === 0) {
        return undefined;
    }
    const currentAccount = accounts[0];
    let idToken: IdTokenClaimModel;
    try {
        idToken = currentAccount.idTokenClaims as IdTokenClaimModel;
        if (!idToken) {
            return undefined;
        }
    }
    catch (e) {
        console.error(e);
        return undefined;
    }

    const name = idToken.extension_DisplayName;
    const email = idToken.extension_Email;

    let extension: UserRole;
    try {
        if (!idToken?.extension_Roles) {
            return undefined;
        }
        extension = JSON.parse(idToken.extension_Roles) as UserRole;
    } catch (e) {
        // extension_Roles claim will be checked in the jwtAxios's interceptor
        console.error(e);
        return undefined;
    }

    const userInfo: AuthUserInfo = {
        Name: name ?? UNKNOWN_USER_NAME,
        Email: email ?? "",
        Roles: extension?.Roles ?? []
    };

    return userInfo;
};

export const isCurrentAccountOrgManager = () => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts && accounts.length > 0) {
        const account = accounts[0];
        if (account.idTokenClaims) {
            const idToken = account.idTokenClaims as IdTokenClaimModel;
            if (idToken && idToken.extension_Roles) {
                const userRole: UserRole = JSON.parse(idToken.extension_Roles);
                if (userRole && userRole.AccessLevel) {
                    const lowerCaseAccessLevel = userRole.AccessLevel.toLocaleLowerCase();
                    // Treat Admin the same as OrgManager
                    if (lowerCaseAccessLevel === "orgmanager" || lowerCaseAccessLevel === "admin") {
                        return true;
                    }
                }
            }
        }
    }
    return false;
};

export const getOrgNameFromToken = () => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts && accounts.length > 0) {
        const account = accounts[0];
        if (account.idTokenClaims) {
            const idToken = account.idTokenClaims as IdTokenClaimModel;
            if (idToken) {
                const userRole: UserRole = JSON.parse(idToken.extension_Roles ?? "");
                return userRole?.org;
            }
        }
    }
    return "";
};

export default AppAuthProvider;
