import React from "react";
import { Icon, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import VerticalNavItem from "./VerticalNavItem";
import { RouterConfigData } from "../../types/models/RouterConfigData";
import AppBadge from "../AppBase/AppBadge";
import AppNavLink from "../AppBase/AppNavLink";
import { Fonts } from "../../constants/AppEnums";

interface NavigationItemProp {
  item: RouterConfigData;
  level: number;
}

const NavigationItem: React.FC<NavigationItemProp> = ({ level, item }) => {

  return (
    <VerticalNavItem
      className="standard-menu"
      level={level}
      button
      component={AppNavLink}
      to={item.url}
      activeClassName="active"
      exact={item.exact}
    >
      {item.icon && (
        <Box component="span" sx={{ mr: 3, ml: 1 }}>
          <Icon
            sx={{
              fontSize: 20,
              display: "block",
              width: "24px",
              height: "24px"
            }}
            className="nav-item-icon"
            color="action"
          >
            {item.icon}
          </Icon>
        </Box>
      )
      }
      <ListItemText
        className="nav-item-text"
        primary={item.title}
        primaryTypographyProps={{ fontSize: '17px', fontWeight: Fonts.MEDIUM }}
      />
      {
        (item.count !== undefined && item.count > 0) && (
          <Box sx={{ mr: 3.5 }} >
            <AppBadge color="orangeRed" count={item.count} />
          </Box>
        )
      }
      {
        (item.alert ? 
        <Box sx={{ mr: 3.5 }} >
          <Box
            className="menu-badge"
            sx={{
              bgcolor: item.active ? "white" : "orangered",
              fontSize: 11,
              fontWeight: Fonts.SEMI_BOLD,
              height: 18,
              minWidth: 18,
              borderRadius: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: (theme) => theme.palette.secondary.contrastText,
            }}
          >
    </Box>
        </Box> : null)
      }
    </VerticalNavItem >
  );
};

export default NavigationItem;
