import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery, useTheme, Box, Grid } from "@mui/material";
import { useAlerts } from "../../hooks/useAlerts";
import { RuleInstance } from "../../types/models/RuleInstance";
import { ContentRevision } from "../../types/models/ContentRevision";
import { AlertViewMode } from "../../constants/AppEnums";
import { AppState } from "../../redux/store";
import { RuleUnitState } from "../../types/models/RuleUnitState";
import { selectRule } from "../../redux/actions";
import AppInfoView from "../../components/AppBase/AppInfoView";
import AppScrollbar from "../../components/AppBase/AppScrollbar";
import LoadingError from "../../components/LoadingError";
import RulesHeader from "../../components/RulesHeader";
import RuleInstanceDetail from "../rules/RuleInstanceDetail";
import EmptyResult from "../rules/EmptyResult";
import AlertsCard from "./AlertsCard";

export default function AlertsPage() {
    const { invalidAccess, isLoading, refreshAlerts } = useAlerts();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isSmallScreen = useMediaQuery(`(width <= ${theme.breakpoints.values.md}px)`);
    const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);

    const [selectedRule, setSelectedRule] = useState<{ Rule: RuleInstance, Content: ContentRevision }>();
    const [showRuleModal, setShowRuleModal] = useState<boolean>(false);
    const [viewMode, setViewMode] = useState<string>(AlertViewMode.Alert);

    const contentHeight = "calc(100vh - 100px)";

    useEffect(() => {
        if (selectedRule) {
            // Clear selection if the searched rules does not include selected rule
            if (viewMode === AlertViewMode.History && ruleUnitState.alertHistories && !ruleUnitState.alertHistories.find(rule => rule.RuleId === selectedRule.Rule.RuleId)) {
                setSelectedRule(undefined);
                dispatch(selectRule(undefined));
            }
            else if (viewMode === AlertViewMode.Alert && ruleUnitState.alertRules && !ruleUnitState.alertRules.find(rule => rule.RuleId === selectedRule.Rule.RuleId)) {
                setSelectedRule(undefined);
                dispatch(selectRule(undefined));
            }
        }
    }, [dispatch, ruleUnitState.alertRules, selectedRule]);

    return (
        invalidAccess ?
            (<LoadingError />) :
            (
                <>
                    <Box display="grid" gridTemplateRows="min-content 1fr" minHeight="100vh" sx={{ backgroundColor: theme.page.bgColor }}>
                        <RulesHeader title="Alerts" isRefreshing={isLoading} refreshPage={refreshAlerts} displayFilterButton={false} />
                        <Grid container sx={{ height: "100%" }}>
                            <Grid item xs={12} md={6} lg={5} sx={{ pt: 2, px: 1 }}>
                                <AppScrollbar sx={{ height: contentHeight }}>
                                    <Box sx={{ px: 1 }}>
                                        <AlertsCard
                                            handleSelectAlert={(rule, content) => {
                                                setSelectedRule({ Rule: rule, Content: content });
                                                dispatch(selectRule(rule));
                                                setShowRuleModal(true);
                                            }}
                                            handleSelectViewMode={(viewMode) => {
                                                setViewMode(viewMode);
                                                // Clear detail
                                                setSelectedRule(undefined);
                                            }}
                                        />
                                    </Box>
                                </AppScrollbar>
                            </Grid>

                            {/* Rule Content Area */}
                            <Grid item xs={12} md={6} lg={7}>
                                {isSmallScreen ? (
                                    // Modal version
                                    <RuleInstanceDetail
                                        isModal={true}
                                        key={selectedRule?.Rule.RuleInstanceId}
                                        displayFeedbackButtons={viewMode === AlertViewMode.Alert}
                                        contentRevision={selectedRule?.Content}
                                        rule={selectedRule?.Rule}
                                        isShowDetail={showRuleModal}
                                        setIsShowDetail={setShowRuleModal}
                                        displayFavouritePinButton={false}
                                    />
                                ) : (
                                    <Box py={2} pr={2} >
                                        {selectedRule ? (
                                            <AppScrollbar sx={{ height: contentHeight }}>
                                                <Box sx={{ px: 2, py: 1 }} minHeight={contentHeight} border={1} bgcolor="white" borderColor="grey.200" borderRadius={1} flexGrow={1}>
                                                    <RuleInstanceDetail
                                                        key={selectedRule?.Rule.RuleInstanceId}
                                                        displayFeedbackButtons={viewMode === AlertViewMode.Alert}
                                                        isModal={false}
                                                        contentRevision={selectedRule?.Content}
                                                        rule={selectedRule?.Rule}
                                                        displayFavouritePinButton={false}
                                                        handleAcknowledge={() => setSelectedRule(undefined)}
                                                    />
                                                </Box>
                                            </AppScrollbar>
                                        ) : (
                                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid", borderColor: "grey.200", backgroundColor: "white", height: contentHeight }}>
                                                <EmptyResult>Click on an item to see more details.</EmptyResult>
                                            </Box>
                                        )}
                                    </Box>)
                                }
                            </Grid >
                        </Grid >
                    </Box >
                    <AppInfoView />
                </>
            )
    );
}
