import { Dispatch } from "react";
import axios from "axios";
import jwtAxios from "../../auth/jwtAxios";
import contentAxios from "../../auth/contentAxios";
import { AppActions } from "../../types";
import { fetchStart, fetchSuccess, fetchError } from "./Common";
import { RuleFile } from "../../types/models/RuleFile";
import { GET_FILE_LIST, SEARCH_FILE } from "../../types/actions/RuleFile.actions";
import { getDisplayErrorMessage } from "../../utils/StringUtils";

export const getFileList = (callBack: (fileList: RuleFile[] | undefined) => void) => {

    return (dispatch: Dispatch<AppActions>) => {
        dispatch(fetchStart());
        jwtAxios
            .get("api/ListPDFs")
            .then((data) => {
                if (data.status === 200) {
                    dispatch(fetchSuccess());
                    const fileList: RuleFile[] = data.data;
                    // Sort by DisplayName ASC
                    fileList.sort((a, b) => (a.DisplayName < b.DisplayName) ? -1 : 1);
                    dispatch({ type: GET_FILE_LIST, payload: fileList });
                    callBack(fileList);
                } else {
                    dispatch(fetchError("Unable to get a PDF list right now. Please try again later or contact support"));
                    callBack(undefined);
                }
            })
            .catch((error) => {
                if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
                callBack(undefined);
            });
    };
};

export const getUploadedPDFs = (callBack: (FileList: RuleFile[] | undefined) => void) => {
    return (dispatch: Dispatch<AppActions>) => {
        dispatch(fetchStart());
        jwtAxios
            .get("api/ListDownloads")
            .then((data) => {
                if(data.status === 200) {
                    dispatch(fetchSuccess());
                    const fileList: RuleFile[] = data.data;
                    //Sort by CreatedAt date (latest first)
                    fileList.sort((a, b) => new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime());
                    dispatch({ type: GET_FILE_LIST, payload: fileList });
                    callBack(fileList);
                }
                else {
                    dispatch(fetchError("Unable to get a PDF list right now. Please try again later or contact support"));
                    callBack(undefined);
                }
            })
            .catch((error) => {
                if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
                callBack(undefined);
            })
    };
};

export const downloadPDFBlob = (contentId: string, resourceId: string, callBack: ((result: Blob | null) => void)) => {

    return (dispatch: Dispatch<AppActions>) => {
        contentAxios
            .get(`content/${contentId}/${resourceId}`, { responseType: "blob" })
            .then(response => {
                const blob = new Blob([response.data], { type: "application/pdf" });
                callBack(blob);
            })
            .catch(error => {
                console.error(error);
                dispatch(fetchError(`Failed to download a PDF file. Please try again later or contact support. \nError: ${error.message}`));
                callBack(null);
            });
    };
};

export const searchFile = (searchText: string | undefined) => {

    return (dispatch: Dispatch<AppActions>) => {
        dispatch({ type: SEARCH_FILE, payload: searchText });
    };
};


export const uploadPDF = (name: string, file: File, callBack: (result: boolean) => void) => {
    
    return (dispatch: Dispatch<AppActions>) => {
        const formData = new FormData();
        formData.append("file", file);

        jwtAxios
            .post(`api/UploadExtraFile?name=${name}`, formData, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                  },
            })
            .then((response) => {
                if(response.status === 200) {
                    callBack(true);
                }
                else {
                    dispatch(fetchError("Failed to upload PDF file. Please try again later or contact support."));
                    callBack(false);
                }
            })
            .catch((error) => {
                console.error(error);
                dispatch(fetchError(`Failed to upload PDF file. Please try again later or contact support. \nError: ${error.message}`));
                callBack(false);
            })
    }
}