import React, { ReactNode } from "react";
import Button from "@mui/material/Button";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { Fonts } from "../../constants/AppEnums";
import { TransitionProps } from "@mui/material/transitions";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { fontColor } from "../../constants/ColorSets";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  // eslint-disable-next-line no-undef
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AppConfirmDialogProps {
  dialogTitle: string | ReactNode;
  open: boolean;
  onDeny?: (isOpen: boolean) => void;
  title: string | ReactNode;
  onConfirm: () => void;
  confirmButtonTitle?: string;
  denyButtonTitle?: string;
  disableDenyButton?: boolean;
  copyToClipboard?: string;
  copyToClipboardTitle?: string;
  loading?: boolean;
}

const AppConfirmDialog: React.FC<AppConfirmDialogProps> = ({
  open,
  onDeny,
  onConfirm,
  title,
  dialogTitle,
  confirmButtonTitle = "Yes",
  denyButtonTitle = "No",
  disableDenyButton = false,
  copyToClipboard = "",
  copyToClipboardTitle = "",
  loading
}) => {
  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={() => onDeny && onDeny(false)}
    >
      {loading ? 
        <Box sx={{ 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            height: "100%", 
            width: 500,
            p: 10 }}>
          <CircularProgress size="3rem" sx={{ color: fontColor.orangeTitle }} />
        </Box> :
      <>
      <DialogTitle>
        <Typography
          sx={{
            mb: 3,
            fontWeight: Fonts.SEMI_BOLD,
          }}
          id="alert-dialog-title"
        >
          {dialogTitle}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{ color: "text.secondary", fontSize: 14, whiteSpace: "pre-wrap" }}
        id="alert-dialog-description"
      >
        {title}
      </DialogContent>
      {copyToClipboard && (
        <Box sx={{ display: "flex", mx: 4 }}>
          <TextField variant="outlined" label={copyToClipboardTitle} value={copyToClipboard} sx={{ width: "300px", input: { fontSize: 12, color: fontColor.subTitleFontColor } }}></TextField>
          <IconButton onClick={() => navigator.clipboard.writeText(copyToClipboard)}><ContentCopyIcon /></IconButton>
        </Box>
      )}
      <DialogActions
        sx={{
          pb: 5,
          px: 6,
        }}
      >
        <Button
          variant="outlined"
          sx={{
            fontWeight: Fonts.MEDIUM,
          }}
          onClick={onConfirm}
          color="primary"
          autoFocus
        >
          {confirmButtonTitle}
        </Button>
        {!disableDenyButton &&
          <Button
            variant="outlined"
            sx={{
              fontWeight: Fonts.MEDIUM,
            }}
            onClick={() => onDeny && onDeny(false)}
            color="secondary"
          >
            {denyButtonTitle}
          </Button>
        }
      </DialogActions>
      </>}
    </Dialog>
  );
};

export default AppConfirmDialog;
