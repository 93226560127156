import { ContentRevision } from "../types/models/ContentRevision";
import { contentRevisionDB } from "./RuleCacheDB";

export const getContentRevisionCache = async (contentRevisionId: string) => {

    try {
        return await contentRevisionDB.ContentRevisions.get(contentRevisionId);
    }
    catch (e) {
        if (e instanceof Error) {
            console.log(e.message);
        }
    }
}

export const setContentRevisionCache = async (contentRevision: ContentRevision) => {
    try {
        await contentRevisionDB.ContentRevisions.put(contentRevision, contentRevision.ContentRevisionId);
    }
    catch (e) {
        if (e instanceof Error) {
            console.log(e.message);
        }
    }
}

export const clearOldContentRevisionCaches = async (activeContentIds: string[]) => {
    try {
        await contentRevisionDB.deleteInactiveContents(activeContentIds);
    }
    catch (e) {
        if (e instanceof Error) {
            console.log(e.message);
        }
    }
}
