export const NAV_COLLAPSED = "NAV_COLLAPSED";
export const TOGGLE_NAV_COLLAPSED = "TOGGLE_NAV_COLLAPSED";
export const SET_INITIAL_PATH = "SET_INITIAL_PATH";
export const ROUTE_CHANGE = "@@router/LOCATION_CHANGE";
export const RIS_DEPENDENCY_ERROR = "RIS_DEPENDENCY_ERROR";
export const OTHER_PROVIDER_ERROR = "OTHER_PROVIDER_ERROR";
export const FORGOT_PASSWORD_TOKEN = "FORGOT_PASSWORD_TOKEN";

export interface ToggleNavCollapsedAction {
  type: typeof TOGGLE_NAV_COLLAPSED;
}
export interface NavCollapsedAction {
  type: typeof NAV_COLLAPSED;
}

export interface SetInitialPathAction {
  type: typeof SET_INITIAL_PATH;
  initialPath: string | undefined;
}

export interface RouteChangeAction {
  type: typeof ROUTE_CHANGE;
}

export interface RISDependencyErrorAction {
  type: typeof RIS_DEPENDENCY_ERROR;
  payload: string;
}

export interface OtherProviderErrorAction {
  type: typeof OTHER_PROVIDER_ERROR;
  payload: string;
}

export interface ForgotPasswordTokenAction {
  type: typeof FORGOT_PASSWORD_TOKEN;
  payload: boolean;
}

export type SettingsActionTypes =
  | ToggleNavCollapsedAction
  | SetInitialPathAction
  | NavCollapsedAction
  | RouteChangeAction
  | RISDependencyErrorAction
  | OtherProviderErrorAction
  | ForgotPasswordTokenAction;
