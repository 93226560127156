import { Dispatch } from "react";
import axios from "axios";
import jwtAxios from "../../auth/jwtAxios";
import contentAxios from "../../auth/contentAxios";
import { AppActions } from "../../types";
import {
  GET_CONTENT_LIST,
  GET_CONTENT_BY_ID,
  SELECT_RULE,
  TOGGLE_RULE_MODAL,
  DOWNLOAD_HTML,
  SEARCH_TEXT,
  ACTIVE_AUDIO_INDEX,
  POST_ACKNOWLEDGED,
  SELECT_ROLES,
  SET_ENABLE_FILTER_BY_ROLES,
  SELECT_LOCATIONS,
  GET_MY_ACKNOWLEDGEMENT,
  HISTORY_SEARCH_TEXT,
  CLEAR_ALL_DATA,
  CHECK_CONTENT_UPDATE,
  RuleUnitActions,
  GET_MY_FAVOURITES,
  UPDATE_FAVOURITE_RULE
} from "../../types/actions/RuleUnit.actions";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import { RuleInstance } from "../../types/models/RuleInstance";
import { ContentRevision } from "../../types/models/ContentRevision";
import { PostAcknowledgedRequest } from "../../types/models/PostAcknowledgedRequest";
import { PostUpdateFavouriteRequest } from "../../types/models/PostUpdateFavouriteRequest";
import { getSortedRules } from "../../utils/RuleUtil";
import { FavouriteRule } from "../../types/models/FavouriteRule";
import { clearOldContentRevisionCaches, getContentRevisionCache, setContentRevisionCache } from "../../utils/RuleCacheHelper";
import { getDisplayErrorMessage } from "../../utils/StringUtils";


export const getContentList = (callBack?: () => void) => {

  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("api/ContentForUser")
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          const contents: ContentRevision[] = data.data.Values;
          const shieldId: string = data.data.ShieldId;

          const activeContentIds = contents.map(content => content.ContentRevisionId);
          clearOldContentRevisionCaches(activeContentIds);

          dispatch({ type: GET_CONTENT_LIST, payload: contents, shieldId: shieldId });
        } else {
          dispatch(fetchError("Something went wrong, Please try again! Status:" + data.status));
        }
        if (callBack) callBack();
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        if (callBack) callBack();
      });
  };
};

export const getContentById = (contentId: string, callBack: null | ((content: ContentRevision | undefined) => void)) => {

  return async (dispatch: Dispatch<AppActions>) => {
    const cachedContent = await getContentRevisionCache(contentId);
    if (cachedContent) {
      cachedContent.fromCache = true;
      dispatch({ type: GET_CONTENT_BY_ID, payload: cachedContent });
      if (callBack) callBack(cachedContent);
    } else {
      dispatch(fetchStart());
      jwtAxios
        .get("api/ContentForId?id=" + contentId)
        .then((data) => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            // sorting
            const content: ContentRevision = data.data;
            content.fromCache = false;
            content.RuleInstances = getSortedRules(content.RuleInstances ?? []);

            dispatch({ type: GET_CONTENT_BY_ID, payload: content });
            setContentRevisionCache(content);
            if (callBack) callBack(content);
          } else {
            dispatch(fetchError("Something went wrong, Please try again! Status:" + data.status));
            if (callBack) callBack(undefined);
          }
        })
        .catch((error) => {
          if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        });
    }
  };
};

export const postAcknowledgeStatus = (request: PostAcknowledgedRequest, callBack: undefined | ((result: boolean) => void)) => {

  return (dispatch: Dispatch<AppActions>) => {

    jwtAxios
      .post("api/AcknowledgeStatus", request)
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: POST_ACKNOWLEDGED, payload: request });
          if (callBack) callBack(true);

        } else {
          dispatch(fetchError("Something went wrong, Please try again! Status:" + data.status));
          if (callBack) callBack(false);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        if (callBack) callBack(false);
      });
  };
};

export const selectRule = (selectedRule: RuleInstance | undefined) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SELECT_RULE, payload: selectedRule })
  };
};

export const toggleRuleModal = (payload: boolean) => {
  return (dispatch: Dispatch<RuleUnitActions>) => {
    dispatch({ type: TOGGLE_RULE_MODAL, payload })
  };
};

export const downloadHtml = (contentId: string, fileName: string, callBack: ((result: string | null) => void)) => {

  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    contentAxios
      .get("content/" + contentId + "/" + fileName, { responseType: "blob" })
      .then(response => {
        dispatch(fetchSuccess());
        const reader = new FileReader();
        reader.readAsText(response.data);
        reader.onload = function (e) {
          const html = e.target?.result?.toString() ?? "";
          dispatch({ type: DOWNLOAD_HTML, fileName: fileName, payload: html });
          callBack(html);
        };
      })
      .catch(error => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        callBack(null);
      });
  };
};

export const onSearchText = (text: string) => {

  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SEARCH_TEXT, payload: text })
  };
};

export const setActiveAudioIndex = (index: number | null) => {

  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: ACTIVE_AUDIO_INDEX, payload: index })
  };
};

export const setSelectedUserRoles = (roles: string[]) => {

  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SELECT_ROLES, payload: roles })
  };
};

export const setSelectedUserLocations = (locations: string[]) => {

  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SELECT_LOCATIONS, payload: locations })
  };
};

export const setFilterByRoles = (enabled: boolean) => {

  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_ENABLE_FILTER_BY_ROLES, payload: enabled })
  };
};

export const getMyAcknowledgement = (callBack: (result: boolean) => void) => {

  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("api/MyAcknowledgements")
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_MY_ACKNOWLEDGEMENT, payload: data.data.Values });
          callBack(true);
        } else {
          dispatch(fetchError("Something went wrong, Please try again! Status:" + data.status));
          callBack(false);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        callBack(false);
      });
  };
};

export const onHistorySearchText = (text: string) => {

  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: HISTORY_SEARCH_TEXT, payload: text })
  };
};

export const clearAllState = () => {

  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: CLEAR_ALL_DATA, payload: null })
  };
};

export const checkContentUpdate = () => {

  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .get("api/ContentForUser")
      .then((data) => {
        if (data.status === 200) {
          const contents: ContentRevision[] = data.data.Values;

          dispatch({ type: CHECK_CONTENT_UPDATE, payload: contents });
        } else {
          dispatch(fetchError("Something went wrong, Please try again! Status:" + data.status));
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
      });
  };
};

export const getFavouriteRules = (callBack: (result: boolean) => void) => {

  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .get("api/MyFavourites")
      .then((data) => {
        if (data.status === 200) {
          const myFavourites: FavouriteRule[] = data.data.Favourites;
          dispatch({ type: GET_MY_FAVOURITES, payload: myFavourites });
          callBack(true);
        } else {
          dispatch(fetchError("Something went wrong, Please try again! Status:" + data.status));
          callBack(false);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        callBack(false);
      });
  };
};

export const updateFavouriteRule = (ruleId: string, action: string) => {

  const request: PostUpdateFavouriteRequest = {
    RuleId: ruleId,
    Action: action
  };

  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .post("api/Favourite", request)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: UPDATE_FAVOURITE_RULE, payload: request });
        } else {
          dispatch(fetchError("Something went wrong, Please try again! Status:" + data.status));
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
      });
  };
};