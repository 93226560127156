import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Divider, FormControl, List, ListItem, ListItemText, ListSubheader, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import { useMsal } from "@azure/msal-react";
import { setSelectedUserRoles } from "../../../redux/actions/RuleUnit";
import { fontColor } from "../../../constants/ColorSets";
import { UserRole } from "../../../types/models/UserRole";
import { AppState } from "../../../redux/store";
import { RoleState } from "../../../types/models/RoleState";
import { getMyRoles, postUpdateRoleSubscription } from "../../../redux/actions/Role";
import { PostUpdateSubscriptionsRequest } from "../../../types/models/PostUpdateSubscriptionsRequest";
import { MyRole } from "../../../types/models/MyRole";
import EmptyResult from "../EmptyResult";

const RoleFilter = () => {
    const dispatch = useDispatch();
    const { accounts } = useMsal();

    const [activeRoleIds, setActiveRoleIds] = useState<string[] | undefined>(undefined);
    const [defaultRoleIds, setDefaultRoleIds] = useState<string[]>([]);
    const [availableRoleIds, setAvailableRoleIds] = useState<string[]>([]);
    const [isOpenRole, setIsOpenRole] = useState<boolean>(false);
    const [isRoleEmpty, setIsRoleEmpty] = useState<boolean>(false);

    const roleState = useSelector<AppState, RoleState>((state) => state.role);

    useEffect(() => {
        dispatch(getMyRoles((roles) => {
            if (roles) {
                initialiseRoles(roles.roles);
            }
            else {
                setIsRoleEmpty(true);
            }
        }));
    }, []);

    const getRoleById = (roleId: string) => {
        const knownRole = roleState.knownRoles?.roles.find(knownRole => knownRole.RoleId === roleId);
        return knownRole;
    }

    const getSelectedRoleElements = (selectedRoleIds: string[]) => {
        const elements: ReactNode[] = selectedRoleIds.map((roleId, index) =>
            <ListItem key={'selected' + "_" + roleId} sx={{ borderBottom: (index !== selectedRoleIds.length - 1) ? ("1px solid " + fontColor.grayBackground) : "none" }}>
                <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    {getRoleById(roleId)?.DisplayName}
                    {defaultRoleIds.some(userRole => userRole === roleId) && <LockPersonOutlinedIcon sx={{ color: fontColor.subTitleFontColor }} />}
                </Box>
            </ListItem>
        );

        return (
            <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                    <Typography sx={{ color: fontColor.titleFontColor, alignContent: "center" }}>{"Active Roles (" + selectedRoleIds.length + ")"}</Typography>
                    <Button sx={{ color: fontColor.orangeTitle }} onClick={() => setIsOpenRole(true)}>Edit</Button>
                </Box>
                <Box sx={{ border: "1px solid " + fontColor.grayBackground }}>
                    {selectedRoleIds?.length > 0 ? (
                        <List>
                            {elements}
                        </List>
                    ) : (
                        <Box sx={{
                            display: "grid",
                            background: fontColor.ruleSearchEmptyResultBackground,
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            width: "100%",
                            height: "100%"
                        }}>
                            <Box sx={{ margin: 1, color: "grey" }}>No roles selected</Box>
                        </Box>
                    )}
                </Box>
            </Box>
        );
    }

    const updateRolesSelection = (selectedRoleIds: string[]) => {
        const otherRoleIds: string[] = [];
        roleState.knownRoles?.roles.forEach(role => {
            if (!selectedRoleIds.some(defaultRoleId => defaultRoleId === role.RoleId))
                otherRoleIds.push(role.RoleId);
        });

        if (!activeRoleIds) {
            // initial loading
            setActiveRoleIds(selectedRoleIds);
            setAvailableRoleIds(otherRoleIds);
            dispatch(setSelectedUserRoles(selectedRoleIds));
        }
        else {
            // selection
            const requests: PostUpdateSubscriptionsRequest[] = [];
            const addedRoleIds = selectedRoleIds.filter(id => !activeRoleIds.includes(id));
            addedRoleIds.forEach(addedId => {
                requests.push({
                    RoleId: addedId,
                    Action: "Subscribe"
                });
            });
            const removedRoleIds = activeRoleIds.filter(id => !selectedRoleIds.includes(id));
            removedRoleIds.forEach(removedId => {
                requests.push({
                    RoleId: removedId,
                    Action: "Unsubscribe"
                });
            });

            Promise
                .allSettled(requests.map(request => dispatch(postUpdateRoleSubscription(request))))
                .then(() => {
                    setActiveRoleIds(selectedRoleIds);
                    setAvailableRoleIds(otherRoleIds);
                    dispatch(setSelectedUserRoles(selectedRoleIds));
                });
        }
    }

    const initialiseRoles = (myRoles: MyRole[]) => {

        let defaultIds: string[] = [];

        const currentAccount = accounts[0];
        if (currentAccount && currentAccount.idTokenClaims) {
            const roleJsonString = currentAccount.idTokenClaims['extension_Roles'] as string;
            if (roleJsonString) {
                const userRole: UserRole = JSON.parse(roleJsonString);
                defaultIds = userRole?.Roles ?? [];
            }
        }
        defaultIds = defaultIds.sort();
        setDefaultRoleIds(defaultIds);

        const knownRoles = roleState.knownRoles?.roles ?? [];
        const initialSelectedRoleIds: string[] = [];
        defaultIds.forEach(defaultRoleId => {
            const role = knownRoles && knownRoles.find(role => role.RoleId === defaultRoleId);
            if (role) {
                initialSelectedRoleIds.push(role.RoleId);
            }
        });

        myRoles.forEach(myRole => {
            const role = knownRoles.find(role => role.RoleId === myRole.RoleId);
            if (role && !initialSelectedRoleIds.some(initialRoleId => initialRoleId === role?.RoleId)) {
                initialSelectedRoleIds.push(role.RoleId);
            }
        });

        updateRolesSelection(initialSelectedRoleIds);
    }

    const getMenus = (roleIds: string[]) => {
        const elements: ReactNode[] = [];

        roleIds.forEach(roleId => {
            const role = getRoleById(roleId);
            if (!role) return;

            // Determine if the user can opt out of the role
            const optOutLocked = roleState.myRoles?.find(myRole => myRole.RoleId === roleId && myRole.OptIn !== true) !== undefined;

            elements.push(
                <MenuItem sx={{ margin: 1 }} key={roleId} value={roleId} disabled={optOutLocked}>
                    <ListItemText primary={role.DisplayName} />
                    {(defaultRoleIds.some(userRole => userRole === roleId) || optOutLocked) && <LockPersonOutlinedIcon sx={{ color: fontColor.subTitleFontColor }} />}
                </MenuItem>
            );
        });
        return elements;
    }

    return (
        <Box>
            {isRoleEmpty ?
                <EmptyResult>No roles available.</EmptyResult>
                : (
                    <FormControl sx={{ m: 0.5, width: "100%" }} size="medium" >
                        <Select
                            displayEmpty
                            open={isOpenRole}
                            onClose={() => setIsOpenRole(false)}
                            multiple={true}
                            variant="standard"
                            value={activeRoleIds ?? []}
                            defaultValue={activeRoleIds ?? []}
                            MenuProps={{ disablePortal: true }}
                            id="grouped-select"
                            label="Roles"
                            sx={{
                                boxShadow: "none",
                                borderRadius: 2,
                                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                    border: 0,
                                },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                    border: 0,
                                },
                                '.MuiSelect-icon': {
                                    display: "none"
                                },
                            }}
                            onChange={selectedOption => {
                                let selectedRoleIds = typeof selectedOption.target.value === 'string' ? selectedOption.target.value.split(',') : selectedOption.target.value;
                                const newActiveRoleIds: string[] = [];
                                defaultRoleIds.forEach(defaultRoleId => {
                                    if (selectedRoleIds.indexOf(defaultRoleId) === -1) {
                                        // can not un-check the user's role
                                        newActiveRoleIds.push(defaultRoleId);
                                    }
                                })
                                selectedRoleIds = selectedRoleIds.sort((a: string, b: string) => {
                                    if (defaultRoleIds.some(def => def === a))
                                        return -1;
                                    else if (defaultRoleIds.some(def => def === b))
                                        return 1;
                                    else {
                                        return a > b ? 1 : -1;
                                    }
                                });
                                updateRolesSelection(newActiveRoleIds.concat(selectedRoleIds));
                            }}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                                getSelectedRoleElements(selected ?? [])
                            )}
                        >
                            <ListSubheader>Active</ListSubheader>
                            {getMenus(activeRoleIds ?? [])}
                            <Divider />
                            <ListSubheader>Available</ListSubheader>
                            {getMenus(availableRoleIds)}
                        </Select>
                    </FormControl>
                )}
        </Box>
    )
}

export default RoleFilter;