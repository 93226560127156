import { ContentRevision } from "../models/ContentRevision";
import { PostAcknowledgedRequest } from "../models/PostAcknowledgedRequest";
import { PostFeedbackRequest } from "../models/PostFeedbackRequest";
import { PostUpdateFavouriteRequest } from "../models/PostUpdateFavouriteRequest";
import { RuleInstance } from "../models/RuleInstance";
import { FavouriteRule } from "../models/FavouriteRule"

export const GET_CONTENT_LIST = "GET_CONTENT_LIST";
export const GET_CONTENT_BY_ID = "GET_CONTENT_BY_ID";
export const SELECT_RULE = "SELECT_RULE";
export const TOGGLE_RULE_MODAL = "TOGGLE_RULE_MODAL";
export const DOWNLOAD_HTML = "DOWNLOAD_HTML";
export const SEARCH_TEXT = "SEARCH_TEXT";
export const ACTIVE_AUDIO_INDEX = "ACTIVE_AUDIO_INDEX";
export const POST_ACKNOWLEDGED = "POST_ACKNOWLEDGED";
export const SEND_FEEDBACK = "SEND_FEEDBACK";
export const SELECT_ROLES = "SELECT_ROLES";
export const SELECT_LOCATIONS = "SELECT_LOCATIONS";
export const SET_ENABLE_FILTER_BY_ROLES = "SET_ENABLE_FILTER_BY_ROLES";
export const GET_MY_ACKNOWLEDGEMENT = "GET_MY_ACKNOWLEDGEMENT";
export const HISTORY_SEARCH_TEXT = "HISTORY_SEARCH_TEXT";
export const CLEAR_ALL_DATA = "CLEAR_ALL_DATA";
export const CHECK_CONTENT_UPDATE = "CHECK_CONTENT_UPDATE";
export const GET_MY_FAVOURITES = "GET_MY_FAVOURITES";
export const UPDATE_FAVOURITE_RULE = "UPDATE_FAVOURITE_RULE";

export interface GetContentList {
  type: typeof GET_CONTENT_LIST;
  payload: Array<ContentRevision> | undefined;
  shieldId: string;
}

export interface GetContentById {
  type: typeof GET_CONTENT_BY_ID;
  payload: ContentRevision | undefined;
}

export interface SelectRule {
  type: typeof SELECT_RULE;
  payload: RuleInstance | undefined;
}

export interface ShowRuleModal {
  type: typeof TOGGLE_RULE_MODAL;
  payload: boolean;
}

export interface DownloadHtml {
  type: typeof DOWNLOAD_HTML;
  payload: string;
  fileName: string;
}

export interface SearchText {
  type: typeof SEARCH_TEXT;
  payload: string;
}

export interface ActiveAudioIndex {
  type: typeof ACTIVE_AUDIO_INDEX;
  payload: number | null;
}

export interface PostAcknowledged {
  type: typeof POST_ACKNOWLEDGED;
  payload: PostAcknowledgedRequest;
}

export interface SendFeedback {
  type: typeof SEND_FEEDBACK;
  payload: PostFeedbackRequest;
}

export interface SelectRoles {
  type: typeof SELECT_ROLES;
  payload: string[];
}

export interface SelectLocations {
  type: typeof SELECT_LOCATIONS;
  payload: string[];
}

export interface EnableFilterByRoles {
  type: typeof SET_ENABLE_FILTER_BY_ROLES;
  payload: boolean;
}

export interface GetMyAcknowledgements {
  type: typeof GET_MY_ACKNOWLEDGEMENT;
  payload: string[] | undefined;
}

export interface HistorySearchText {
  type: typeof HISTORY_SEARCH_TEXT;
  payload: string;
}

export interface ClearAllData {
  type: typeof CLEAR_ALL_DATA;
  payload: null;
}

export interface CheckContentUpdate {
  type: typeof CHECK_CONTENT_UPDATE;
  payload: Array<ContentRevision> | undefined;
}

export interface GetMyFavourites {
  type: typeof GET_MY_FAVOURITES;
  payload: Array<FavouriteRule> | undefined;
}

export interface UpdateFavouriteRule {
  type: typeof UPDATE_FAVOURITE_RULE;
  payload: PostUpdateFavouriteRequest;
}

export type RuleUnitActions =
  | GetContentList
  | GetContentById
  | SelectRule
  | ShowRuleModal
  | DownloadHtml
  | SearchText
  | ActiveAudioIndex
  | PostAcknowledged
  | SendFeedback
  | SelectRoles
  | SelectLocations
  | EnableFilterByRoles
  | GetMyAcknowledgements
  | HistorySearchText
  | ClearAllData
  | CheckContentUpdate
  | GetMyFavourites
  | UpdateFavouriteRule;
