import { Box } from "@mui/material";

type RuleEmptyResultProps = React.PropsWithChildren;

const RuleEmptyResult = (props: RuleEmptyResultProps) => {
    const { children } = props;

    return (
        <Box sx={{
            display: "grid",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            height: "100%",
            padding: 2
        }}>
            <Box sx={{ color: "grey" }}>{children}</Box>
        </Box>
    );
};

export default RuleEmptyResult;
