import { Dispatch } from "react";
import axios from "axios";
import jwtAxios from "../../auth/jwtAxios";
import { AppActions } from "../../types";
import { TeamMemberProgress } from "../../types/models/TeamMember";
import { GET_TEAM_MEMBERS, GET_USERS, GET_ORG_TEAMS, SEARCH_ORG_TEAMS, GET_MY_TEAMS, GET_PROGRESS_OF_TEAM } from "../../types/actions/TeamMembers.actions";
import { fetchStart, fetchSuccess, fetchError } from "./Common";
import { VirtualTeam } from "../../types/models/Team";
import { RISUser } from "../../types/models/RISUser";
import { MakeTeamRequest } from "../../types/models/MakeTeamRequest";
import { MakeTeamResponse } from "../../types/models/MakeTeamResponse";
import { UpdateTeamRequest } from "../../types/models/UpdateTeamRequest";
import { TeamsSearchResult } from "../../types/models/TeamsSearchResult";
import { TeamProgressModel } from "../../types/models/TeamProgressModel";
import { getDisplayErrorMessage, splitSearchText } from "../../utils/StringUtils";

export const getOrgProgress = (callBack?: (teamMembers: TeamMemberProgress[] | undefined) => void) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("api/TeamProgress")
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          const teamMembers: TeamMemberProgress[] = data.data.Values;
          dispatch({ type: GET_TEAM_MEMBERS, payload: teamMembers });
          callBack && callBack(teamMembers);
        } else {
          dispatch(fetchError("Something went wrong, Please try again! Status:" + data.status));
          callBack && callBack(undefined);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        callBack && callBack(undefined);
      });
  };
};

export const getTeamProgress = (teamId: string, callBack?: (progress: TeamMemberProgress[] | undefined) => void) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`api/ProgressOfTeam?teamId=${teamId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          const progress: TeamMemberProgress[] = data.data.Values;
          const result: TeamProgressModel = { TeamId: teamId, Progress: progress };
          dispatch({ type: GET_PROGRESS_OF_TEAM, payload: result });
          callBack && callBack(progress);
        } else {
          dispatch(fetchError("There was a problem loading the team. Please try again or contact support."));
          callBack && callBack(undefined);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        callBack && callBack(undefined);
      });
  };
};

export const getOrgTeams = (callBack?: (result: boolean) => void) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .get("api/orgteams")
      .then((data) => {
        if (data.status === 200) {
          const teams: VirtualTeam[] = data.data.Teams;
          dispatch({ type: GET_ORG_TEAMS, payload: teams });
          callBack && callBack(true);
        } else {
          dispatch(fetchError("Something went wrong, Please try again! Status:" + data.status));
          callBack && callBack(false);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        callBack && callBack(false);
      });
  };
};

export const getUsers = (callBack: (result: boolean) => void) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .get("api/mysubordinates")
      .then((data) => {
        if (data.status === 200) {
          const users: RISUser[] = data.data.Subordinates;
          dispatch({ type: GET_USERS, payload: users });
          callBack && callBack(true);
        } else {
          dispatch(fetchError("Something went wrong, Please try again! Status:" + data.status));
          callBack && callBack(false);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        callBack && callBack(false);
      });
  };
};

export const getPotentialTeamMembers = (callback?: (result: boolean) => void) => {
  return (dispatch: Dispatch<AppActions>) => {
      jwtAxios.get("/api/potentialteammembers")
      .then((data) => {
        if(data.status === 200) {
          const users: RISUser[] = data.data.Subordinates;
          dispatch({ type: GET_USERS, payload: users });
          callback && callback(true);
        }
        else {
          dispatch(fetchError(`Team member list is currently unavailable. Please check again later or contact support. Status Code: ${data.status}`));
          callback && callback(false);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        callback && callback(false);
      });
  }
}

export const makeTeam = (makeRequest: MakeTeamRequest, callBack: (teamId: string | undefined) => void) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .post("api/maketeam", makeRequest)
      .then((data) => {
        if (data.status === 200) {
          const response: MakeTeamResponse = data.data;
          callBack(response.TeamId);
        } else {
          dispatch(fetchError("Something went wrong, Please try again! Status:" + data.status));
          callBack(undefined);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        callBack(undefined);
      });
  };
};

export const updateTeam = (updateRequest: UpdateTeamRequest, callBack: (result: boolean) => void) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .post("api/updateteam", updateRequest)
      .then((data) => {
        if (data.status === 200) {
          callBack(true);
        } else {
          dispatch(fetchError("Something went wrong, Please try again! Status:" + data.status));
          callBack(false);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        callBack(false);
      });
  };
};

export const deleteTeam = (teamId: string, callBack: (result: boolean) => void) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .post("api/deleteteam", { TeamId: teamId })
      .then((data) => {
        if (data.status === 200) {
          callBack(true);
        } else {
          dispatch(fetchError("Something went wrong, Please try again! Status:" + data.status));
          callBack(false);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        callBack(false);
      });
  };
};

export const searchOrgTeams = (searchText: string, baseOrgTeams: VirtualTeam[]) => {
  return (dispatch: Dispatch<AppActions>) => {
    let result: TeamsSearchResult;
    if (searchText) {
      const searches = splitSearchText(searchText);
      const filteredOrgTeams = baseOrgTeams.filter(team => {
        let result = true;
        searches.forEach(search => {
          result = result && (
            team.DisplayName.toLowerCase().includes(search) ||
            team.Members.some(member => member.DisplayName.toLowerCase().includes(search) || member.Email.toLowerCase().includes(search))
          );
        });
        return result;
      });
      result = {
        SearchResults: filteredOrgTeams,
        SearchText: searchText.trim()
      };
    } else {
      result = {
        SearchResults: baseOrgTeams,
        SearchText: ""
      };
    }
    dispatch({ type: SEARCH_ORG_TEAMS, payload: result });
  };
};

export const getMyTeams = (callBack?: (result: boolean) => void) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .get("api/myteams")
      .then((data) => {
        if (data.status === 200) {
          const teams: VirtualTeam[] = data.data.Teams;
          dispatch({ type: GET_MY_TEAMS, payload: teams });
          callBack && callBack(true);
        } else {
          dispatch(fetchError("There was a problem loading your teams. Please try again or contact support."));
          callBack && callBack(false);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        callBack && callBack(false);
      });
  };
};
