import {
  RoleActions,
  GET_KNOWN_ROLES,
  GET_MY_ROLES
} from "../../types/actions/Role.actions";
import { KnownLocation } from "../../types/models/KnownLocation";
import { KnownRole } from "../../types/models/KnownRole";
import { RoleState } from "../../types/models/RoleState";
import { snakeToPascalCase, toSnakeCase } from "../../utils/RuleUtil";

const initialSettings: RoleState = {
  knownRoles: undefined,
  myRoles: undefined,
  myLocations: undefined,
  contentAuthor: undefined,
};

const RoleReducer = (state = initialSettings, action: RoleActions) => {
  const roles: {roles: KnownRole[], locations: KnownLocation[]} = {
    roles: [],
    locations: []
  };

  switch (action.type) {
    case GET_KNOWN_ROLES:

      // Convert just in case (Remove this once make sure the id has an expected format)
      action.payload?.roles.filter((knownRole) => knownRole.Active).forEach(knownRole => {
        const newRoleId = toSnakeCase(knownRole.RoleId);

        if (!roles.roles.some(role => role.RoleId === newRoleId))
          roles.roles.push({
            Active: knownRole.Active,
            RoleId: newRoleId,
            DisplayName: snakeToPascalCase(knownRole.DisplayName)
          })
      });

      roles.locations = action.payload?.locations.map((location) => location) ?? [];

      return {
        ...state,
        knownRoles: roles,
      };
    case GET_MY_ROLES:
      return {
        ...state,
        myRoles: action.payload?.roles,
        myLocations: action.payload?.locations,
        contentAuthor: action.payload?.contentAuthor,
      };
    default:
      return state;
  }
};

export default RoleReducer;
