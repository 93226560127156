import { Box, Typography } from "@mui/material"
import { fontColor } from "../../../constants/ColorSets"
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

interface Props {
  count?: number;
}

export const AcknowledgeAlert = ({ count }: Props) => {
  return (
    <Box
      sx={{ 
          width: "fit-content", 
          display: "flex", 
          alignItems: "center", 
          px: 1.5, py: 0.5, 
          backgroundColor: fontColor.grayBackground, 
          borderRadius: "10px", color:  fontColor.redFontColor, 
      }}>
          <WarningAmberRoundedIcon fontSize="small" sx={{mr: 0.5}} />
          <Typography sx={{ mt: 0.2, fontWeight: "600", fontSize: 12 }}>{count === 1 ? `${count} overdue alert requires acknowledgement` : `${count} overdue alerts require acknowledgement`}</Typography>
  </Box>)
}