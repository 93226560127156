import React, { useCallback, useEffect, useState } from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { RuleInstance } from "../../../types/models/RuleInstance";
import { useDispatch, useSelector } from "react-redux";
import { selectRule, toggleRuleModal } from "../../../redux/actions/RuleUnit";
import { AppState } from "../../../redux/store";
import { RuleUnitState } from "../../../types/models/RuleUnitState";
import { fontColor } from "../../../constants/ColorSets";
import { Fonts } from "../../../constants/AppEnums";
import AppCard from "../../../components/AppCard";
import RuleInstanceList from "../ContentRevisionView/RuleInstanceList";
import EmptyResult from "../EmptyResult";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function FavouriteCard() {

    const dispatch = useDispatch();
    const theme = useTheme();
    const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
    const [showPinned, setShowPinned] = useState<boolean>(true);

    useEffect(() => {
        if(ruleUnitState.searchText && ruleUnitState.searchText.length > 0) {
            setShowPinned(false);
        }
        else {
            setShowPinned(true);
        }
    }, [ruleUnitState.searchText])

    const getParentNameForRule = (displayRule: RuleInstance) => {
        const contentRevision = (ruleUnitState.baseContentRevisionDetails ?? [])
            .concat(ruleUnitState.baseSupportDocumentContentRevisionDetails ?? [])
            .find(detail => detail.RuleInstances?.some(rule => rule.RuleInstanceId === displayRule.RuleInstanceId));
        if (contentRevision?.Taxonomy) {
            return contentRevision?.Taxonomy + " > " + contentRevision?.DisplayName;
        }
        else {
            return contentRevision?.DisplayName;
        }
    };

    const getDisplayFavourites = useCallback(() => {
        const onViewRuleDetail = (selectedRule: RuleInstance) => {
            dispatch(selectRule(selectedRule));
            dispatch(toggleRuleModal(true));
        };

        if (ruleUnitState.baseFavouriteRules && ruleUnitState.baseFavouriteRules.length > 0 && (ruleUnitState.favouriteRules?.length === 0 ?? true)) {
            // No search result exists
            return (<EmptyResult>No items matching your current search.</EmptyResult>)
        }
        else {
            // Display the pinned items
            return (
                <Box>
                    <RuleInstanceList
                        narrowRowSpace={true}
                        invisiblePinIcon={true}
                        ruleInstances={ruleUnitState.favouriteRules}
                        onViewRuleDetail={onViewRuleDetail}
                        secondaryTextFunction={getParentNameForRule}
                    />
                </Box>
            );
        }
    }, [ruleUnitState.baseFavouriteRules, ruleUnitState.favouriteRules]);

    return (
        <Box component="section" id="alerts">
            <AppCard
                contentStyle={{ px: 0 }}
                title={
                    <Box
                        display="flex"
                        gap={0.5}
                        p={0.5}
                        alignItems="center"
                        sx={{
                            color: theme.card.headerTextColor,
                            fontWeight: 600,
                            fontSize: 18,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            width: "100%",
                            margin: 0,
                            display: "flex",
                        }}
                    >
                        <PushPinOutlinedIcon />
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <Typography variant="h5" component="h3">Pinned</Typography>
                            {(ruleUnitState.searchText?.replace(/"/g, '').length ?? 0) > 1 && (
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography
                                    sx={{
                                        fontSize: 15,
                                        fontWeight: Fonts.REGULAR,
                                        marginRight: 1.5,
                                        color: theme.card.headerSubTextColor
                                    }}>
                                    {ruleUnitState.favouriteRules?.length ?? 0} results
                                </Typography>
                                {!showPinned ? (
                                    <IconButton onClick={() => setShowPinned(true)}>
                                        <KeyboardArrowDownIcon />
                                    </IconButton>) :                                 
                                    <IconButton onClick={() => setShowPinned(false)}>
                                        <KeyboardArrowUpIcon />
                                    </IconButton>}
                                </Box>
                            )}
                        </Box>
                    </Box>
                }
                headerStyle={{ backgroundColor: fontColor.grayBackground }}
            >
                <Box>
                    {!showPinned ? null : getDisplayFavourites()}
                </Box>
            </AppCard>
        </Box>
    );
}
