import { RuleAudioResponse } from "../models/RuleAudioState";

export const GET_AUDIO_FILE = "GET_AUDIO_FILE";
export const START_AUDIO = "START_AUDIO";
export const STOP_AUDIO = "STOP_AUDIO";
export const SET_AUTO_PLAY = "SET_AUTO_PLAY";
export const SET_IS_DOWNLOADING = "SET_IS_DOWNLOADING";
export const SET_CURRENT_INDEX = "SET_CURRENT_INDEX";
export const INITIALISE_AUDIO_STATE = "INITIALISE_AUDIO_STATE";

export interface GetAudioFile {
  type: typeof GET_AUDIO_FILE;
  payload: RuleAudioResponse;
}

export interface StartAudio {
  type: typeof START_AUDIO;
  payload: number;
}

export interface StopAudio {
  type: typeof STOP_AUDIO;
  payload: boolean;
}

export interface SetAutoPlay {
  type: typeof SET_AUTO_PLAY;
  payload: boolean;
}

export interface SetIsDownloading {
  type: typeof SET_IS_DOWNLOADING;
  payload: boolean;
}

export interface SetCurrentIndex {
  type: typeof SET_CURRENT_INDEX;
  payload: number;
}

export interface InitialiseAudioState {
  type: typeof INITIALISE_AUDIO_STATE;
  payload: string;
}

export type RuleAudioActions =
  | GetAudioFile
  | StartAudio
  | StopAudio
  | SetAutoPlay
  | SetIsDownloading
  | SetCurrentIndex
  | InitialiseAudioState;
