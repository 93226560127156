
import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Fonts } from "../constants/AppEnums";
import { fontColor } from '../constants/ColorSets';
import AppLogo from "./AppBase/AppLogo";

interface GenericErrorFallbackProp {
  error?: Error
}

const GenericErrorFallback: React.FC<GenericErrorFallbackProp> = ({ error }) => {

  const { instance, accounts } = useMsal();
  const [expandDetail, setExpandDetail] = useState<boolean>(false);

  const logout = () => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts[0].homeAccountId),
      postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI
    };
    instance.logoutRedirect(logoutRequest);
  }

  return (
    <Box
      sx={{
        width: "100%",
        py: { xl: 8 },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}>
      <Box
        sx={{
          margin: 8,
          backgroundColor: "black",
          width: "100%",
          height: "50px",
          maxWidth: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
        <AppLogo />
      </Box>
      <Box sx={{
        mb: { xs: 4, xl: 5 },
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <Box
          component="h3"
          sx={{
            mb: { xs: 3, xl: 4 },
            fontSize: { xs: 20, md: 24 },
            fontWeight: Fonts.MEDIUM,
          }}>
          Sorry, something went wrong
        </Box>
        <Box
          sx={{
            mb: { xs: 4, xl: 5 },
            color: fontColor.subTitleFontColor,
            fontSize: 16,
            fontWeight: Fonts.MEDIUM,
          }}>
          <Typography>Please try refreshing the page. If the issue persists, consider logging out and then logging back in to resolve it.</Typography>
        </Box>
        {error && (
          <Box sx={{ mb: 2, mr: 2 }}>
            <Button variant="text" size="small" sx={{ color: "primary.main" }} onClick={() => setExpandDetail(!expandDetail)}>
              {expandDetail ? <ExpandMoreIcon sx={{ fontSize: 28 }} /> : <ChevronRightIcon sx={{ fontSize: 28 }} />}
              <Box sx={{ mt: 0.25 }}>Error Detail</Box>
            </Button>
            {expandDetail && (
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <TextField
                  variant="outlined"
                  label={"Error"}
                  value={`${error.name}:\n${error.message}`}
                  multiline={true}
                  rows={5}
                  InputProps={{ style: { fontSize: 14 } }} // font size of input text
                  sx={{ width: "100%", minWidth: "500px", mt: 1, ml: 6 }} />
                <IconButton
                  sx={{ mt: 2 }}
                  onClick={() => navigator.clipboard.writeText(`${error.name}:\n${error.message}`)}>
                  <ContentCopyIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        )}
        <Button
          variant="contained"
          color="primary"
          sx={{
            fontWeight: Fonts.MEDIUM,
            fontSize: 16,
            width: "120px",
            color: "white",
            textTransform: "capitalize",
          }}
          onClick={() => logout()}>
          Logout
        </Button>
      </Box >
    </Box >
  );
};

export default GenericErrorFallback;
