export enum Fonts {
  LIGHT = "300",
  REGULAR = "400",
  MEDIUM = "500",
  SEMI_BOLD = "600",
  BOLD = "700",
}

export const RoleMap: Map<string, string> = new Map<string, string>([
  ["addressee", "Addressee"],
  ["area_infrastructure_manager", "Area Infrastructure Manager"],
  ["competent_traction_person", "Competent Traction Person"],
  ["competent_worker", "Competent Worker"],
  ["driver", "Driver"],
  ["ganger", "Ganger"],
  ["ganger_in_charge", "Ganger in Charge"],
  ["hand_signaller", "Hand Signaller"],
  ["line_manager", "Line Manager"],
  ["locomotive_engineer", "Locomotive Engineer"],
  ["manager_supervisor", "Manager/ Supervisor"],
  ["mobile_plant_controller", "Mobile Plant Controller"],
  ["mobile_plant_operator", "Mobile Plant Operator"],
  ["mtmu_operator", "MTMV Operator"],
  ["network_control_manager", "Network Control Manager"],
  ["observer", "Observer"],
  ["officer_in_charge", "Officer in Charge"],
  ["operations_support_representative", "Operations Support Representative"],
  ["operator", "Operator"],
  ["operator_in_charge", "Operator in Charge"],
  ["permit_issuer", "Permit Issuer"],
  ["person_in_charge", "Person in Charge"],
  ["person_responsible_for_movements", "Person Responsible for Movements"],
  ["pilot", "Pilot"],
  ["points_operator", "Points Operator"],
  ["protection_planner", "Protection Planner"],
  ["rail_incident_controller", "Rail Incident Controller"],
  ["rail_operator", "Rail Operator"],
  ["rail_personnel", "Rail Personnel"],
  ["rail_protection_officer", "Rail Protection Officer"],
  ["receiver", "Receiver"],
  ["rolling_stock_representative", "Rolling Stock Representative"],
  ["sender", "Sender"],
  ["signaller", "Signaller"],
  ["signals_maintenance_representative", "Signals Maintenance Representative"],
  ["site_protector", "Site Protector"],
  ["team_leader", "Team Leader"],
  ["team_supervisor", "Team Supervisor"],
  ["track_maintenance_representative", "Track Maintenance Representative"],
  ["track_vehicle_operator", "Track Vehicle Operator"],
  ["train_controller", "Train Controller"],
  ["train_crew", "Train Crew"],
  ["train_crew_manager", "Train Crew Manager"],
  ["train_manager", "Train Manager"],
  ["work_supervisor", "Work Supervisor"]
]);

export const Locations = [
  "L1 - Pukekohe - Otiria and Branches",
  "L2 - Te Rapa - Pukekohe ECMT and Branches",
  "L3 - Waikanae - Te Rapa MNPL SOL PN",
  "L4 - Wellington - Waikanae JVL Wlg Ju",
  "L5 - Middleton - Picton",
  "L6 - Midland and West Coast",
  "L7 - Lyttelton - Invercargill and Branches"
]

export enum AlertViewMode {
  Alert = "alert",
  History = "history"
}

export const SELECTED_LOCATION_STORAGE_KEY = "wise-track-selected-locations";

export const escalatedMessage = "This message has been escalated";