import React from 'react';
import { Box, styled } from '@mui/material';
import { ChatMessage } from '../../types/models/ChatMessage';
import { theme } from '../../themes/theme';
import { EscalateIcon } from '../AppBase/EscalateIcon';
import { escalatedMessage } from '../../constants/AppEnums';

interface MessageItemProp {
    message: ChatMessage,
    senderId: string,
    isLast: boolean,
    hideSenderAndTime: boolean,
    displayDateTime?: boolean,
    smallFontSize?: boolean
}

const MessageItem: React.FC<MessageItemProp> = (prop) => {

    // Regular expression that matches emojis unicode characters
    const isEscalated = prop.message.Message === escalatedMessage;
    const reEmoji = new RegExp(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g);
    const isSenderMessage = prop.senderId === prop.message.UserId;
    const MessageChat = styled('div')(() => {
        return {
            display: 'inline-flex',
            border: isEmojiOnly ? 'none' : `solid 1px ${theme.palette.grey[200]}`,
            position: 'relative',
            whiteSpace: "pre-wrap",
            fontSize: isEmojiOnly ? 36 : 15,
            padding: isEmojiOnly ? '0 8px' : '8px 8px',
            flexDirection: 'row-reverse',
            textAlign: 'left',
            backgroundColor: isEmojiOnly ? 'none' : (isSenderMessage ? theme.palette.primary.main : "gray"),
            color: 'white',
            borderRadius: 8,
            borderBottomRightRadius: isSenderMessage ? 0 : 8,
            borderBottomLeftRadius: isSenderMessage ? 8 : 0,
        };
    });

    const dateTimeFormatter = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' });
    const timeOnlyFormatter = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit' });

    const getDateString = () => {
        try {
            const date = new Date(prop.message.SentAt);
            if (date.getDate() === new Date().getDate() || !prop.displayDateTime) {
                return timeOnlyFormatter.format(date);
            } else {
                return dateTimeFormatter.format(date);
            }
        }
        catch {
            return "";
        }
    };

    const getSenderName = () => {
        if (prop.senderId === prop.message.UserId) {
            return "";
        }
        else {
            return prop.message.UserDisplayName;
        }
    };

    const getIsEmojiOnly = (text: string) => {
        const finds = text.match(reEmoji);
        if (finds?.length === 1) {
            const noEmojiText = text.replaceAll(reEmoji, "");
            return (noEmojiText.length === 0);
        }
        return false;
    }

    const isEmojiOnly = getIsEmojiOnly(prop.message.Message);

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                px: 0,
                width: "100%",
                justifyContent: prop.senderId === prop.message.UserId ? 'flex-end' : 'flex-start',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    width: isEscalated ? "100%": "auto"
                }}
                className="message-chat-item"
            >
                {!isEscalated ? (
                <Box
                    sx={{
                        ml: 0,
                        textAlign: isSenderMessage ? 'right' : 'left',
                        fontSize: 12,
                        color: (theme) => theme.palette.text.secondary,
                        display: 'block',
                        mt: 0,
                    }}
                    component="span"
                    className="message-time"
                >
                    {getSenderName() + (!prop.hideSenderAndTime ? ("  " + getDateString()) : "")}
                </Box>
            ) : null}
                <Box
                >
                    {isEscalated ? 
                        <EscalateMessage /> 
                        : 
                        <MessageChat sx={{ fontSize: prop.smallFontSize ? 13 : 16 }}>{prop.message.Message}</MessageChat>
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default MessageItem;

function EscalateMessage() {
    return (
        <Box sx={{
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            border: `solid 2px ${theme.palette.primary.main}`,
            fontSize: 15,
            padding: '4px 4px',
            flexDirection: 'row',
            textAlign: 'left',
            color: `${theme.palette.primary.main}`,
            borderRadius: 2,
            fontWeight: 600,
            px: 4
        }}>
            <Box sx={{
                mt: 1,
                mr: 1
            }}><EscalateIcon color='#ff7606' /></Box> <span>This feedback has been escalated</span>
        </Box>
    )
}