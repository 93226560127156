import React from "react";
import ContentLoader from "react-content-loader";

export const SkeletonRuleItem = () => (
  <ContentLoader height="44px" viewBox="0 0 300 25">
    <rect height="8" width="120" x="10" y="10" />
    <rect height="8" width="60" x="145" y="10" />
  </ContentLoader>
);
const RuleInstanceListSkeleton = () => {
  return (
    <React.Fragment>
      <SkeletonRuleItem />
      <SkeletonRuleItem />
    </React.Fragment>
  );
};

export default RuleInstanceListSkeleton;
