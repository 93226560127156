import { Box, Button, Dialog, DialogActions, DialogTitle, Snackbar, SnackbarContent, TextField, Typography } from "@mui/material";
import { fontColor } from "../../constants/ColorSets";
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { theme } from "../../themes/theme";
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { uploadPDF } from "../../redux/actions/RuleFile";

interface Props {
  getPDFs: () => void
  uploadModalOpen: boolean;
  setUploadModalOpen: (uploadModalOpen: boolean) => void;
}


export const UploadPDFModal = ({ getPDFs, uploadModalOpen, setUploadModalOpen }: Props) => {
  const dispatch = useDispatch();

  const [fileName, setFileName] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadMessage, setUploadMessage] = useState<{title: string, isError: boolean}>({title: "", isError: false});
  const [openMessageAlert, setOpenMessageAlert] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  const resetState = () => {
    setFileName("");
    setSelectedFile(null);
    setUploadModalOpen(false);
    setUploading(false);
  }

  const handleCloseModal = () => {
    setFileName("");
    setSelectedFile(null);
    setUploadMessage({title: "", isError: false});
    setOpenMessageAlert(false);
    setUploadModalOpen(false);
  }

  const handleFileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value);
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if(file.type === "application/pdf") {
      setSelectedFile(file);
      setUploadMessage({title: "", isError: false});
      setOpenMessageAlert(false);
    }
    else {
      setUploadMessage({title: "Files can only be of type PDF. Please check the file format and try again.", isError: true});
      setOpenMessageAlert(true);
    }
  }

  const handleDragFile = (e: React.DragEvent) => {
    e.preventDefault();
  }

  const handleDropFile = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];
    if(file.name.includes(".pdf")) {
      setSelectedFile(file);
      setUploadMessage({ title: "", isError: false });
      setOpenMessageAlert(false);
    }
    else {
      setUploadMessage({ title: "Files can only be of type PDF. Please check the file format and try again.", isError: true });
      setOpenMessageAlert(true);
    }
  }

  const handleUpload = () => {
    setUploading(true);
    if(selectedFile) {
        dispatch(uploadPDF(fileName, selectedFile, (success) => {
            if(success) {
                setOpenMessageAlert(true);
                setUploadMessage({ title: "PDF file uploaded successfully!", isError: false })
                setUploadModalOpen(false);
                setUploading(false);
                getPDFs();
            }
            else {
                setUploadMessage({ title: "Failed to upload PDF file. Please try again later.", isError: true });
                setUploading(false);
            }
        }))
    }
    resetState();
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
        open={openMessageAlert}
        autoHideDuration={6000}
        onClose={() => setOpenMessageAlert(false)}
      > 
        <SnackbarContent style={{
            backgroundColor: uploadMessage.isError ? theme.palette.error.main : theme.palette.success.main,
          }}
          message={<span>{uploadMessage.title}</span>}
        />
      </Snackbar>
      <Dialog fullWidth onClose={handleCloseModal} open={uploadModalOpen}>
        <DialogTitle sx={{mb: 5, p: 2, fontSize: 18, borderBottom: `${fontColor.grayBackground} solid 1px` }}>Upload PDF</DialogTitle>
          <TextField 
            sx={{mx: 2}} type="text" 
            id="fileName"
            label="File Name"
            InputLabelProps={{ shrink: true }}
            required 
            value={fileName} 
            onChange={handleFileNameChange}
            error={fileName === "error"}
            helperText={fileName === "error" ? "File name is required" : ""}
          />
          <Box 
            sx={{my: 5, mx: 3, py: 8, 
            display: "flex", 
            flexDirection: "column", 
            alignItems: "center", 
            justifyContent: "center", 
            borderRadius: "20px", 
            border: `dashed 1px gray`,
            cursor: "pointer"}}
            onDragOver={handleDragFile}
            onDrop={handleDropFile}
            >
              {!selectedFile ? 
                <label style={{display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer"}}>
                  <input type="file" accept="application/pdf" onChange={handleFileChange} className="upload-input" />
                  <UploadFileOutlinedIcon fontSize="large" />
                  <Typography>Select or drag PDF file</Typography>
                </label> 
                : 
                <label style={{display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer"}}>
                  <input type="file" accept="application/pdf" onChange={handleFileChange} className="upload-input" />
                  <CheckCircleOutlinedIcon fontSize="large" sx={{color: "#43a047"}} />
                  <Typography>{selectedFile.name}</Typography>
                </label>
              }
            </Box>
            <DialogActions sx={{my: 1}}>
              <Button
                sx={{
                  minWidth: 100,
                  textTransform: "none",
                  color: "white"
                }}
                disabled={!fileName || !selectedFile || uploading}
                variant="contained"
                onClick={handleUpload}
                >{
                  uploading ? <CircularProgress size={24} thickness={5} /> : "Upload PDF"
                }
              </Button>
              <Button 
                disabled={uploading}
                sx={{
                  minWidth: 100,
                  mx: 2,
                  textTransform: "none"
                }}
                color="primary"
                variant="outlined" onClick={handleCloseModal}>Cancel</Button>
            </DialogActions>
      </Dialog>
  </>
  )
}