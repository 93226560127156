import { Box } from "@mui/material";
import React from "react";

interface PlusMoreLabelProp {
    moreCount: number
}

const PlusMoreLabel: React.FC<PlusMoreLabelProp> = (prop) => {
    return (
        <Box
            sx={{
                borderRadius: 2,
                backgroundColor: "lightgray",
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 12,
                height: "20px",
                minWidth: "60px",
                ml: 1

            }}>{" +" + (prop.moreCount) + " More"}</Box>
    );
};

export default PlusMoreLabel;