import { Common } from "../../types/models/Common";
import {
  CommonActionTypes,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
} from "../../types/actions/Common.action";

const INIT_STATE: Common = {
  error: "",
  loading: false,
  message: ""
};

const CommonReducer = (
  state = INIT_STATE,
  action: CommonActionTypes
): Common => {
  switch (action.type) {
    case FETCH_START: {
      return {
        error: state.error,  // Keep error message to not override previous error
        message: "",
        loading: true
      };
    }
    case FETCH_SUCCESS: {
      return {
        error: state.error, // Keep error message to not override previous error
        message: "",
        loading: false,
      };
    }
    case SHOW_MESSAGE: {
      return {
        error: "",
        message: action.message,
        loading: false,
      };
    }
    case FETCH_ERROR: {
      return {
        loading: false,
        error: action.error,
        message: "",
      };
    }
    case HIDE_MESSAGE: {
      return {
        loading: false,
        error: "",
        message: "",
      };
    }
    default:
      return state;
  }
};
export default CommonReducer;
