import axios, { AxiosRequestConfig } from "axios";
import { msalInstance } from "../components/AppBase/AppAuthProvider";
import { IdTokenClaimModel } from "../types/models/IdTokenClaimModel";
import { UserRole } from "../types/models/UserRole";
import { setForgotPasswordFlag, setRISDependencyError } from "../redux/actions";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import store from "../redux/store";
import { OTHER_PROVIDER_ERROR } from "../types/actions/Settings.action";
import { AuthErrorModel } from "../types/models/AuthErrorModel";

const jwtAxios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

jwtAxios.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    if (config.headers) {
      config.headers['Content-Type'] = 'application/json';
    }

    const account = msalInstance.getAllAccounts()[0];
    if (account) {

      const accessTokenRequest = {
        scopes: [process.env.REACT_APP_API_SCOPE ?? ""],
        account: account,
      };

      try {
        //* The `acquireTokenSilent` retrieves the token from cache in the browser storage first,
        //* so we can call it every time before making an API call. 
        const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
        if (accessTokenResponse) {
          const accessToken = accessTokenResponse.accessToken;

          let rightToken = true;
          const idToken = accessTokenResponse.idTokenClaims as IdTokenClaimModel;

          if (idToken.isForgotPassword === true) {
            store.dispatch(setForgotPasswordFlag());
            return config;
          }

          // Claims check
          if (!idToken ||
            !idToken.extension_Roles ||
            !idToken.extension_DisplayName ||
            !idToken.extension_Email) {
            rightToken = false;
          }
          else {
            const userRole: UserRole = JSON.parse(idToken.extension_Roles ?? "");
            if (userRole.Roles === undefined) {
              rightToken = false;
            }
          }

          if (!rightToken) {
            // Open dialog and lead to logout
            store.dispatch(setRISDependencyError(accessToken));
            return config;
          }

          if (config.headers && accessToken) {
            config.headers['Authorization'] = 'Bearer ' + accessToken;
          }
        }
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          msalInstance.acquireTokenRedirect(accessTokenRequest);
        }
        console.log(error);
      }
    }
    else {
      // This will not happen
      console.log("[ERROR] An user does not login")
    }
    return config;

  },
  error => {
    Promise.reject(error)
  });

jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    console.error("Got an error on API request:");
    console.error(err.response);

    if (err.response && err.response.status === 401 && err.response.data) {
      try {
        // Error handling for "OtherProvider" error
        const errorBody: AuthErrorModel = err.response.data;
        if (errorBody.Status.startsWith("OtherProvider:")) {
          const statusStrings = errorBody.Status.split(":");
          // Pass the other provider UUID
          store.dispatch({ type: OTHER_PROVIDER_ERROR, payload: statusStrings[1] });
        }
      }
      catch (e) {
        console.log("Failed to analyse error response:");
        console.error(e);
      }
    }

    return Promise.reject(err);
  }
);

export default jwtAxios;
