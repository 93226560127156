import React, { ReactNode } from "react";
import { Box } from "@mui/material";

interface AppPhoneNumberProps {
  children: ReactNode;
}

const AppPhoneNumber: React.FC<AppPhoneNumberProps> = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#E3F2FD",
        borderRadius: 1,
        display: "flex",
        alignItems: "center",
        maxWidth: "500px",
        minHeight: "104px",
        width: "100%",
        py: 2,
        px: 1,
        "& .avatar-icon": {
          width: { xs: 40, md: 48 },
          minWidth: { xs: 40, md: 48 },
          height: { xs: 40, md: 48 },
          backgroundColor: (theme) => theme.palette.background.paper,
          color: (theme) => theme.palette.primary.main,
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mr: 1,
          "& svg": {
            fontSize: 20,
          },
        },
      }}
    >
      {children}
    </Box>
  );
};

export default AppPhoneNumber;
