/* eslint-disable no-case-declarations */
import { GET_FILE_LIST, SEARCH_FILE, RuleFileActions } from "../../types/actions/RuleFile.actions";
import { RuleFile } from "../../types/models/RuleFile";
import { RuleFileState } from "../../types/models/RuleFileState";
import { splitSearchText } from "../../utils/StringUtils";

const initialSettings: RuleFileState = {
  ruleFileList: undefined,
  searchText: undefined,
  filteredRuleFileList: undefined,
};

const filterByLastestCreatedAt = (list: RuleFile[] | undefined) => {
  if (!list) {
    return;
  }
  const newObj: { [key: string]: RuleFile } = {};
  list.filter((obj) => {
    const currentCreatedAt = new Date(obj.CreatedAt).getTime();
    const existingObj = newObj[obj.DisplayName];
    if (!existingObj || currentCreatedAt > new Date(existingObj.CreatedAt).getTime()) {
      newObj[obj.DisplayName] = obj;
    }
  });
  return Object.values(newObj);
}

const RuleFileReducer = (state = initialSettings, action: RuleFileActions) => {
  let filteredList = state.ruleFileList;

  switch (action.type) {
    case GET_FILE_LIST:
      const filteredRuleList = filterByLastestCreatedAt(action.payload);
      return {
        ...state,
        ruleFileList: filteredRuleList,
        filteredRuleFileList: filteredRuleList,
        searchFile: undefined,
      };
    case SEARCH_FILE:
      if (state.ruleFileList && action.payload && action.payload.length > 0) {
        const searches = splitSearchText(action.payload);
        filteredList = state.ruleFileList.filter(file => {
          let result = true;
          searches.forEach(search => {
            // Find files having all of search text (AND condition)
            result = result && file.DisplayName.toLowerCase().includes(search.toLowerCase());
          });
          return result;
        });
      }

      return {
        ...state,
        searchText: action.payload,
        filteredRuleFileList: filteredList
      };
    default:
      return state;
  }
};
export default RuleFileReducer;
