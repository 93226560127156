import { alpha } from "@mui/system/colorManipulator";

export const sidebarColor =
{
  sidebarBgColor: "#313541",
  sidebarHeaderColor: "#313541",
  sidebarTextColor: "#fff",
  sidebarMenuSelectedBgColor: alpha("#ff7e21", 0.8),
  sidebarMenuSelectedTextColor: "rgba(0, 0, 0, 0.87)",
};

export const fontColor =
{
  titleFontColor: "#4a4949",
  subTitleFontColor: "#828181",
  orangeTitle: "#ff7e21",
  orangeBackground: alpha("#ff7e21", 0.25),
  orangeHoverBackground: alpha("#ff7e21", 0.1),
  orangeHoverRipple: "#fd933ab3 !important",
  grayBackground: "#e8e8e8",
  footerVersion: "#555657",
  footerAccountText: "#c2c2c2",
  footerAccountBackground: "#545b70",
  roleAccessibleBorder: "#47fc5c",
  roleAccessibleBackground: "#ebfced",
  ruleBeforeAccessible: "#00d615",
  ruleBeforeInaccessible: "gray",
  ruleSearchEmptyResultBackground: "#f7f7f7",
  searchedTextBackground: "#e8bb49",
  blueHighlight: alpha("#0000FF", 0.15),
  redFontColor: "#ff2a25",
};