interface Props {
  color: string;
}

export const EscalateIcon = ({ color }: Props) => {
  return (
  <svg width="21px" height="21px" viewBox="0 0 188 183" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M7,181 L7,24.4 C7,19.615 8.70375,15.51875 12.11125,12.11125 C15.51875,8.70375 19.615,7 24.4,7 L163.6,7 C168.385,7 172.48125,8.70375 175.88875,12.11125 C179.29625,15.51875 181,19.615 181,24.4 L181,128.8 C181,133.585 179.29625,137.68125 175.88875,141.08875 C172.48125,144.49625 168.385,146.2 163.6,146.2 L41.8,146.2 L7,181 Z M34.990625,129.228571 L164,129.228571 L164,25 L25,25 L25,139 L34.990625,129.228571 Z M25.5,129 L25.5,25 L25.5,129 Z" id="Shape" fill={color} fillRule="nonzero"></path>
        <polygon id="Path" fill={color} fillRule="nonzero" points="118 84 118 62.4 66.4 114 58 105.6 109.6 54 88 54 88 42 130 42 130 84"></polygon>
    </g>
  </svg>)
}