import React from "react"
import "./loader.css";
import { Box, CircularProgress } from "@mui/material";
import { fontColor } from "../../../constants/ColorSets";

const AppLoader = () => {
  return (
    <Box className="app-loader">
      <CircularProgress size="3rem" sx={{ color: fontColor.orangeTitle }} />
    </Box>)
}


export default AppLoader;
