import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControlLabel, FormGroup, Skeleton, Switch, Typography, useTheme } from "@mui/material";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { RuleInstance } from "../../../types/models/RuleInstance";
import { ContentRevision } from "../../../types/models/ContentRevision";
import { AppState } from "../../../redux/store";
import { RuleUnitState } from "../../../types/models/RuleUnitState";
import { ChatState } from "../../../types/models/ChatState";
import { AlertViewMode } from "../../../constants/AppEnums";
import { getRuleInstanceMetadata } from "../../../utils/RuleUtil";
import AppCard from "../../../components/AppCard";
import EmptyResult from "../../rules/EmptyResult";
import MessageItem from "../../../components/Chat/MessageItem";
import RuleInstanceList from "../../rules/ContentRevisionView/RuleInstanceList";
import { PostAcknowledgedRequest } from "../../../types/models/PostAcknowledgedRequest";
import { postAcknowledgeStatus } from "../../../redux/actions";

interface AlertsCardProps {
    handleSelectAlert: (rule: RuleInstance, content: ContentRevision) => void;
    handleSelectViewMode: (viewMode: string) => void;
}

export default function AlertsCard(props: AlertsCardProps) {

    const dispatch = useDispatch();
    const theme = useTheme();
    const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
    const chatState = useSelector<AppState, ChatState>((state) => state.chat);

    const [displayRules, setDisplayRules] = useState<RuleInstance[]>();
    const [viewMode, setViewMode] = useState<string>(AlertViewMode.Alert);

    useEffect(() => {
        if (viewMode === AlertViewMode.Alert) {
            setDisplayRules(ruleUnitState.alertRules);
        } else {
            setDisplayRules(ruleUnitState.alertHistories);
        }

    }, [ruleUnitState.alertRules, ruleUnitState.alertHistories, viewMode])

    const getDisplayAlerts = () => {
        const handleSelectAlert = (selectedRule: RuleInstance) => {
            const contentRevision = ruleUnitState.baseAlertContentRevisionDetails?.find(detail =>
                detail.RuleInstances?.some(rule => rule.RuleInstanceId === selectedRule.RuleInstanceId)
            );
            if (contentRevision) {
                props.handleSelectAlert(selectedRule, contentRevision);
                const request: PostAcknowledgedRequest = {
                    AcknowledgementType: "read",
                    DeviceId: null,
                    RuleInstanceId: selectedRule.RuleInstanceId,
                    UsersTime: new Date(),
                }
                dispatch(postAcknowledgeStatus(request, undefined));
            }
        };

        if ((viewMode === AlertViewMode.Alert && (ruleUnitState.alertRules === undefined || ruleUnitState.baseAlertRules === undefined || ruleUnitState.roleFilteredAlertRules === undefined)) ||
            (viewMode === AlertViewMode.History && (ruleUnitState.baseAlertHistories === undefined || ruleUnitState.alertHistories === undefined))) {
            // The API is not done yet
            return (
                <Box p={2} display="flex" flexDirection="column" gap={4}>
                    <Skeleton variant="rounded" width="100%" height="1em" />
                    <Skeleton variant="rounded" width="100%" height="1em" />
                </Box>
            );
        }
        else if (
            (viewMode === AlertViewMode.Alert && ruleUnitState.alertRules?.length === 0 && ruleUnitState.roleFilteredAlertRules?.length === 0) ||
            (viewMode === AlertViewMode.History && ruleUnitState.alertHistories?.length === 0 && ruleUnitState.baseAlertHistories?.length === 0)) {
            // No alert exists
            return <EmptyResult>No data to display.</EmptyResult>;
        }
        else if (
            (viewMode === AlertViewMode.Alert && ruleUnitState.alertRules?.length === 0 && (ruleUnitState.roleFilteredAlertRules?.length ?? 0) > 0) ||
            (viewMode === AlertViewMode.History && ruleUnitState.alertHistories?.length === 0 && (ruleUnitState.baseAlertHistories?.length ?? 0) > 0)
        ) {
            // No search result exists
            return <EmptyResult>No items matching your current search.</EmptyResult>;
        } else {
            // Display the alerts
            return (
                <Box>
                    <RuleInstanceList
                        narrowRowSpace={true}
                        ruleInstances={displayRules}
                        onViewRuleDetail={(rule) => handleSelectAlert(rule)}
                        secondaryTextFunction={(rule: RuleInstance) => getRuleInstanceMetadata(rule.Metadata ?? "")?.annotations?.join(", ")}
                        footerElement={
                            (rule: RuleInstance) => {
                                const thread = chatState.chatThreads?.find(thread => thread.ruleInstanceId === rule.RuleInstanceId);
                                if (thread && thread.preview) {
                                    return <MessageItem
                                        smallFontSize={true}
                                        message={{ Message: thread.preview, MessageId: "", SentAt: "", UserDisplayName: "", UserId: "", RuleInstanceId: thread.ruleInstanceId }}
                                        hideSenderAndTime={false}
                                        isLast={true}
                                        senderId={ruleUnitState.userShieldId}
                                    />
                                }
                            }
                        }
                    />
                </Box>
            );
        }
    };

    return (
        <Box component="section" id="alerts">
            <AppCard
                contentStyle={{ px: 0 }}
                title={
                    <Box
                        display="flex"
                        gap={0.5}
                        p={0.5}
                        alignItems="center"
                        sx={{
                            color: theme.card.headerTextColor,
                            fontWeight: 600,
                            fontSize: 18,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            width: "100%",
                            margin: 0,
                            display: "flex",
                        }}
                    >
                        <NotificationsNoneIcon />
                        <Typography variant="h5" component="h3">Alerts</Typography>
                    </Box>
                }
                headerStyle={{ backgroundColor: theme.card.headerBgColor }}
                action={
                    <Box sx={{ marginTop: "4px", display: "flex", justifyContent: "center", alignContent: "center" }}>
                        <FormGroup>
                            <FormControlLabel
                                labelPlacement="end"
                                control={
                                    <Switch
                                        value={viewMode === AlertViewMode.History}
                                        onChange={(event) => {
                                            const newViewMode = event.target.checked ? AlertViewMode.History : AlertViewMode.Alert;
                                            setViewMode(newViewMode);
                                            props.handleSelectViewMode(newViewMode);
                                        }} />
                                }
                                label={<Typography sx={{ fontSize: 15 }}>History</Typography>} />
                        </FormGroup>
                    </Box>
                }>
                <Box>
                    {getDisplayAlerts()}
                </Box>
            </AppCard>
        </Box>
    );
}
