import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Hidden, IconButton, Typography } from "@mui/material";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { toggleNavCollapsed } from "../../redux/actions/Setting";
import { fontColor } from "../../constants/ColorSets";
import { theme } from "../../themes/theme";
import { getAuthUserInfoFromToken } from "../../components/AppBase/AppAuthProvider";
import { LogoutDialog } from "../../components/LogoutDialog";
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';

interface Props {
    setSettingsDialogOpen: (settingsDialogOpen: boolean) => void;
    setOpenRoleLocationModal: (openRoleLocationModal: boolean) => void
}

const UserProfileHeader: React.FC<Props> = ({setSettingsDialogOpen, setOpenRoleLocationModal}) => {
    const dispatch = useDispatch();
    const [isOpenLogoutDialog, setIsOpenLogoutDialog] = useState<boolean>(false);
    
    const userInfo = getAuthUserInfoFromToken();

    return (
        <Box sx={{ borderRadius: 0 }}>
            <Box sx={{
                width: "100%",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between"
            }}>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start"
                    }}>
                    <Hidden lgUp>
                        <IconButton
                            sx={{
                                color: "text.secondary",
                                marginLeft: 1,
                                padding: 0,
                            }}
                            edge="start"
                            className="menu-btn"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => dispatch(toggleNavCollapsed())}
                            size="large">
                            <MenuIcon
                                sx={{
                                    width: 35,
                                    height: 35,
                                }}
                            />
                        </IconButton>
                    </Hidden>
                    <Box sx={{ py: 1, ml: 2 }}>
                        <Typography
                            sx={{ color: theme.palette.text.secondary }}>
                            Profile
                        </Typography>

                        <Typography
                            variant="h3"
                            component="h3"
                            color={fontColor.titleFontColor}
                            style={{ fontSize: 22 }}>
                            {userInfo?.Name}
                        </Typography>
                    </Box>
                </Box>
                <Hidden smDown>
                <Box sx={{ mr: 2, width: "100%" }}>
                    <Box sx={{ mr: 0, mt: 2, display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            endIcon={<SettingsOutlinedIcon />}
                            variant="text"
                            onClick={() => setSettingsDialogOpen(true)}
                            sx={{ mr: 1, textTransform: "none", borderRadius: 1 }}>
                            <Box>
                                Settings
                            </Box>
                        </Button>
                        <Button
                            endIcon={<PersonPinCircleOutlinedIcon />}
                            variant="text"
                            onClick={() => setOpenRoleLocationModal(true)}
                            sx={{ mr: 1, textTransform: "none", borderRadius: 1 }}>
                            <Box>
                                Roles and Locations
                            </Box>
                        </Button>
                        <Button
                            endIcon={<LogoutOutlinedIcon />}
                            variant="text"
                            onClick={() => setIsOpenLogoutDialog(true)}
                            sx={{ mr: 1, textTransform: "none", borderRadius: 1 }}>
                            <Box>
                                Logout
                            </Box>
                        </Button>
                    </Box>
                </Box>
                </Hidden>
                <Hidden smUp>
                <Box sx={{ mr: 2, width: "100%" }}>
                    <Box sx={{ mr: 0, mt: 2, display: "flex", justifyContent: "flex-end" }}>
                        <IconButton
                            sx={{
                                color: fontColor.orangeTitle,
                                mx: 1,
                                padding: 0,
                            }}
                            edge="start"
                            className="menu-btn"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => setSettingsDialogOpen(true)}
                            size="small">
                            <SettingsOutlinedIcon
                                sx={{
                                    width: 35,
                                    height: 35,
                                }}
                            />
                        </IconButton>
                        <IconButton
                            sx={{
                                color: fontColor.orangeTitle,
                                mx: 1,
                                padding: 0,
                            }}
                            edge="start"
                            className="menu-btn"
                            color="inherit"
                            onClick={() => setOpenRoleLocationModal(true)}
                            size="small">
                            <PersonPinCircleOutlinedIcon
                                sx={{
                                    width: 35,
                                    height: 35,
                                }}
                            />
                        </IconButton>
                        <IconButton
                            sx={{
                                color: fontColor.orangeTitle,
                                mx: 1,
                                padding: 0,
                            }}
                            edge="start"
                            className="menu-btn"
                            color="inherit"
                            onClick={() => setIsOpenLogoutDialog(true)}
                            >
                            <LogoutOutlinedIcon
                                sx={{
                                    width: 35,
                                    height: 35,
                                }}
                            />
                        </IconButton>
                    </Box>
                </Box>
                </Hidden>
            </Box >
            <Box sx={{ borderBottom: "1px solid lightgrey", mx: 1.5 }}></Box>
            <LogoutDialog
                open={isOpenLogoutDialog}
                onDeny={() => setIsOpenLogoutDialog(false)} />
        </Box >
    );
}

export default UserProfileHeader;
