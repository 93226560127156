import Dexie, { Table } from 'dexie';
import { ContentRevision } from '../types/models/ContentRevision';

export class ContentRevisionDB extends Dexie {
    ContentRevisions!: Table<ContentRevision, string>;

    constructor() {
        super('ContentRevisions');
        this.version(1).stores({
            ContentRevisions: 'ContentRevisionId'
        });
    }
    async deleteInactiveContents(activeContentIds: string[]) {
        await this.ContentRevisions.where('ContentRevisionId').noneOf(activeContentIds).delete();
    }
}

export const contentRevisionDB = new ContentRevisionDB();

