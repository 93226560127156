import { AlertMetaDataInstance } from "../types/models/AlertMetaData";
import { ContentRevision } from "../types/models/ContentRevision";
import { FavouriteRule } from "../types/models/FavouriteRule";
import { RoleMetadata } from "../types/models/RoleMetadata";
import { RuleInstance } from "../types/models/RuleInstance";
import { RuleInstanceMetaData } from "../types/models/RuleInstanceMetaData";
import { splitSearchText } from "./StringUtils";

export const getModuleNameFromDisplayName = (displayName: string) => {

    if (displayName && displayName.length > 0) {
        const names = displayName.split(":");
        if (names.length > 0) {
            return names[0];
        }
        else {
            return "Module"
        }
    }
    else {
        return "Module";
    }
}

export const checkConformance = (rule: RuleInstance, locations: string[]) => {
    if(!locations) return;
    const selectedLocations = locations.map((location) => location.split(".")[0]);

    const metaData = JSON.parse(rule.Metadata ?? "");
    if(metaData && metaData.conformance && metaData.conformance.length > 0) {
      const exists = metaData.conformance.some((conformanceItem: string) => {
        const conformanceKey = conformanceItem.split("_")[0].split(".")[0];
        return selectedLocations.includes(conformanceKey);
      });
      return exists ? rule : null;
    }
  }


export const getRuleInstancesForRoles = (rules: RuleInstance[], roles: string[]): RuleInstance[] => {
    const filteredRules = rules.filter((rule) => {
        // Check if any paragraphs have an audience role
        const paragraphsHaveAudience = rule.Paragraphs.some((paragraph) => {
            const paragraphMetadata = getMetadataObject(paragraph.Metadata ?? "");
            const hasAudience = paragraphMetadata?.audience && paragraphMetadata.audience.length > 0;
            return hasAudience;
        });

        // Include the rule if no paragraphs have an audience (should be visible to everyone)
        if (!paragraphsHaveAudience) {
            return true;
        }

        // Include rules with paragraphs having a role in the roles array
        return rule.Paragraphs.some((paragraph) => {
            const paragraphMetadata = getMetadataObject(paragraph.Metadata ?? "");
            return (
                paragraphMetadata?.audience &&
                paragraphMetadata.audience.some((audienceRole) =>
                    roles.includes(audienceRole.replaceAll(" ", "_").toLowerCase())
                )
            );
        });
    });

    return filteredRules;
};

export const getAlertRuleInstancesForRoles = (rules: RuleInstance[], roles: string[], locations: string[]) => {

    const filteredRules = rules.filter(rule => isMetadataIncludeRole(rule.Metadata ?? "", roles));
    const filteredLocationRules = filteredRules.filter((rule) => isMetadataIncludeLocation(rule.Metadata ?? "", locations));
    return filteredLocationRules;
}

export const isMetadataIncludeLocation = (metadata: string, locations: string[]) => {

    const ruleMetadata = getMetadataObject(metadata);

    // If conformance is empty, the alert displays for everyone
    if (!ruleMetadata?.conformance || ruleMetadata?.conformance.length === 0) return true;

    if (!locations || locations.length === 0) return false; // Any location is not assigned to the member

    // Check if the member's location matches with the alert rule
    return locations.some(location => ruleMetadata.conformance?.some((meta) => location.includes(meta.split("_")[0])));
};

export const isMetadataIncludeRole = (metadata: string, roles: string[]) => {

    const ruleMetadata = getMetadataObject(metadata);

    // If audience is empty, the alert displays for everyone
    if (!ruleMetadata?.audience || ruleMetadata?.audience.length === 0) return true;

    if (!roles || roles.length === 0) return false; // Any role is not assigned to the member

    // Check if the member's role matches with the alert rule
    return roles.some(role => ruleMetadata?.audience?.some(meta => meta === role.replaceAll(" ", "_").toLowerCase()) ?? false);
};

export const getRuleInstancesForSearchText = (rules: RuleInstance[], searchText: string) => {
    if (!searchText) return rules;
    const searches = splitSearchText(searchText);
    const filteredRules = rules.filter(rule => {
        let result = true;
        searches.forEach(search => {
            // Find rules having all of search text
            result = result && (rule.DisplayName.toLowerCase().includes(search.toLowerCase()) ||
                rule.Paragraphs.some(paragraph => paragraph.TextContent.toLowerCase().includes(search.toLowerCase())));
        });
        return result;
    });

    return filteredRules;
}

export const getMetadataObject = (metadata: string) => {
    if (metadata.length === 0) {
        return null;
    }

    try {
        const data: RoleMetadata = JSON.parse(metadata);
        return data;
    } catch {
        return null;
    }
}

export const getOrigin = (metadata: string | undefined) => {
    if(!metadata) return null;

    try {
        const data: {origin: string} = JSON.parse(metadata);
        return data.origin[0];
    } catch {
        return null;
    }
}

export const getRuleInstanceMetadata = (metadata: string) => {
    if (metadata.length === 0) {
        return null;
    }

    try {
        const data: RuleInstanceMetaData = JSON.parse(metadata);
        return data;
    } catch {
        return null;
    }
}

export const getCategorisedContentRevisions = (contents: ContentRevision[]) => {

    const AlertTaxonomy = "Alert";

    const modules = contents
        .filter(content => content.Taxonomy && content.Taxonomy !== AlertTaxonomy)
        .sort((a, b) => {
            if (a.Taxonomy && b.Taxonomy) {
                if (a.Taxonomy < b.Taxonomy) {
                    return -1;
                }
                if (a.Taxonomy > b.Taxonomy) {
                    return 1;
                }
            }
            return 0;
        }) ?? [];

    const alerts = contents
        .filter(content => content.Taxonomy && content.Taxonomy === AlertTaxonomy) ?? [];

    const documents = contents
        .filter(content =>
            !modules.some(module => module.ContentRevisionId === content.ContentRevisionId) &&
            !alerts.some(alert => alert.ContentRevisionId === content.ContentRevisionId))
        .sort((a, b) => {
            if (a.DisplayName < b.DisplayName) {
                return -1;
            }
            if (a.DisplayName > b.DisplayName) {
                return 1;
            }

            return 0;
        })
        ?? [];

    return {
        "Modules": modules,
        "Documents": documents,
        "Alerts": alerts,
    }
}

export const snakeToPascalCase = (text: string) => {

    return text.split("_")
        .map(substr => substr.charAt(0)
            .toUpperCase() +
            substr.slice(1))
        .join(" ");
};

export const toSnakeCase = (text: string) => {
    return text.match(/([A-Z])/g)?.reduce(
        (str, c) => str.replace(new RegExp(c), '_' + c.toLowerCase()), text)
        .substring((text.slice(0, 1).match(/([A-Z])/g)) ? 1 : 0) ?? text;
};

export const getSortedRules = (rules: RuleInstance[]) => {
    return rules.sort((c1, c2) => {
        if (c1.DisplayName.length > 3 && c2.DisplayName.length > 3) {
            const num1 = c1.DisplayName.substring(0, 2).replace(".", "");
            const num2 = c2.DisplayName.substring(0, 2).replace(".", "");

            if (parseInt(num1) > 0 || parseInt(num2) > 0) {
                // sort by number
                return parseInt(num1) - parseInt(num2)
            }
        }

        // sort by string
        if (c1.DisplayName > c2.DisplayName) {
            return 1;
        }

        if (c1.DisplayName < c2.DisplayName) {
            return -1;
        }
        return 0;
    });
}

export const getSortedFavouriteRules = (rules: RuleInstance[], favourites: FavouriteRule[]) => {
    const sortedFavouriteRuleIds = favourites
        .sort((a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            if (dateA > dateB) {
                return -1;
            }
            else {
                return 1;
            }
        })
        .map(favourite => favourite.rule_id);

    const sortedRules: RuleInstance[] = [];
    sortedFavouriteRuleIds.forEach(id => {
        const rule = rules.find(rule => rule.RuleId === id);
        if (rule) {
            sortedRules.push(rule)
        }
    });

    return sortedRules;
}

export const isOverdue = (metaData: string) => {
    const labels = getRuleInstanceMetadata(metaData ?? "")?.label;
    if(!labels || labels.length === 0) return "";

    const today = new Date();

    let dateStr: Date | null = null;
    labels.forEach((label) => {
        if(label.startsWith("ruleStartDate=")) {
            try {
                const dateValue = label.split("=")[1];
                dateStr = new Date(dateValue);
            }
            catch{
                console.log("Failed to convert ruleStartDate");
                dateStr = null;
            }
        }
    });

    if(dateStr) {
        const effectiveDate = new Date(dateStr);
        if(effectiveDate.getTime() < today.getTime()) {
            return true;
        }
    }
    return false;
}

export const getMetaData = (alerts: AlertMetaDataInstance, id: string) => {
    const item = alerts?.find((alert) => alert?.RuleInstanceId === id);
    return item ? item.Metadata : null;
}