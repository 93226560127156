import { ReactNode } from "react";
import { Box } from "@mui/material";
import { fontColor } from "../../../constants/ColorSets";
import { theme } from "../../../themes/theme";
import { Fonts } from "../../../constants/AppEnums";
import AppCard from "../../../components/AppCard";
import RuleInstanceListSkeleton from "../../../components/RuleInstanceListSkeleton";
import EmptyResult from "../EmptyResult";
import ContentRevisionView from "../ContentRevisionView";
import { ContentRevision } from "../../../types/models/ContentRevision";

interface SupportDocumentsCardProps {
    searchText: string | undefined;
    supportContent: ContentRevision[] | undefined;
    supportContentDetails: ContentRevision[] | undefined;
}

export default function SupportDocumentsCard({ searchText, supportContent, supportContentDetails }: SupportDocumentsCardProps) {
    const getModules = () => {
        const elements: Array<ReactNode> = [];

        supportContent?.forEach((content) => {
            elements.push(
                <ContentRevisionView
                    isExpanded={false} // isRevisionExpanded(data.ContentResourceId)
                    key={content.ContentRevisionId}
                    contentRevision={content}
                    forDocumentDisplay={true} />
            )
        }
        );

        return (<Box>{elements}</Box>)
    }

    const getDisplayModules = () => {
        if (supportContent === undefined) {
            // The API is not done yet
            return <RuleInstanceListSkeleton />;
        }
        else if (supportContent?.length === 0) {
            // The API returns no modules
            return <EmptyResult>No data to display.</EmptyResult>;
        }
        else if (supportContentDetails &&
            supportContentDetails.length > 0 &&
            (supportContentDetails?.length === 0 ?? true)) {
            // No search result exists
            return (<EmptyResult>No items matching your current search.</EmptyResult>)
        } else {
            return getModules();
        }
    };

    return (
        <Box component="section" id="support-documents">
            <AppCard
                contentStyle={{ px: 0 }}
                title={<Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <Box
                        component="h3"
                        sx={{
                            marginLeft: 1.5,
                            color: theme.card.headerTextColor,
                            fontWeight: Fonts.SEMI_BOLD,
                            fontSize: 18,
                        }} >
                        Support Documents
                    </Box>
                    {(searchText?.replace(/"/g, '').length ?? 0) > 1 &&
                        <Box
                            sx={{
                                fontSize: 15,
                                fontWeight: Fonts.REGULAR,
                                marginRight: 1.5,
                                color: theme.card.headerSubTextColor
                            }} >
                            {supportContentDetails?.flatMap(detail => detail.RuleInstances).length ?? 0} results
                        </Box>
                    }
                </Box>}
                headerStyle={{ backgroundColor: fontColor.grayBackground }}>
                {getDisplayModules()}
            </AppCard>
        </Box>
    );
}
