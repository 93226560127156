import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Hidden, IconButton, InputAdornment, TextField, Typography, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { toggleNavCollapsed } from "../../redux/actions/Setting";
import { fontColor } from "../../constants/ColorSets";
import { theme } from "../../themes/theme";
import { AppState } from "../../redux/store";
import { TeamMembersState } from "../../types/models/TeamMembersState";
import { searchOrgTeams } from "../../redux/actions";

interface TeamPageHeaderProp {
    title: string,
    action?: ReactElement
}

const TeamPageHeader: React.FC<TeamPageHeaderProp> = (prop) => {
    const dispatch = useDispatch();
    const teamState = useSelector<AppState, TeamMembersState>((state) => state.teamMembers);
    const [searchText, setSearchText] = useState<string>("");
    const isScreenLessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        setSearchText("");
    }, [])

    useEffect(() => {
        // Clear when the org data is refreshed
        if (!teamState.orgTeamSearchText) {
            setSearchText("");
        }
    }, [teamState.orgTeamSearchText])

    const handleSearchTextChange = (text: string) => {
        setSearchText(text);
        dispatch(searchOrgTeams(text, teamState.baseOrgTeams ?? []));
    }

    return (
        <Box sx={{ borderRadius: 0 }}>
            <Box sx={{
                width: "100%",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between"
            }}>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start"
                    }}
                >
                    <Hidden lgUp>
                        <IconButton
                            sx={{
                                color: "text.secondary",
                                marginLeft: 1,
                                padding: 0,
                            }}
                            edge="start"
                            className="menu-btn"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => dispatch(toggleNavCollapsed())}
                            size="large"
                        >
                            <MenuIcon
                                sx={{
                                    width: 35,
                                    height: 35,
                                }}
                            />
                        </IconButton>
                    </Hidden>
                    <Box sx={{ py: 1 }}>
                        <Typography
                            variant="h3"
                            component="h3"
                            color={fontColor.titleFontColor}
                            style={{ fontSize: 22, marginLeft: 12 }}
                        >
                            {prop.title}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ mr: 2 }}>
                    <TextField
                        id="search"
                        type="search"
                        label="Search"
                        variant="standard"
                        value={searchText}
                        sx={{ width: isScreenLessThanSmall ? 120 : 200, mr: 2 }}
                        onChange={(event) => { handleSearchTextChange(event.target.value) }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Box sx={{ mr: 0, mt: 2, display: "flex", justifyContent: "flex-end" }}>
                        {prop.action}
                    </Box>
                </Box>
            </Box >
            <Box sx={{ borderBottom: "1px solid lightgrey", mx: 1.5 }}></Box>
        </Box >
    );
}

export default TeamPageHeader;
