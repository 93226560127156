import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { MsalAuthenticationResult, MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import { getKnownRoles, getMyRoles } from "../../redux/actions/Role";
import { getContentList, getMyTeams, getOrgProgress, setSelectedUserLocations, setSelectedUserRoles } from "../../redux/actions";
import { AppState } from "../../redux/store";
import { Setting } from '../../types/models/Setting';
import { loginRequest } from "../../config/AuthConfig";
import AlertsPage from "../../pages/alerts";
import RulesPage from "../../pages/rules";
import AllTeamsPage from "../../pages/all-teams";
import YourTeamsPage from "../../pages/your-teams";
import UserProfilePage from "../../pages/user-profile";
import AppConfirmDialog from "./AppConfirmDialog";
import DownloadFilePage from "../../pages/download-pdf";
import PrivacyPolicy from "../../pages/privacy-policy";
import AppLoader from "./AppLoader";

const AppContentView: React.FC = () => {
  const { instance, accounts } = useMsal();
  const authRequest = { ...loginRequest };
  const settingState = useSelector<AppState, Setting>((state) => state.settings);

  const [isOpenAlertDialog, setIsOpenAlertDialog] = useState<boolean>(false);
  const [clipboardContent, setClipboardContent] = useState<string>("");
  const [clipboardContentTitle, setClipboardContentTitle] = useState<string>("");
  const [errorDialogMessage, setErrorDialogMessage] = useState<string>("");
  const [errorDialogTitle, setErrorDialogTitle] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const logout = () => {
    setIsOpenAlertDialog(false);
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts[0].homeAccountId),
      postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI
    };
    instance.logoutRedirect(logoutRequest);
  }

  useEffect(() => {
    if (settingState.invalidToken) {
      setClipboardContent(settingState.invalidToken);
      setClipboardContentTitle("Access token");
      setErrorDialogMessage("Your account is temporarily unable to access Shield. Please contact support.");
      setErrorDialogTitle("RIS Dependency Failure");
      setIsOpenAlertDialog(true);
    }
  }, [settingState.invalidToken]);

  useEffect(() => {
    if (settingState.otherProviderStatus) {
      setClipboardContent(settingState.otherProviderStatus);
      setClipboardContentTitle("Support Code");
      setErrorDialogTitle("Duplicate Shield Account");
      setErrorDialogMessage(
        "You have signed in with another identity provider than the one you signed up with. Please log out and log back in using another option." +
        "\n\n" +
        "If you are still having difficulty logging in, please contact support and provide the code below."
      );
      setIsOpenAlertDialog(true);
    }
  }, [settingState.otherProviderStatus]);

  useEffect(() => {
    if (settingState.forgotPasswordToken === true) {
      // Force logout when redirected by resetting password
      logout();
    }
  }, [settingState.forgotPasswordToken]);

  useEffect(() => {
    if (accounts && accounts[0]) {
      setLoading(true);
      dispatch(getKnownRoles(() => {
        dispatch(getContentList(() => {
          dispatch(getMyTeams(() => {
            dispatch(getMyRoles((myRoles) => {
              if (myRoles) {
                dispatch(setSelectedUserRoles(myRoles.roles.map(role => role.RoleId) ?? []));
                dispatch(setSelectedUserLocations(myRoles.locations.map((location) => location.LNIId)))
              }
              else {
                console.log("[Error] Could not get my roles by the API");
              }
              // Call TeamProgress API to judge if the team progress exists for the user
              // A KiwiRail user can have a result even if they are not an OrgManager and not a leader
              dispatch(getOrgProgress(() => {
                setLoading(false);
              }));
            }));
          }));
        }));
      }));
    }
  }, [accounts]);

  const ErrorComponent: React.FC<MsalAuthenticationResult> = ({ error }) => {

    const accessTokenRequest = {
      scopes: [process.env.REACT_APP_API_SCOPE ?? ""],
      account: accounts[0],
    };

    // Redirect to sign in page. This happens when clicks cancel button in sign up page
    instance.acquireTokenRedirect(accessTokenRequest);

    return (
      <Box sx={{
        display: "flex",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignContent: "center"
      }}>
        <Typography variant="h6">
          An Error Occurred: {error ? error.errorCode : "unknown error"}
        </Typography>
      </Box>
    );
  }

  const Loading = () => {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh",
          justifyContent: "center",
          alignContent: "center",
        }}>
        <AppLoader />
      </Box>)
  }

  interface MsalAuthProp {
    children: ReactNode
  }

  const MsalAuth: React.FC<MsalAuthProp> = ({ children }) => {
    return (
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
      >
        {children}
      </MsalAuthenticationTemplate>
    );
  };

  return (
    <>
    {loading ? <Loading /> : 
    <>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            padding: 0
          }}
        >
          <Routes>
            <Route path="/" element={<Navigate to={"/rules"} />} />
            <Route path="/rules" element={<MsalAuth><RulesPage /></MsalAuth>} />
            <Route path="/alerts" element={<MsalAuth><AlertsPage /></MsalAuth>} />
            <Route path="/your-teams/:team_id?/:user_id?/:rule_id?" element={<MsalAuth><YourTeamsPage /></MsalAuth>} />
            <Route path="/all-teams" element={<MsalAuth><AllTeamsPage /></MsalAuth>} />
            <Route path="/user-profile" element={<MsalAuth><UserProfilePage /></MsalAuth>} />
            <Route path="/download-pdf" element={<MsalAuth><DownloadFilePage /></MsalAuth>} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/response-oidc" element={<Navigate to={"/rules"} />} />
            <Route path="*" element={<Navigate to={"/rules"} />} />
          </Routes>
        </Box >
        <AppConfirmDialog
          open={isOpenAlertDialog}
          disableDenyButton={true}
          copyToClipboard={clipboardContent}
          copyToClipboardTitle={clipboardContentTitle}
          confirmButtonTitle="Logout"
          onConfirm={() => { logout() }}
          title={errorDialogMessage}
          dialogTitle={errorDialogTitle}
        ></AppConfirmDialog >
      </>}
    </>
  );
};

export default AppContentView;
