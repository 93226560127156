/**
 * Rule Instance Detail
 * @description Displays the content of a rule instance.
 * May be shown as a modal, or as a static view.
 */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Checkbox, Typography, useMediaQuery } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import { RuleInstance } from "../../../types/models/RuleInstance";
import { ContentRevision } from "../../../types/models/ContentRevision";
import AppDialog from "../../../components/AppBase/AppDialog";
import AppLoader from "../../../components/AppBase/AppLoader";
import { Fonts } from "../../../constants/AppEnums";
import RuleDetailBody from "./RuleDetailBody";
import RuleDetailFooter from "./RuleDetailFooter";
import { toggleRuleModal, updateFavouriteRule } from "../../../redux/actions";
import { FavouriteActionType } from "../../../types/models/PostUpdateFavouriteRequest";
import { AppState } from "../../../redux/store";
import { RuleUnitState } from "../../../types/models/RuleUnitState";
import { theme } from "../../../themes/theme";
import { RuleAudioState } from "../../../types/models/RuleAudioState";
import { initialiseAudioState } from "../../../redux/actions/RuleAudio";

interface RuleDetailBaseProps {
    rule: RuleInstance | undefined,
    contentRevision: ContentRevision | undefined,
    displayFeedbackButtons: boolean,
    displayFavouritePinButton: boolean
    handleAcknowledge?: () => void
}

interface RuleDetailModalProps extends RuleDetailBaseProps {
    isModal: true,
    onClose?: () => void;
    isShowDetail: boolean;
    setIsShowDetail: (isShowDetail: boolean) => void;
}

interface RuleDetailStaticProps extends RuleDetailBaseProps {
    isModal: false,
}

type RuleInstanceDetailProps = RuleDetailModalProps | RuleDetailStaticProps;

const RuleInstanceDetail = (prop: RuleInstanceDetailProps) => {
    const dispatch = useDispatch();
    const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
    const audioState = useSelector<AppState, RuleAudioState>((state) => state.audio);
    const isScreenLessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const { isModal = true } = prop; // Default to modal view
    const [isSendingAcknowledge, setIsSendingAcknowledge] = useState<boolean>(false);
    const [expandAll, setExpandAll] = useState<boolean>(true);
    const [isLoadingFailed, setIsLoadingFailed] = useState<boolean>(false);
    const [isFavourite, setIsFavourite] = useState<boolean>(ruleUnitState.myFavourites?.some(favourite => favourite.rule_id === prop.rule?.RuleId ?? "") ?? false);

    const getTaxonomyTitle = (taxonomy: string) => {
        if(!taxonomy) {
            return;
        }

        let title = "";

        const headers = taxonomy.split(";");
        if(headers.length >= 2) {
            title = headers[1];
        }
        else {
            title = taxonomy;
        }

        return title;
    }

    useEffect(() => {
        if (audioState.ruleInstanceId != prop.rule?.RuleInstanceId) {
            dispatch(initialiseAudioState(prop.rule?.RuleInstanceId ?? ""));
        }
    }, []);

    useEffect(() => {
        if (prop.rule) {
            // validation
            const existInFavouriteList = ruleUnitState.myFavourites?.some(favourite => favourite.rule_id === prop.rule?.RuleId) ?? false;
            if ((isFavourite && existInFavouriteList) || (!isFavourite && !existInFavouriteList)) {
                // the rule is already favourite for adding, or the rule does not exist to delete 
                return;
            }

            dispatch(
                updateFavouriteRule(
                    prop.rule.RuleId,
                    isFavourite ? FavouriteActionType.Add : FavouriteActionType.Delete
                ));
        }
    }, [isFavourite]);

    useEffect(() => {
        if (prop.rule && ruleUnitState.myFavourites) {
            setIsFavourite(ruleUnitState.myFavourites.some(favourite => favourite.rule_id === prop.rule?.RuleId));
        }
    }, [prop.rule, ruleUnitState.myFavourites]);

    const renderHeaderActions = () => {
        return (
            <Box sx={{
                position: "relative",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Button variant="text" size="small" sx={{ color: "primary.main", minWidth: 0 }} onClick={() => setExpandAll(!expandAll)}>
                    {expandAll ? <ExpandMoreIcon sx={{ fontSize: 28 }} /> : <ChevronRightIcon sx={{ fontSize: 28 }} />}
                    {isScreenLessThanSmall ? "" : (expandAll ? "Collapse All" : "Expand All")}
                </Button>
                {prop.displayFavouritePinButton && (
                    <Checkbox
                        onChange={(event) => setIsFavourite(event.target.checked)}
                        checked={isFavourite}
                        icon={<PushPinOutlinedIcon />}
                        checkedIcon={<PushPinIcon />} />
                )}
            </Box >
        );
    }

    const getRuleFooterWithEvents = () => {
        return (
            <RuleDetailFooter
                contentRevision={prop.contentRevision}
                ruleInstance={prop.rule}
                sendingAcknowledge={setIsSendingAcknowledge}
                handleSentAcknowledgeStatus={() => { dispatch(toggleRuleModal(false)) }}
                displayFeedbackButtons={prop.displayFeedbackButtons}
                disableButtons={isLoadingFailed}

            />
        );
    }

    const getRuleBodyWithEvents = () => {
        return (
            <Box>
                <RuleDetailBody
                    onClose={() => dispatch(toggleRuleModal(false))}
                    contentRevision={prop.contentRevision}
                    ruleInstance={prop.rule}
                    expandAll={expandAll}
                    onFailedLoading={() => setIsLoadingFailed(true)}

                />
                {/* Space for footer controls */}
                <Box sx={{ height: "50px" }} />
            </Box>
        );
    }

    if (!prop.contentRevision) return null;

    if (isModal) {
        const modalProps = prop as RuleDetailModalProps; // TypeScript now knows that prop is of type RuleDetailModalProps
        return (
            <AppDialog
                title=
                {
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography sx={{color: "blue"}}>{prop.contentRevision.Taxonomy && prop.contentRevision.Taxonomy !== "Alert" ? <span className="breadcrumb" onClick={() => dispatch(toggleRuleModal(false))}>{getTaxonomyTitle(prop.contentRevision.Taxonomy)}</span> : null}</Typography>
                        <Box
                            sx={{
                                color: 'primary.main',
                                fontSize: 16,
                                fontWeight: Fonts.REGULAR,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}>
                            {prop.rule?.DisplayName}
                        </Box>
                    </div>
                }
                extraMenu={<> {renderHeaderActions()} </>}
                maxWidth='lg'
                dividers={false}
                fullHeight={true}
                onClose={() => {
                    dispatch(toggleRuleModal(false));
                    if (modalProps.setIsShowDetail) { modalProps.setIsShowDetail(false) }
                }}
                open={modalProps.isShowDetail}
                floating={getRuleFooterWithEvents()} >
                {getRuleBodyWithEvents()}
                {isSendingAcknowledge && <AppLoader />}
            </AppDialog >
        );
    }

    // Static (non-modal) view – this is for rules only, alerts are modal only
    return (
        <>
            <Box data-element="RuleDetail_Static">
                <Box sx={{ pb: 2 }}>
                <Typography>{prop.contentRevision.Taxonomy && prop.contentRevision.Taxonomy !== "Alert" ? <>{getTaxonomyTitle(prop.contentRevision.Taxonomy)}</> : null}</Typography>
                    <Box display="flex" alignItems="center">
                        <Typography
                            color="primary.main"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap"
                            }}>
                            {prop.contentRevision?.DisplayName}
                        </Typography>
                        <Box ml="auto" display="flex" alignItems="center">
                            {renderHeaderActions()}
                        </Box>
                    </Box>
                    {getRuleBodyWithEvents()}
                </Box>
            </Box>
            <Box sx={{ position: "fixed", bottom: 32, right: 32 }} >
                {getRuleFooterWithEvents()}
            </Box>
        </>
    );
};

RuleInstanceDetail.displayName = "RuleInstanceDetail";

export default RuleInstanceDetail;
