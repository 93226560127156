import { Dispatch } from "react";
import {
  NAV_COLLAPSED,
  SET_INITIAL_PATH,
  SettingsActionTypes,
  TOGGLE_NAV_COLLAPSED,
  RIS_DEPENDENCY_ERROR,
  FORGOT_PASSWORD_TOKEN
} from "../../types/actions/Settings.action";

export const toggleNavCollapsed = () => ({ type: TOGGLE_NAV_COLLAPSED });
export const onNavCollapsed = () => ({ type: NAV_COLLAPSED });

export const setInitialPath = (initialPath: string): SettingsActionTypes => ({
  type: SET_INITIAL_PATH,
  initialPath,
});

export const setRISDependencyError = (invalidToken: string) => {
  return (dispatch: Dispatch<SettingsActionTypes>) => {
    dispatch({ type: RIS_DEPENDENCY_ERROR, payload: invalidToken });
  }
};

export const setForgotPasswordFlag = () => {
  return (dispatch: Dispatch<SettingsActionTypes>) => {
    dispatch({ type: FORGOT_PASSWORD_TOKEN, payload: true });
  }
};
