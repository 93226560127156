import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import reducers from "../reducers";

export type AppState = ReturnType<typeof reducer>;

const reducer = combineReducers({
  ...reducers,
})
const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
})
export default store;
