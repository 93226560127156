/**
 * @name RuleDetailBody
 * @description The main content of the rule, consisting of RuleSection components.
 * @features
 *  - Get HTML content from Paligo via backend API
 *  - Parse HTML into ReactElements
 *  - Render RuleSection components
 *  - Handle playing audio (mp3 file) for each section
 *  - Handle internal linking to other rules or modules
 */

import ReactHtmlParser, { DOMNode } from 'html-react-parser';
import React, { Dispatch, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { downloadHtml } from "../../../redux/actions/RuleUnit";
import { AppState } from "../../../redux/store";
import { getMetadataObject } from "../../../utils/RuleUtil";
import { ContentRevision } from "../../../types/models/ContentRevision";
import { RuleInstance } from "../../../types/models/RuleInstance";
import RuleSection from "./RuleSection";
import RuleInstanceDetailSkeleton from "./RuleInstanceDetailSkeleton";
import "./RuleDetailBodyStyle.scss"
import { useNavigate } from "react-router-dom";
import { Element as ParsedElement } from "html-react-parser";
import { showMessage } from "../../../redux/actions";
import { RuleAudioState } from '../../../types/models/RuleAudioState';
import { getAudioFile, setAutoPlay, startAudio, stopAudio } from '../../../redux/actions/RuleAudio';

interface RuleDetailBodyProps {
    contentRevision: ContentRevision | undefined,
    ruleInstance: RuleInstance | undefined,
    expandAll: boolean,
    onClose?: () => void,
    onFailedLoading?: () => void,
}

interface ParsedElementWithData extends ParsedElement {
    data: string
}

const RuleDetailBody: React.FC<RuleDetailBodyProps> = (prop) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [html, setHtml] = useState<string | undefined>(undefined);
    const [currentActiveIndex, setCurrentActiveIndex] = useState<number | undefined>(undefined);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const audioState = useSelector<AppState, RuleAudioState>((state) => state.audio);

    useEffect(() => {
        setCurrentActiveIndex(audioState.currentAudioIndex);
    }, [audioState.currentAudioIndex]);

    useEffect(() => {
        if (prop.ruleInstance) {
            setIsDownloading(true);
            dispatch(downloadHtml(prop.contentRevision?.ContentRevisionId ?? "", prop.ruleInstance?.Filename ?? "", (html) => {
                if (!html && prop.onFailedLoading) {
                    prop.onFailedLoading();
                }
                setHtml(html ?? "")
                setIsDownloading(false);
            }));
        }
        else {
            setHtml(undefined);
        }
    }, [prop.ruleInstance]);


    /**
     * Handle Start / Stop Audio
     */
    const handleOnClickSection = (index: number) => {
        if (!prop.ruleInstance || !prop.contentRevision) return;
        if (index === currentActiveIndex && audioState.isPlaying) {
            dispatch(stopAudio());
        } else {
            dispatch(setAutoPlay(false));
            if (audioState.audioMap?.has(index)) {
                dispatch(startAudio(index));
            } else {
                dispatch(getAudioFile(prop.ruleInstance, prop.contentRevision.ContentRevisionId, index, (result) => {
                    result ? dispatch(startAudio(index)) : dispatch(stopAudio());
                }));
            }
        }
    }

    /**
     * @name handleInternalLink
     * @description Handles internal linking to other rules or modules.
     * @param e The mouse event that triggered the function.
     * @param options An object containing the pathname and search parameters for the link.
     */
    const handleInternalLink = (e: React.MouseEvent<HTMLAnchorElement>, options: { pathname: string, search: string }) => {
        e.preventDefault();
        if (prop.onClose) {
            prop.onClose();
        }
        navigate({ pathname: options.pathname, search: options.search });
    }

    /**
     * @name getContentsFromHtml
     * @description Parses the HTML content into ReactElements and returns the children of the body element.
     * @param html The HTML content to parse.
     * @param dispatch The dispatch function from the Redux store.
     * @returns An array of ReactElements representing the children of the body element.
     */
    const getContentsFromHtml = (html: string, dispatch: Dispatch<unknown>) => {
        const parsedHtml = ReactHtmlParser(html, {
            replace: replaceLinks
        }) as ReactElement;

        if (!parsedHtml) {
            loggingForHtmlAnalysing(html, "[Error] the HTML file you downloaded could not be parsed into an element.", dispatch);
            const emptyResult: ReactElement[] = [];
            return emptyResult;
        }

        const children = parsedHtml.props?.children as ReactElement[] ?? null;
        if (!children) {
            loggingForHtmlAnalysing(html, "[Error] the HTML file you downloaded could not be parsed into an element. No children found", dispatch);
            return [parsedHtml];
        }

        const body = children.find(child => child.type == "body") as ReactElement;
        if (!body) {
            loggingForHtmlAnalysing(html, "[Error] the HTML file you downloaded could not be parsed into an element. No body found", dispatch);
            return children;
        }

        const bodyChildren = body.props?.children as ReactElement[] ?? null;
        if (!bodyChildren) {
            loggingForHtmlAnalysing(html, "[Error] the HTML file you downloaded could not be parsed into an element. No body children found", dispatch);
            return [body];
        }

        const targetSection = bodyChildren.find(bodyChild => bodyChild.props?.className == "section") as ReactElement;
        if (!targetSection) {
            loggingForHtmlAnalysing(html, "[Error] the HTML file you downloaded could not be parsed into an element. No section found", dispatch);
            return bodyChildren;
        }

        const contents = (targetSection.props.children as ReactElement[]).filter(child => child.props);
        if (!contents) {
            loggingForHtmlAnalysing(html, "[Error] the HTML file you downloaded could not be parsed into an element. No section contents found", dispatch);
            return [targetSection];
        }

        return contents;
    }

    const loggingForHtmlAnalysing = (html: string, message: string, dispatch: Dispatch<unknown>) => {
        dispatch(showMessage(message));
    }

    /**
     * @name replaceLinks
     * @description Replaces anchor tags with react-router-dom links.
     * @param domNode 
     * @returns DOMNode : Augmented anchor tag handled by react-router-dom
     */
    const replaceLinks = (domNode: DOMNode) => {
        const domElement: ParsedElement = domNode as ParsedElement;

        if (domElement.name === 'a') {
            // Check if we're internally-linking to a rule or module
            const isModule = domElement.attribs?.class?.includes('link linktype-publication');
            const isRule = domElement.attribs?.class?.includes('link linktype-component');
            const isInternalLink = isModule || isRule;

            if (!isInternalLink) return;

            // Build the target path
            const path = `/rules`;
            let uuid = '';
            let target = '';
            if (isModule) {
                uuid = domElement.attribs.href.split('#')[1]; // Example source: href="/document/preview/7075#UUID-33c49137-d9c4-db15-71c1-12d4e41fbb88"
                target = `?moduleId=${uuid}`
            }
            if (isRule) {
                if(domElement.attribs.href.includes(".html")) {
                    uuid = domElement.attribs.href.split('.html')[0]; // Example source href="UUID-537fd62c-454a-3995-d147-27809bc9f650.html";
                    target = `?ruleId=${uuid}`
                }
                else {
                    uuid = domElement.attribs.href.split('#')[1]; // Example source: href="/document/preview/7075#UUID-33c49137-d9c4-db15-71c1-12d4e41fbb88"
                    target = `?ruleId=${uuid}`
                }
            }
            if (domElement.children && domElement.children[0]) {
                const content = domElement.children[0] as ParsedElementWithData;
                return (
                    <a
                        href={`${path}${target}`} // Left here for accessibility
                        onClick={(e) => {
                            handleInternalLink(e, {
                                pathname: path,
                                search: target,
                            })
                        }}>{content.data}</a>
                );
            }
        }
    };

    const createSectionsFromDownloadedHTML = (html: string) => {
        if (!html) {
            return <Box></Box>;
        }

        // replace static image path
        html = html.replaceAll("../css/image/", "./css/image/")

        // replace image path to the server URL
        prop.contentRevision?.Miscellaneous?.forEach(miscellanea => {
            const lowerFileName = miscellanea.Filename.toLowerCase();
            if (lowerFileName.endsWith(".jpg") ||
                lowerFileName.endsWith(".jpeg") ||
                lowerFileName.endsWith(".png")) {

                html = html.replaceAll(miscellanea.Filename,
                    `${process.env.REACT_APP_API_BASE_URL}/content/${prop.contentRevision?.ContentRevisionId}/${miscellanea.Filename}`);
            }
        });

        const contents = getContentsFromHtml(html, dispatch);

        const reactNodes: ReactElement[] = contents?.map((element, index) => {
            const isInformalTable = element.props?.className?.toString().split(" ").includes("informaltable") ?? false;
            const paragraph = (prop.ruleInstance?.Paragraphs.length ?? 0) > index ? prop.ruleInstance?.Paragraphs[index] : undefined;
            const sectionMetadata = getMetadataObject(paragraph?.Metadata ?? "");

            if (!prop.ruleInstance) return <Box />;

            return (
                <Box key={index} >
                    <RuleSection
                        pageLocations={sectionMetadata?.conformance ?? []}
                        pageRoles={sectionMetadata?.audience ?? []}
                        contentRevisionId={prop.contentRevision?.ContentRevisionId ?? ""}
                        rule={prop.ruleInstance}
                        index={index}
                        element={element}
                        handleClickSection={(index) => { handleOnClickSection(index) }}
                        isActive={currentActiveIndex === index && audioState.isPlaying}
                        forceExpandByRole={prop.expandAll}
                        hideTitle={(sectionMetadata?.audience ?? []).length > 0 || (sectionMetadata?.conformance ?? []).length > 0}
                        isInformalTable={isInformalTable}
                        locationMetaData={prop.ruleInstance.Metadata}
                    ></RuleSection>
                </Box>
            )
        });
        return reactNodes;
    };

    return (
        <Box>
            {isDownloading ?
                (<RuleInstanceDetailSkeleton />)
                :
                createSectionsFromDownloadedHTML(html ?? "")
            }
        </Box>
    );
}

export default RuleDetailBody;
