import React, { Ref } from "react";
import { NavLink } from "react-router-dom";

interface AppNavLinkProps {
  activeClassName: string;
  className: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

// eslint-disable-next-line react/display-name
const AppNavLink = React.forwardRef(
  (
    { activeClassName, className, ...rest }: AppNavLinkProps,
    ref: Ref<HTMLAnchorElement>
  ) => {
    return (
      <NavLink
        ref={ref}
        to={rest.to}
        {...rest}
        className={({ isActive }) =>
          isActive ? `${activeClassName} ${className}` : className
        }
      >
        {rest.children}
      </NavLink>
    );
  }
);

export default AppNavLink;
