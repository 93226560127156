import Settings from "./Setting";
import Common from "./Common";
import RuleUnitReducer from "./RuleUnitReducer";
import TeamMembersReducer from "./TeamMembersReducer";
import RoleReducer from "./RoleReducer";
import ChatReducer from "./ChatReducer";
import RuleFileReducer from "./RuleFileReducer";
import RuleAudioReducer from "./RuleAudioReducer";

const reducers = {
  settings: Settings,
  common: Common,
  ruleUnit: RuleUnitReducer,
  teamMembers: TeamMembersReducer,
  role: RoleReducer,
  chat: ChatReducer,
  file: RuleFileReducer,
  audio: RuleAudioReducer
};

export default reducers;
