import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Pagination, Tab, Tabs } from "@mui/material";
import { AppState } from "../../redux/store";
import { getFileList, getUploadedPDFs } from "../../redux/actions/RuleFile";
import { RuleFileState } from "../../types/models/RuleFileState";
import { theme } from "../../themes/theme";
import AppInfoView from "../../components/AppBase/AppInfoView";
import AppLoader from "../../components/AppBase/AppLoader";
import DownloadFileHeader from "./DownloadFileHeader";
import DownloadFileTable from "./DownloadFileTable";
import { RuleFile } from "../../types/models/RuleFile";
import { UploadPDFModal } from "./UploadPDFModal";
import { RoleState } from "../../types/models/RoleState";

export default function DownloadFilePage() {
    const dispatch = useDispatch();
    const roleState = useSelector<AppState, RoleState>((state) => state.role);
    const fileState = useSelector<AppState, RuleFileState>((state) => state.file);

    const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentFiles, setCurrentFiles] = useState<RuleFile[]>();
    const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
    const [pdfValue, setPdfValue] = useState<number>(0);
    const pageSize = 10;

    const handlePdfValueChange = (event: React.SyntheticEvent, newValue: number) => {
        setPdfValue(newValue);
    }

    const getPDFs = () => {
        setLoading(true);
        setCurrentPage(1);
        if(pdfValue === 0) {
            dispatch(getFileList((list) => {
                setCurrentFiles(list?.slice(0, pageSize) ?? []);
                setLoading(false)
            }));
        }
        else {
            dispatch(getUploadedPDFs((list) => {
                setCurrentFiles(list?.slice(0, pageSize) ?? []);
                setLoading(false)
            }));
        }
    }

    useEffect(() => {
        // Call ListPDFs API
        getPDFs();
    }, [pdfValue]);

    useEffect(() => {
        const pagedFiles = fileState.filteredRuleFileList?.slice((currentPage - 1) * pageSize, currentPage * pageSize) ?? [];
        setCurrentFiles(pagedFiles)
    }, [currentPage]);

    useEffect(() => {
        setCurrentPage(1);
        const pagedFiles = fileState.filteredRuleFileList?.slice(0, pageSize) ?? [];
        setCurrentFiles(pagedFiles)
    }, [fileState.filteredRuleFileList]);

    return (
        <>
            {loading && <Box sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <AppLoader />
            </Box>}
            <Box sx={{ backgroundColor: theme.page.bgColor, minHeight: "100vh" }}>
                <DownloadFileHeader
                    refreshPage={() => {
                        setLoading(true);
                        setCurrentPage(1);
                        dispatch(getFileList(() => {
                            setLoading(false);
                        }));
                    }}
                    isRefreshing={loading}
                    setUploadModalOpen={setUploadModalOpen}
                    isContentAuthor={roleState.contentAuthor}
                />
                {roleState.contentAuthor ? (
                    <Box sx={{ display: "flex", justifyContent: "center", mx: 2, mt: 2 }}>
                        <Tabs value={pdfValue} onChange={handlePdfValueChange} >
                            <Tab label="Default" />
                            <Tab label="uploads" />
                        </Tabs>
                    </Box>) 
                : null}
                <Box sx={{ mx: 2, mt: 2 }}>
                    <DownloadFileTable filteredRuleFileList={currentFiles} />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                    <Pagination
                        sx={{
                            m: 4,
                            "&  .Mui-selected": {
                                color: theme.palette.primary.main,
                                backgroundColor: theme.card.headerBgColor
                            }
                        }}
                        count={Math.ceil((fileState.filteredRuleFileList?.length ?? 0) / 10)}
                        shape="rounded"
                        page={currentPage}
                        onChange={(event, value) => setCurrentPage(value)}
                    />
                </Box>
            </Box>
            <AppInfoView />
            <UploadPDFModal 
                getPDFs={getPDFs} 
                uploadModalOpen={uploadModalOpen} 
                setUploadModalOpen={setUploadModalOpen} 
            />
        </>
    );
}
