import { useEffect } from "react";
import { Box, Button, Dialog, DialogActions, DialogTitle, Snackbar, SnackbarContent, TextField, Typography } from "@mui/material";
import { fontColor } from "../../../constants/ColorSets";
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import "./UploadModal.scss";
import { theme } from "../../../themes/theme";
import { TeamCSVData } from "../../../types/models/TeamCSVData";
import { VirtualTeam } from "../../../types/models/Team";

interface Props {
  uploadModalOpen: boolean;
  setUploadModalOpen: (uploadModalOpen: boolean) => void;
  formDisplayName: string | undefined;
  handleUploadCsvData: (data: TeamCSVData[], teamName: string) => void;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDragFile: (e: React.DragEvent)=> void;
  handleDropFile: (e: React.DragEvent<HTMLInputElement>) => void;
  handleUpload: () => void;
  teamName: string | undefined;
  setTeamName: (teamName: string | undefined) => void;
  selectedFile: File | null;
  setSelectedFile: (selectedFile: File | null) => void;
  openMessageAlert: boolean;
  setOpenMessageAlert: (openMessageAlert: boolean) => void;
  uploadMessage: string;
  setUploadMessage: (uploadMessage: string) => void;
  team: VirtualTeam | undefined;
}


export const UploadModal = ({ 
  setUploadModalOpen, 
  uploadModalOpen, 
  formDisplayName, 
  handleDragFile, 
  handleDropFile, 
  handleFileChange, 
  handleUpload, 
  openMessageAlert, 
  selectedFile, 
  setOpenMessageAlert, 
  setSelectedFile, 
  setTeamName, 
  setUploadMessage, 
  teamName, 
  uploadMessage,
  team,
}: Props) => {
  

  useEffect(() => {
    setTeamName(formDisplayName)
  }, [formDisplayName])

  const handleCloseModal = () => {
    if(!formDisplayName) {
      setTeamName("");
    }
    setSelectedFile(null);
    setUploadMessage("");
    setUploadModalOpen(false);
    setOpenMessageAlert(false);
  }

  const handleTeamNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTeamName(e.target.value);
  }

  return (
  <>
    <Snackbar
      anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      open={openMessageAlert}
      autoHideDuration={6000}
      onClose={() => setOpenMessageAlert(false)}
    > 
    <SnackbarContent style={{
        backgroundColor: theme.palette.error.main,
      }}
      message={<span>{uploadMessage}</span>}
    />
  </Snackbar>
  <Dialog fullWidth onClose={handleCloseModal} open={uploadModalOpen}>
    <DialogTitle sx={{mb: 5, p: 2, fontSize: 18, borderBottom: `${fontColor.grayBackground} solid 1px` }}>{team ? "Edit Team via CSV" : "Create a Team via CSV"}</DialogTitle>
    <TextField 
      sx={{mx: 2}} type="text" 
      id="TeamName"
      label="Team Name"
      InputLabelProps={{ shrink: true }}
      required 
      value={teamName} 
      onChange={handleTeamNameChange}
      disabled={team !== undefined}
    />
    <Box 
      sx={{my: 5, mx: 3, py: 8, 
      display: "flex", 
      flexDirection: "column", 
      alignItems: "center", 
      justifyContent: "center", 
      borderRadius: "20px", 
      border: `dashed 1px gray`,
      cursor: "pointer"}}
      onDragOver={handleDragFile}
      onDrop={handleDropFile}
      >
        {!selectedFile ? 
          <label style={{display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer"}}>
            <input type="file" accept=".csv" onChange={handleFileChange} className="upload-input" />
            <UploadFileOutlinedIcon fontSize="large" />
            <Typography>Select or drag CSV file</Typography>
          </label> 
          : 
          <label style={{display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer"}}>
            <input type="file" accept=".csv" onChange={handleFileChange} className="upload-input" />
            <CheckCircleOutlinedIcon fontSize="large" sx={{color: "#43a047"}} />
            <Typography>{selectedFile.name}</Typography>
          </label>
        }
    </Box>
    <DialogActions sx={{my: 1}}>
      <Button 
        sx={{
          minWidth: 100,
          textTransform: "none",
          color: "white"
        }}
        disabled={!teamName}
        variant="contained"
        onClick={handleUpload}
        >Upload CSV</Button>
      <Button 
        sx={{
          minWidth: 100,
          mx: 2,
          textTransform: "none"
        }}
          color="primary"
          variant="outlined" onClick={handleCloseModal}>Cancel</Button>
    </DialogActions>
  </Dialog>
  </>
  )
}