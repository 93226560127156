import { Box } from "@mui/material";
import RoleFilter from "./RoleFilter";
import LocationFilter from "./LocationFilter";

const RuleUnitFilter = () => {


    return (
        <Box>
            <RoleFilter></RoleFilter>
            <LocationFilter></LocationFilter>
        </Box>
    )
}

export default RuleUnitFilter; 