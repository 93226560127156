import React from "react";
import { useMsal } from "@azure/msal-react";
import AppConfirmDialog from "./AppBase/AppConfirmDialog";

interface LogoutDialogProp {
    open: boolean,
    onDeny: () => void
}

export const LogoutDialog: React.FC<LogoutDialogProp> = (prop) => {
    const { accounts, instance } = useMsal();
    const logout = () => {
        const logoutRequest = {
            account: instance.getAccountByHomeId(accounts[0].homeAccountId),
            postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI
        };
        instance.logoutRedirect(logoutRequest);
    }
    return (
        <AppConfirmDialog
            open={prop.open}
            onDeny={() => prop.onDeny()}
            onConfirm={() => logout()}
            title="You are about to log out. Do you want to proceed?"
            dialogTitle="Logout"
        ></AppConfirmDialog >
    );
};
