/**
 * @name ContentRevisionView
 * @description Displays an accordion of the ContentRevisions within a Module.
 *  Each ContentRevision contains a list of RuleInstances.
 */

import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SegmentIcon from '@mui/icons-material/Segment';
import { AppState } from "../../../redux/store";
import { getContentById, selectRule, toggleRuleModal } from "../../../redux/actions/RuleUnit";
import { ContentRevision } from "../../../types/models/ContentRevision";
import { RuleUnitState } from "../../../types/models/RuleUnitState";
import { RuleInstance } from "../../../types/models/RuleInstance";
import { SkeletonRuleItem } from "../../../components/RuleInstanceListSkeleton";
import { theme } from "../../../themes/theme";
import RuleInstanceList from "./RuleInstanceList";
import { useRules } from "../../../hooks/useRules";

interface ContentRevisionViewProps {
  contentRevision: ContentRevision | undefined;
  forDocumentDisplay: boolean;
  isExpanded?: boolean;
}

const ContentRevisionView: React.FC<ContentRevisionViewProps> = (prop) => {
  const {targetItem} = useRules();
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [contentRevision, setContentRevision] = React.useState<ContentRevision | undefined>(undefined);
  const [isFirstLoad, setIsFirstLoad] = React.useState<boolean>(true);
  const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
  const dispatch = useDispatch();

  const targetRef = React.createRef<HTMLDivElement>();

  const handleScroll = useCallback((contentResourceId: string) => {
    try {
        if (contentResourceId === targetItem?.id) {
            targetRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    } catch (error) {
        console.log(error);
    }
}, [targetRef]);

useEffect(() => {
  if(targetItem && targetItem.id) {
    handleScroll(targetItem?.id)
  }
}, [targetItem])

  // On mount, check if the content is already loaded
  useEffect(() => {
    const targetDetails = prop.forDocumentDisplay ? ruleUnitState.baseSupportDocumentContentRevisionDetails : ruleUnitState.baseContentRevisionDetails;
    if (targetDetails?.some(content => content.ContentRevisionId === prop.contentRevision?.ContentRevisionId)) {
      setCurrentContentRevision();
    }
    else {
      // Call API to get the detail of the content
      dispatch(getContentById(prop.contentRevision?.ContentRevisionId ?? "", () => {
        setIsFirstLoad(true);
        setCurrentContentRevision();
      }));
    }
  }, []);

  useEffect(() => {
    setIsFirstLoad(false);
    setCurrentContentRevision();

    if (prop.isExpanded !== undefined && prop.isExpanded !== false) {
      setExpanded(prop.isExpanded);
      return
    }

  }, [ruleUnitState.contentRevisionDetails, ruleUnitState.supportDocumentContentRevisionDetails, prop.isExpanded]);

  const setCurrentContentRevision = () => {
    const targetDetails = prop.forDocumentDisplay ? ruleUnitState.supportDocumentContentRevisionDetails : ruleUnitState.contentRevisionDetails;
    const contentRevision = targetDetails?.find(content => content.ContentRevisionId === prop.contentRevision?.ContentRevisionId);
    setContentRevision(contentRevision);
  }

  const handleChange =
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded);
    };

  const onViewRuleDetail = (selectedRule: RuleInstance) => {
    dispatch(selectRule(selectedRule));
    dispatch(toggleRuleModal(true));
  };

  if (contentRevision || isFirstLoad) {
    return (
      <>
        <Accordion
          ref={targetRef}
          data-element="ContentRevisionView"
          disableGutters={true}
          sx={{
            position: "relative",
            boxShadow: "none",
            borderBottom: (theme) => `solid 1px ${theme.palette.grey[200]}`,
            mb: "-1px",
            "&:last-of-type": {
              borderRadius: 0,
            },
          }}
          key={prop.contentRevision?.ContentRevisionId}
          expanded={expanded !== false}
          onChange={handleChange(contentRevision?.ContentRevisionId ?? "")}>
          <AccordionSummary
            sx={{
              "& .MuiSvgIcon-root": {
                color: (theme) => theme.palette.grey[500],
              },
              "&.Mui-expanded": {
                borderBottom: (theme) => `solid 1px ${theme.palette.grey[200]}`,
              },
              paddingX: 1,
              ml: prop.forDocumentDisplay ? 0 : 1.5
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            {contentRevision ? (
              <>
                <SegmentIcon sx={{ mr: 1 }}></SegmentIcon>
                <Typography sx={{ width: '70%', flexShrink: 0, color: theme.palette.primary.main }}>
                  {contentRevision?.DisplayName ?? ""}
                </Typography>
                <Typography sx={{ color: theme.palette.primary.main, ml: "auto", fontSize: 15, textAlign: "center" }}>
                  {contentRevision.RuleInstances?.length + " items"}
                </Typography>
              </>
            ) : (
              <SkeletonRuleItem />
            )}
          </AccordionSummary>
          <AccordionDetails
            sx={{ p: 0, ml: prop.forDocumentDisplay ? 0 : 1 }}
          >
            <RuleInstanceList
              ruleInstances={contentRevision?.RuleInstances ?? undefined}
              onViewRuleDetail={onViewRuleDetail}
            />
          </AccordionDetails>
        </Accordion >
      </>
    );
  } else {
    // Empty searched result
    return <Box />
  }
};

export default ContentRevisionView;
