import axios, { AxiosError } from "axios";
import { ErrorResponseDataModel } from "../types/models/ErrorResponseDataModel";

export function snakeCaseToTitleCase(string: string) {
  return string.split('_').map(word => word[0].toUpperCase() + word.slice(1)).join(' ');
}

export function toCommaList(array: string[]) {
  const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
  return formatter.format(array);
}

export function formatDate(date: string) {
  const formatter = new Intl.DateTimeFormat('en', { dateStyle: 'medium' });
  return formatter.format(new Date(date));
}

export function formatDateTime(date: string) {
  const formatter = new Intl.DateTimeFormat('en', { dateStyle: 'medium', timeStyle: 'short' });
  return formatter.format(new Date(date)).replaceAll(",", "");
}

export function formatTime(date: string) {
  const formatter = new Intl.DateTimeFormat('en', { timeStyle: 'short' });
  return formatter.format(new Date(date)).replaceAll(",", "");
}

export const splitSearchText = (searchText: string) => {
  let splittedWords: string[] = [];
  if (searchText && searchText.length > 0) {
    splittedWords = searchText
      .trim()
      .split(/([^\s"]+|"[^"]*")+/g)
      .map(txt => txt.replaceAll('"', '').toLowerCase())
      .filter(txt => txt.trim().length > 1 && txt.trim() !== "the"); // Ignore 1 letter and "the"
  }
  return splittedWords;
};

export const getDisplayErrorMessage = (err: Error | AxiosError) => {
  // Return the title, detail, and status if the error response contains them.
  if (axios.isAxiosError(err)) {
    try {
      if (err.response) {
        const errorBody = err.response.data as ErrorResponseDataModel;
        if (errorBody && (errorBody.title || errorBody.detail) && errorBody.status) {
          return `${errorBody.title ?? ""} ${errorBody.detail ?? ""} (Status: ${errorBody.status})`;
        }
      }
    } catch (e) {
      console.log("(Warning) Failed to convert error response");
      console.log(e);
    }
  }
  return err.message;
}
