import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import store from "./redux/store";
import AppLayout from './components/AppBase/AppLayout';
import AppAuthProvider from "./components/AppBase/AppAuthProvider";
import GenericErrorFallback from "./components/GenericErrorFallback";
import { theme } from "./themes/theme";
import './App.css';

function App() {

  const logError = (error: Error, info: { componentStack: string }) => {
    console.error(error);
    console.error(info);
  };

  return (
      <ThemeProvider theme={theme}>
        <ErrorBoundary fallbackRender={({ error }) => (<GenericErrorFallback error={error} />)} onError={logError}>
          <AppAuthProvider>
            <Provider store={store}>
              <HashRouter basename="/">
                <CssBaseline />
                <AppLayout />
              </HashRouter>
            </Provider>
          </AppAuthProvider>
        </ErrorBoundary>
      </ThemeProvider>
  );
}

export default App;
