import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Hidden, IconButton, InputAdornment, TextField, Tooltip, Typography, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import SyncIcon from "@mui/icons-material/Sync";
import { AppState } from "../../redux/store";
import { fontColor } from "../../constants/ColorSets";
import { RuleFileState } from "../../types/models/RuleFileState";
import { searchFile } from "../../redux/actions/RuleFile";
import { theme } from "../../themes/theme";
import { toggleNavCollapsed } from "../../redux/actions/Setting";

interface DownloadFileHeaderProp {
    refreshPage: () => void;
    isRefreshing: boolean;
    setUploadModalOpen: (uploadModalOpen: boolean) => void;
    isContentAuthor: boolean | undefined;
}

const DownloadFileHeader: React.FC<DownloadFileHeaderProp> = (prop) => {
    const dispatch = useDispatch();
    const isScreenLessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const fileState = useSelector<AppState, RuleFileState>((state) => state.file);
    const [searchText, setSearchText] = useState<string>(fileState.searchText ?? "");

    const handleSearchTextChange = (text: string) => {
        dispatch(searchFile(text));
        setSearchText(text);
    }

    const startRefresh = () => {
        setSearchText("");
        prop.refreshPage();
    }

    return (
        <Box sx={{ borderRadius: 0 }}>
            <Box sx={{
                width: "100%",
                height: "64px",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between"
            }}>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start"
                    }}>
                    <Hidden lgUp>
                        <IconButton
                            sx={{
                                color: "text.secondary",
                                marginLeft: 1,
                                padding: 0,
                            }}
                            edge="start"
                            className="menu-btn"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => dispatch(toggleNavCollapsed())}
                            size="large">
                            <MenuIcon
                                sx={{
                                    width: 35,
                                    height: 35,
                                }}
                            />
                        </IconButton>
                    </Hidden>
                    <Box sx={{ py: 1 }}>
                        <Typography
                            variant="h3"
                            component="h3"
                            color={fontColor.titleFontColor}
                            style={{ fontSize: 22, marginLeft: 12 }}>
                            Download PDF
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <TextField
                        id="search"
                        type="search"
                        label="Search"
                        variant="standard"
                        value={searchText}
                        sx={{ width: isScreenLessThanSmall ? 120 : 200, marginBottom: 1 }}
                        onChange={(event) => { handleSearchTextChange(event.target.value) }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <IconButton
                        onClick={() => startRefresh()}
                        size="large"
                        disabled={prop.isRefreshing}
                        sx={{
                            color: fontColor.orangeTitle,
                            marginTop: 1,
                            marginLeft: isScreenLessThanSmall ? 1 : 2,
                            padding: isScreenLessThanSmall ? 0 : 1
                        }}>
                        <SyncIcon />
                    </IconButton>
                    <Tooltip title="Upload PDF">
                        <span>
                            <IconButton
                                onClick={() => prop.setUploadModalOpen(true)}
                                size="large"
                                disabled={prop.isRefreshing || !prop.isContentAuthor}
                                sx={{
                                    color: fontColor.orangeTitle,
                                    marginTop: 1,
                                    marginRight: 2,
                                    marginLeft: isScreenLessThanSmall ? 1 : 0,
                                    padding: isScreenLessThanSmall ? 0 : 1
                                }}>
                                <CloudUploadOutlinedIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </Box >
            <Box sx={{ borderBottom: "1px solid lightgrey", mx: 1.5 }}></Box>
        </Box >
    );
}

export default DownloadFileHeader;
