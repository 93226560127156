import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, ClickAwayListener, Divider, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useMsal } from "@azure/msal-react";
import { theme } from "../../themes/theme";
import { getAuthUserInfoFromToken } from "./AppAuthProvider";
import packageJson from '../../../package.json';

interface UserInfoProps {
  onLogoutRequest: () => void;
}

const UserInfo = (props: UserInfoProps) => {

  const { accounts } = useMsal();
  const location = useLocation();
  const navigate = useNavigate();

  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    setIsActive(
      location.pathname.endsWith("user-profile") || location.pathname.endsWith("download-pdf")
    );
  }, [location]);

  function getEnvironmentLabel() {
    // Establish required parts of the label
    const env: string = process.env?.REACT_APP_ENVIRONMENT ?? "";
    let ver: string = packageJson.version ?? "";
    // Get the build number from DevOps pipeline
    const buildNumber = process.env.REACT_APP_BUILD_NUMBER ?? "";
    ver = `${ver} (${buildNumber})`;

    // Build environment label
    const labelText = `${env} version ${ver}`;

    return (
      <Typography variant="overline"
        color="grey.600"
        align="center"
        textTransform={"none"}
        sx={{ mx: 2 }}
        fontSize={11}>
        {labelText}
      </Typography >
    )
  }

  if (accounts && accounts[0]) {
    const userInfo = getAuthUserInfoFromToken();

    return (
      <Box
        sx={{
          border: "1px solid " + (isActive ? theme.palette.primary.main : theme.component.appSideBarBgColor),
          backgroundColor: "grey.800",
          borderRadius: 2,
          pl: 1.5,
          pr: 0,
          py: 1,
          textAlign: 'center',
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 1
        }}>
        <Box sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textAlign: "left"
        }}>
          <Box sx={{ fontSize: 14, color: "white" }}>{userInfo?.Name}</Box>
          <Box sx={{ fontSize: 11, color: "grey.400" }}>{userInfo?.Email}</Box>
        </Box>
        <IconButton
          sx={{ ml: 'auto' }}
          color='inherit'
          size="medium"
          ref={anchorRef}
          onClick={() => setIsOpenMenu(true)}
        >
          <MoreVertOutlinedIcon fontSize="small"></MoreVertOutlinedIcon>
        </IconButton>
        <Popper
          open={isOpenMenu}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          sx={{ zIndex: 9999 }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  'right bottom',
              }}>
              <Paper >
                <ClickAwayListener onClickAway={() => setIsOpenMenu(false)}>
                  <MenuList
                    autoFocusItem={isOpenMenu}>
                    <MenuItem
                      sx={{ color: theme.palette.text.secondary }}
                      onClick={() => {
                        setIsOpenMenu(false);
                        navigate("/user-profile");
                      }}>
                      <AccountCircleOutlinedIcon />
                      <Box sx={{ ml: 1 }}>User Profile</Box>
                    </MenuItem>
                    <MenuItem
                      sx={{ color: theme.palette.text.secondary }}
                      onClick={() => {
                        setIsOpenMenu(false);
                        navigate("/download-pdf");
                      }}>
                      <CloudDownloadOutlinedIcon />
                      <Box sx={{ ml: 1 }}>Download PDF</Box>
                    </MenuItem>
                    <MenuItem
                      sx={{ color: theme.palette.text.secondary }}
                      onClick={() => {
                        setIsOpenMenu(false);
                        navigate("/privacy-policy");
                      }}>
                      <PolicyOutlinedIcon />
                      <Box sx={{ ml: 1 }}>Privacy Statement</Box>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      sx={{ color: theme.palette.primary.main }}
                      onClick={() => {
                        setIsOpenMenu(false);
                        props.onLogoutRequest();
                      }}>
                      <LogoutOutlinedIcon />
                      <Box sx={{ ml: 1 }}>Logout</Box>
                    </MenuItem>
                    <Divider />
                    {getEnvironmentLabel()}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    );
  }
  else {
    return (<Box sx={{ fontSize: 14, width: "100%", display: "flex", justifyContent: "center" }}>Not currently logged in</Box>);
  }
};

export default UserInfo;
