import React from "react"
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Box } from "@mui/material";
import { onNavCollapsed } from "../../redux/actions/Setting";
import AppSidebar from "./AppSidebar";
import AppContentView from "./AppContentView";
import './../../App.css';

function AppLayout() {
  const { pathname } = useLocation();
  const { accounts } = useMsal();
  const isLoggedIn = (accounts && accounts[0]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onNavCollapsed());
  }, [dispatch, pathname]);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "row",
        position: "relative",
        backgroundColor: (theme) => theme.palette.background.default,
        "& .mainContent": {
          display: "flex",
          flexDirection: "column",
          position: "relative",
          width: { xs: "100%", lg: isLoggedIn ? "calc(100% - 250px)" : "100%" },
          transition: "all 0.5s ease",
          ml: { lg: isLoggedIn ? "250px" : "0px" },
        }
      }}>
      {isLoggedIn && <AppSidebar />}
      <Box className="mainContent">
        <AppContentView />
      </Box>
    </Box >
  );
}

export default AppLayout;
