import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, FormHelperText } from "@mui/material";
import { AddUserType } from "../TeamTable/TeamTableRow";
import AppDialog from "../../../components/AppBase/AppDialog";
import { RISUser } from "../../../types/models/RISUser";
import { TeamUser } from "../../../types/models/TeamUser";
import { MemberAutocomplete } from "../EditTeam/MemberAutocomplete";

interface EditUserDialogProp {
    isOpen: boolean,
    mode: (typeof AddUserType)[number] | undefined,
    setIsOpen: (open: boolean) => void,
    allUsers: RISUser[],
    currenUsers: TeamUser[],
    reservedUserIds: string[],
    onSelectUsers: (users: RISUser[]) => void
}

const EditUserDialog: React.FC<EditUserDialogProp> = (prop) => {
    const [selectedUserIds, setSelectedUserIds] = useState<string[]>();
    const [selectedUserError, setSelectedUserError] = useState<string | undefined>();
    

    useEffect(() => {
        if (prop.isOpen) {
            setSelectedUserIds(prop.currenUsers.map(user => user.UserId));
        }
    }, [prop.isOpen, prop.currenUsers]);

    const getUserDisplayName = (userId: string) => {
        const user = prop.allUsers.find((user) => user.UserId === userId);
        if(user) {
            return user?.Name || user?.Email;
        }
        else {
            return "Unknown";
        }

    };

    const handleChange = (event: React.ChangeEvent<unknown>, values: RISUser[] | null) => {
        setSelectedUserIds(values?.map((value) => value.UserId ?? ""));
    };

    const handleSubmit = () => {
        if (!selectedUserIds || selectedUserIds.length === 0) {
            setSelectedUserError("Please select one or more " + (prop.mode === "LEADER" ? "leaders" : "members"));
        } else {
            prop.onSelectUsers(prop.allUsers.filter(user => user.UserId !== null && selectedUserIds?.includes(user.UserId ?? "")))
        }
    };

    const handleClose = () => {
        setSelectedUserError(undefined);
        prop.setIsOpen(false);
    };

    return (
        <AppDialog
            open={prop.isOpen}
            title={"Add " + (prop.mode === "LEADER" ? "Leaders" : "Members")}
            onClose={() => handleClose()}
            footer={<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    variant="contained"
                    sx={{ mr: 2, mb: 1, textTransform: "none", color: "white" }}
                    onClick={() => handleSubmit()}>
                    Apply
                </Button>
                <Button
                    variant="outlined"
                    sx={{ mr: 1, mb: 1, textTransform: "none" }}
                    onClick={() => handleClose()}>
                    Cancel
                </Button>
            </Box>}
        >
            <Box>
                <FormControl sx={{ m: 1, width: "100%" }}>
                    <MemberAutocomplete 
                        name={prop.mode?.toLowerCase() ?? ""} 
                        allUsers={prop.allUsers} 
                        emptyError={selectedUserError} 
                        getUserDisplayName={getUserDisplayName}
                        handleChange={handleChange}
                        leaderIds={selectedUserIds}
                        memberIds={selectedUserIds}
                    />
                    {selectedUserError && <FormHelperText className="Mui-error">{selectedUserError}</FormHelperText>}
                </FormControl>
            </Box>
        </AppDialog>
    );
};

export default EditUserDialog;
