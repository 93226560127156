import { Dispatch } from "react";
import { AppActions } from "../../types";
import { RuleInstance } from "../../types/models/RuleInstance";
import { GET_AUDIO_FILE, INITIALISE_AUDIO_STATE, SET_AUTO_PLAY, SET_CURRENT_INDEX, SET_IS_DOWNLOADING, START_AUDIO, STOP_AUDIO } from "../../types/actions/RuleAudio.actions";
import contentAxios from "../../auth/contentAxios";
import { fetchError } from "./Common";

export const getAudioFile = (ruleInstance: RuleInstance, contentRevisionId: string, index: number, callBack: (result: boolean) => void) => {
    return async (dispatch: Dispatch<AppActions>) => {

        dispatch({ type: SET_IS_DOWNLOADING, payload: true });
        const expectedFileName = "audio/" + ruleInstance.RuleId + "_" + index + ".mp3";
        await contentAxios
            .get("content/" + contentRevisionId + "/" + expectedFileName, { responseType: "blob" })
            .then(response => {
                const blob = new Blob([response.data], { type: 'audio/mp3' });
                const reader = new FileReader();
                reader.onloadend = function (e) {
                    const audioSource = e.target?.result?.toString();
                    dispatch({ type: SET_IS_DOWNLOADING, payload: false });
                    dispatch({ type: GET_AUDIO_FILE, payload: { index: index, source: audioSource } });
                    callBack(true);
                }
                reader.readAsDataURL(blob);
            })
            .catch(error => {
                dispatch({ type: SET_IS_DOWNLOADING, payload: false });
                console.error(error);
                dispatch(fetchError(`Failed to download an audio file. Please try again later or contact support. \nError: ${error.message}`));
                callBack(false);
            });
    };
};

export const setCurrentAudioIndex = (index: number) => {
    return (dispatch: Dispatch<AppActions>) => {
        dispatch({ type: SET_CURRENT_INDEX, payload: index });
    };
};

export const startAudio = (index: number) => {
    return (dispatch: Dispatch<AppActions>) => {
        dispatch({ type: START_AUDIO, payload: index });
    };
};

export const stopAudio = () => {
    return (dispatch: Dispatch<AppActions>) => {
        dispatch({ type: STOP_AUDIO, payload: true });
    };
};

export const setAutoPlay = (autoPlay: boolean) => {
    return (dispatch: Dispatch<AppActions>) => {
        dispatch({ type: SET_AUTO_PLAY, payload: autoPlay });
    };
};

export const initialiseAudioState = (ruleInstanceId: string) => {
    return (dispatch: Dispatch<AppActions>) => {
        dispatch({ type: INITIALISE_AUDIO_STATE, payload: ruleInstanceId });
    };
};
