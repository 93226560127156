import React from "react";
import Box from "@mui/material/Box";
import logo from "../../assets/logo.png"

const AppLogo: React.FC = () => {
  return (
    <Box
      sx={{
        bgcolor: "transparent",
        fontSize: 24,
        height: 20,
        minWidth: 120,
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
      }}>
      <Box sx={{
        paddingTop: 1,
        display: "flex",
        alignItems: "center",
      }}>
        <img
          style={{
            height: 50,
          }}
          src={logo}
          alt="kiwi-rail-logo"
        />
        <h2>Shield</h2>
      </Box>
    </Box>
  );
};

export default React.memo(AppLogo);
