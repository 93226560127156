import { Box, Typography } from "@mui/material"
import { fontColor } from "../../../constants/ColorSets"
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';

export const UnreadAlert = () => {
  return (
    <Box
      sx={{ 
          width: "fit-content", 
          display: "flex", 
          alignItems: "center", 
          px: 1.5, py: 0.5, 
          backgroundColor: fontColor.grayBackground, 
          borderRadius: "10px", color: fontColor.orangeTitle, 
      }}>
          <ChatBubbleOutlineRoundedIcon fontSize="small" sx={{mr: 0.5, mt: 0.5}} />
          <Typography sx={{ fontWeight: "600", fontSize: 12 }}>un-responded</Typography>
  </Box>)
}