import { Dispatch } from "react";
import axios from "axios";
import jwtAxios from "../../auth/jwtAxios";
import { AppActions } from "../../types";
import { KnownRole } from "../../types/models/KnownRole";
import { MyLocation, MyRole } from "../../types/models/MyRole";
import { GET_KNOWN_ROLES, GET_MY_ROLES } from "../../types/actions/Role.actions";
import { PostUpdateLocationSubscriptionRequest, PostUpdateSubscriptionsRequest } from "../../types/models/PostUpdateSubscriptionsRequest";
import { fetchStart, fetchSuccess, fetchError } from "./Common";
import { getDisplayErrorMessage } from "../../utils/StringUtils";
import { KnownLocation } from "../../types/models/KnownLocation";

export const getKnownRoles = (callBack: (knownRoles: KnownRole[] | undefined) => void) => {

  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("api/KnownRoles")
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          const roles: KnownRole[] = data.data.Values;
          const locations: KnownLocation[] = data.data.Locations
          const knownValues = {roles: roles, locations: locations};
          dispatch({ type: GET_KNOWN_ROLES, payload: knownValues });
          callBack(roles);
        } else {
          dispatch(fetchError("Something went wrong, Please try again! Status:" + data.status));
          callBack(undefined);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        callBack(undefined);
      });
  };
};

export const getMyRoles = (callBack: (roles: {roles: MyRole[], locations: MyLocation[]} | undefined) => void) => {

  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("api/MyRoles")
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          const roles: MyRole[] = data.data.Values;
          const locations: MyLocation[] = data.data.Locations;
          const contentAuthor: boolean = data.data.ContentAuthor;
          const values = {roles: roles, locations: locations, contentAuthor: contentAuthor};
          dispatch({ type: GET_MY_ROLES, payload: values });
          callBack(values);
        } else {
          dispatch(fetchError("Something went wrong, Please try again! Status:" + data.status));
          callBack(undefined);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        callBack(undefined);
      });
  };
};

export const postUpdateRoleSubscription = (request: PostUpdateSubscriptionsRequest, callBack?: (result: boolean) => void) => {

  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .post("api/UpdateSubscriptions", request)
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          if (callBack)
            callBack(true);

        } else {
          dispatch(fetchError("Something went wrong, Please try again! Status:" + data.status));
          if (callBack)
            callBack(false);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error) || (error instanceof Error)) dispatch(fetchError(getDisplayErrorMessage(error)));
        if (callBack)
          callBack(false);
      });
  };
};

export const postUpdateLocationSubscription = (request: PostUpdateLocationSubscriptionRequest, callBack?: (result: boolean) => void) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .post("api/UpdateLocations", request)
      .then((data) => {
        if(data.status === 200) {
          dispatch(fetchSuccess());
          if(callBack) {
            callBack(true);
          }
        } else {
          dispatch(fetchError("Something went wrong, Please try again! Status: " + data.status));
        }
      })
      .catch(() => {
        if(callBack) {
          callBack(false);
        }
      })
  }
}