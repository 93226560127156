import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store";
import { getContentById, getMyAcknowledgement, getTeamProgress } from "../redux/actions";
import { RuleUnitState } from "../types/models/RuleUnitState";
import { Acknowledgment } from "../types/models/Acknowledgement";
import { ContentRevision } from "../types/models/ContentRevision";
import { isMetadataIncludeLocation, isMetadataIncludeRole } from "../utils/RuleUtil";
import { getMyChatThreads } from "../redux/actions/Chat";
import { TeamMemberProgress } from "../types/models/TeamMember";

export interface RulesProgress {
  total: number;
  completed: number;
}

export function useTeams() {

  const dispatch = useDispatch();
  const [loadingTeams, setLoadingTeams] = useState<boolean>(false);
  const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);

  useEffect(() => {
    // Initial loading
      setLoadingTeams(true);
      dispatch(getMyAcknowledgement(() =>
        dispatch(getMyChatThreads(() =>
            setLoadingTeams(false)))
        ));
  }, []);

  useEffect(() => {
    // Getting alert details
    if (ruleUnitState.baseAlertContentRevisions &&
      (!ruleUnitState.baseAlertContentRevisionDetails || ruleUnitState.baseAlertContentRevisionDetails.length === 0)) {
      // Call detail API if it's not called yet
      ruleUnitState.baseAlertContentRevisions.forEach(alertContent => {
        dispatch(getContentById(alertContent.ContentRevisionId, null));
      });
    }
  }, [ruleUnitState.baseAlertContentRevisions])

  const getProgressForTeam = (teamId: string, callBack: (progress: TeamMemberProgress[]) => void) => {
    setLoadingTeams(true);
    dispatch(getTeamProgress(teamId, (result) => {
      setLoadingTeams(false);
      callBack(result ?? []);
    }));
  };

  const filterAlertProgress = (progress: Acknowledgment[], roles: string[], locations: string[]) => {
    const alerts: Acknowledgment[] = [];
    // Create maps to avoid nested linear search
    const alertRules = ruleUnitState.baseAlertRules ?? [];
    const acknowledgedRules = ruleUnitState.baseAlertHistories ?? [];
    const alertRuleMap = new Map(alertRules.concat(acknowledgedRules).map((alert) => [alert.RuleInstanceId, alert]));
    const contentRuleMap = new Map<string, ContentRevision>();
    ruleUnitState.baseAlertContentRevisionDetails?.forEach(detail =>
      detail.RuleInstances?.forEach(rule =>
        contentRuleMap.set(rule.RuleInstanceId, detail)));

    progress.forEach(acknowledge => {
      const alertDetail = alertRuleMap.get(acknowledge.RuleInstanceId);
      if (alertDetail) {
        // Get Taxonomy from Content list
        const contentRevision = contentRuleMap.get(alertDetail.RuleInstanceId);
        if (contentRevision && contentRevision.Taxonomy === "Alert" && isMetadataIncludeRole(alertDetail.Metadata ?? "", roles) && isMetadataIncludeLocation(alertDetail.Metadata ?? "", locations)) {
          alerts.push(acknowledge);
        }
      }
    });
    return alerts;
  }

  const calculateRulesProgress = (progress: Acknowledgment[], roles: string[], locations: string[]) => {
    let result: RulesProgress = { completed: 0, total: 0 };
    if (progress && progress.length > 0) {
      const alerts = filterAlertProgress(progress, roles, locations);
      const acknowledgedRules = alerts.filter((acknowledgement) => acknowledgement.AcknowledgeAt !== null);
      result = {
        completed: acknowledgedRules.length,
        total: alerts.length
      };
    }
    return result;
  };

  return {
    calculateRulesProgress,
    filterAlertProgress,
    getProgressForTeam,
    isLoading: loadingTeams
  };
}